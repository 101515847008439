import { useIsFeatureEnabled } from '@trr/app-shell-data'

export const useFeatureFlag = () => {
  const isLocationFiltersEnabled = useIsFeatureEnabled(
    'Job-Frontend_locationFilters_perm_240821'
  )
  const isGtmEnabled = useIsFeatureEnabled('gtm.enabled.job')

  const isHotjarEnabled = useIsFeatureEnabled('Job-Frontend_hotjar_perm_240821')

  // For dev purposes
  const isProvidersEnabled = useIsFeatureEnabled(
    'Job-Fontend_providerFilters_perm_240821'
  )
  const isDeleteQuickApplicationEnabled = useIsFeatureEnabled(
    'Job-Frontend_deleteQuickApplication_perm_240821'
  )
  const isRawIndexDataEnabled = useIsFeatureEnabled(
    'Job-Frontend_rawIndexDataContent_perm_240821'
  )

  const isContactTabEnabled = useIsFeatureEnabled(
    'Job-Frontend_showContactTab_temp_240822'
  )

  const isSuggestionsBlockEnabled = useIsFeatureEnabled(
    'Matchning-Profil-V2-Frontend_suggestionsBlock_temp_240828'
  )

  const isCvGenerationEnabled = useIsFeatureEnabled(
    'Job-Frontend-VipsCv_temp_240905'
  )

  const isHotjarFeedbackBoxEnabled = useIsFeatureEnabled(
    'Job-Frontend_showHotjarFeedbackBox_perm_241008'
  )

  const isBetaTestEnabled = useIsFeatureEnabled(
    'CirkusenFrontend-Monorepo_showBetaTesterSetting_temp_241017'
  )

  return {
    isProvidersEnabled,
    isLocationFiltersEnabled,
    isGtmEnabled,
    isRawIndexDataEnabled,
    isDeleteQuickApplicationEnabled,
    isHotjarEnabled,
    isContactTabEnabled,
    isSuggestionsBlockEnabled,
    isCvGenerationEnabled,
    isHotjarFeedbackBoxEnabled,
    isBetaTestEnabled,
  }
}
