import React, { useId } from 'react'
import { TypographyProps } from '@mui/material'

import { useSmartAccordion } from '../Hooks/useSmartAccordion'

import { CustomAccordion } from './CustomAccordion'

interface SmartAccordionProps extends React.PropsWithChildren {
  title: string
  typographyProps?: TypographyProps
  divider?: boolean
}

export const SmartAccordion: React.FC<SmartAccordionProps> = ({
  title,
  typographyProps = { variant: 'h6' },
  children,
  divider = false,
}) => {
  const panelId = useId()
  const { currentOpenAccordion, setCurrentOpenAccordion } = useSmartAccordion()

  return (
    <CustomAccordion
      title={title}
      panelId={panelId}
      typographyProps={typographyProps}
      expanded={currentOpenAccordion === panelId}
      handleClick={() => setCurrentOpenAccordion(panelId)}
      divider={divider}
    >
      {children}
    </CustomAccordion>
  )
}
