import PuffCard from '@local/Components/PuffCard'
import SuggestionsBannerPuff from '@local/Features/JobSearch/PuffGrid/Puffs/SuggestionsBannerPuff'
import usePuffGrid from '@local/Features/JobSearch/PuffGrid/usePuffGrid'
import { useFeatureFlag } from '@local/Utils'
import usePickEpiContent from '@local/Utils/Content/usePickEpiContent'
import React from 'react'

export const MatchedJobsPuff: React.FC = () => {
  const {
    title: profileBoxTitle,
    body: profileBoxBody,
    profileLinkText,
    profileLink,
  } = usePickEpiContent().matchadeNy.profileBox

  const { suggestionsCount, hasSuggestionsPuffContent } = usePuffGrid()
  const { isSuggestionsBlockEnabled } = useFeatureFlag()

  const showSuggestions =
    hasSuggestionsPuffContent &&
    isSuggestionsBlockEnabled &&
    suggestionsCount > 0

  if (showSuggestions) {
    return <SuggestionsBannerPuff count={suggestionsCount} />
  }

  return (
    <PuffCard
      heading={profileBoxTitle}
      description={profileBoxBody}
      actionText={profileLinkText}
      href={profileLink}
    />
  )
}
