import React from 'react'
import { InputAdornment, TextField, TextFieldProps } from '@mui/material'

interface RoundedTextFieldProps {
  icon: React.ReactNode
}

export const RoundedTextField: React.FC<
  RoundedTextFieldProps & TextFieldProps
> = ({ icon, ...params }) => (
  <TextField
    {...params}
    slotProps={{
      input: {
        ...params.InputProps,
        sx: {
          borderRadius: '48px',
          paddingLeft: '50px !important',
        },
        startAdornment: (
          <>
            <InputAdornment
              position="start"
              sx={{
                position: 'absolute',
                top: '50%',
                height: 0,
                left: 15,
              }}
            >
              {icon}
            </InputAdornment>
            {params.InputProps.startAdornment}
          </>
        ),
      },
    }}
  />
)
