import React from 'react'
import { FormControlLabel, Stack, Switch, Typography } from '@mui/material'
import { useFeatureFlag } from '@local/Utils'
import { useTrackCustomClick } from '@trr/gtm-tracking'

import { useApplyJobAdMutation } from '../../../../../Api/JobAdApi'
import usePickEpiContent from '../../../../../Utils/Content/usePickEpiContent'
import { useOptimistic } from '../../../../../Utils/Hooks/useOptimistic'

interface ApplyCheckboxProps {
  jobId: string
  hasApplied: boolean
}

export const ApplyCheckbox: React.FC<ApplyCheckboxProps> = ({
  hasApplied,
  jobId,
}) => {
  const [optimisticHasApplied, setoptimisticHasApplied] =
    useOptimistic(hasApplied)
  const { hasAppliedHelperText, hasAppliedLabel } =
    usePickEpiContent().selectedViewNy.callToAction
  const { isGtmEnabled } = useFeatureFlag()
  const trackClick = useTrackCustomClick()

  const [apply] = useApplyJobAdMutation()

  const handleApply = () => {
    setoptimisticHasApplied(!optimisticHasApplied)
    apply({ id: jobId, method: optimisticHasApplied ? 'DELETE' : 'POST' })

    if (isGtmEnabled && !optimisticHasApplied) {
      trackClick('Skickat ansökan', { label: 'Annons sparad under "Ansökta"' })
    }
  }

  return (
    <Stack spacing={0}>
      <FormControlLabel
        checked={optimisticHasApplied}
        onChange={handleApply}
        label={hasAppliedLabel}
        control={<Switch />}
      />
      <Typography variant="body2" color="text.secondary">
        {hasAppliedHelperText}
      </Typography>
    </Stack>
  )
}
