import React, { useEffect, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { Box, Stack, Typography } from '@mui/material'
import { useUser } from '@trr/app-shell-data'
import { useGlobalDrawer } from '@local/Components/Drawer/Hooks/useGlobalDrawer'
import { FormikProps } from 'formik'
import { useQueryParam, BooleanParam } from 'use-query-params'

import { useGenerateCvQuery } from '../../../Api/generateCvApi'
import { useSelectedJobId } from '../../../Utils/Hooks/useSelectedJobId'

import { mockCv } from './mockGeneratedCv'
import { CreateCvForm } from './CreateCV/CreateCvForm'
import { CvPdf } from './Pdf/CvPdf'
import { Cv } from './GeneratedCv.types'
import { AnalysingCvErrorBlock } from './Components/AnalysingCvErrorBlock'

export const GeneratedCv: React.FC = () => {
  const selectedJobId = useSelectedJobId()
  const { setDrawerContent, openDrawer } = useGlobalDrawer()
  const [DEBUG] = useQueryParam('debug', BooleanParam) // Usage: debug=1

  const user = useUser()

  const {
    data: fetchedCv,
    error,
    isError,
    isLoading,
  } = useGenerateCvQuery(
    { adId: selectedJobId, body: { text: '' } },
    { skip: DEBUG }
  )

  const generatedCv = DEBUG ? mockCv : fetchedCv

  const cv: Cv = {
    ...generatedCv,
    fullname: `${user?.firstName} ${user?.lastName}`,
    email: user?.email,
    phone: user?.mobilePhone,
    profileImage: user?.profileImages?.xlarge,
    skills: [
      ...(generatedCv?.skills ?? []),
      ...(generatedCv?.otherCompetences ?? []),
      ...(generatedCv?.languages ?? []),
    ],
  }

  const [formRef, setFormRef] = useState<FormikProps<Cv>>()

  useEffect(() => {
    if (!isLoading) {
      setDrawerContent({
        title: 'Redigera',
        content: (
          <CreateCvForm
            cv={cv}
            ref={(newRef: FormikProps<Cv>) => setFormRef(newRef)}
          />
        ),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  if (isLoading || !cv || !formRef?.values) return <CircularProgress />

  if (isError) {
    return error && 'status' in error && error.status.toString()[0] === '4' ? (
      <AnalysingCvErrorBlock />
    ) : (
      <Stack>
        <Typography>
          Något gick fel när vi försökte hämta ditt CV, vänligen försök igen
          senare.
        </Typography>
      </Stack>
    )
  }

  return (
    <Box>
      <CvPdf cv={formRef?.values} onEditClick={() => openDrawer()} />
    </Box>
  )
}
