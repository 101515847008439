import React from 'react'
import { CheckCircle } from '@mui/icons-material'
import { date } from '@trr/frontend-datetime'

import { StatusCard } from '../../../../../Components/StatusCard/StatusCard'
import usePickEpiContent, {
  replaceEpiVariables,
} from '../../../../../Utils/Content/usePickEpiContent'

interface AppliedStatusCardProps {
  applicationDate: Date
}

export const AppliedStatusCard: React.FC<AppliedStatusCardProps> = ({
  applicationDate,
}) => {
  const {
    appliedExternallyDateText,
    appliedExternallyDescription,
    appliedExternallyHeading,
  } = usePickEpiContent().selectedViewNy.applicationStatus

  return (
    <StatusCard
      heading={appliedExternallyHeading}
      body={appliedExternallyDescription}
      icon={<CheckCircle />}
      iconColor="default"
      footer={
        applicationDate
          ? replaceEpiVariables(appliedExternallyDateText, {
              date: date.format(applicationDate, { year: true }),
            })
          : undefined
      }
    />
  )
}
