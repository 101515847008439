import React, { useRef, useState } from 'react'
import {
  Avatar,
  Box,
  Button,
  Link,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import { BoltOutlined } from '@mui/icons-material'
import { useDevice } from '@trr/app-shell-data'
import { Formik, FormikProps } from 'formik'

import { QuickApplicationFormFields } from '../../../../Api/types/api.types'
import { useQuickApplyMutation } from '../../../../Api/quickApplicationApi'
import usePickEpiContent from '../../../../Utils/Content/usePickEpiContent'

import { useQuickApplicationValidation } from './hooks/useQuickApplicationValidation'
import { ConfirmApplicationDialog } from './Dialogs/ConfirmApplicationDialog/ConfirmApplicationDialog'
import { SkillsField } from './SkillsField/SkillsField'
import { MotivationField } from './MotivationField/MotivationField'
import QuickApplicationHelperDialog from './Dialogs/QuickApplicationHelperDialog/QuickApplicationHelperDialog'

export const quickApplicationFormComponentId = 'trr-application-form'

const defaultFormValues: QuickApplicationFormFields = {
  skills: [],
  motivation: '',
}

interface QuickApplicationFormProps {
  jobId: string
  requiredSkills: string[]
  onApplicationSuccess: () => void
}

export const QuickApplicationForm: React.FC<QuickApplicationFormProps> = ({
  jobId,
  requiredSkills,
  onApplicationSuccess,
}) => {
  const [isOpenConfirmationDialog, setIsOpenConfirmationDialog] =
    useState(false)
  const [isOpenHelperDialog, setIsOpenHelperDialog] = useState(false)
  const [quickApply] = useQuickApplyMutation()
  const content = usePickEpiContent().selectedViewNy.quickApplication
  const formikRef = useRef<FormikProps<QuickApplicationFormFields>>(null)
  const { isMobile } = useDevice()
  const quickApplicationValidation = useQuickApplicationValidation()

  const toggleOpenHelperDialog = () => {
    setIsOpenHelperDialog((prev) => !prev)
  }

  const handleSubmit = async () => {
    const errors = await formikRef.current?.validateForm()
    Object.keys(errors).length === 0 && setIsOpenConfirmationDialog(true)
  }

  const handleConfirmQuickApplication = async () => {
    await quickApply({
      jobId,
      ...formikRef.current?.values,
    })
      .unwrap()
      .then(() => {
        setIsOpenConfirmationDialog(false)
        onApplicationSuccess()
      })
  }

  return (
    <>
      <Paper
        variant="outlined"
        sx={{ p: { xs: 3, md: 5 } }}
        id={quickApplicationFormComponentId}
      >
        <Formik
          initialValues={defaultFormValues}
          onSubmit={() => {}}
          innerRef={formikRef}
          validationSchema={quickApplicationValidation}
          validateOnBlur={false}
          validateOnChange={false}
        >
          <Stack spacing={5}>
            <Box>
              <Avatar sx={{ bgcolor: 'surface.purple', mb: 3 }}>
                <BoltOutlined color="primary" />
              </Avatar>
              <Typography variant="h4" gutterBottom>
                {content.heading}
              </Typography>
              <Typography>
                {isMobile ? content.descriptionShort : content.descriptionLong}{' '}
                {
                  <Link
                    role="button"
                    onClick={toggleOpenHelperDialog}
                    sx={{ cursor: 'pointer' }}
                  >
                    {content.helperDialogLinkLabel}
                  </Link>
                }
              </Typography>
            </Box>
            {requiredSkills?.length > 0 && (
              <SkillsField options={requiredSkills} />
            )}
            <MotivationField />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              type="submit"
            >
              {content.submitButtonLabel}
            </Button>
          </Stack>
        </Formik>
      </Paper>
      <ConfirmApplicationDialog
        formValues={formikRef.current?.values}
        open={isOpenConfirmationDialog}
        requiredSkills={requiredSkills}
        onClose={() => setIsOpenConfirmationDialog(false)}
        onConfirm={handleConfirmQuickApplication}
      />
      <QuickApplicationHelperDialog
        isOpen={isOpenHelperDialog}
        onClose={toggleOpenHelperDialog}
      />
    </>
  )
}
