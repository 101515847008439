import React from 'react'

import { GlobalDrawerContext } from '../Context/GlobalDrawerProvider'

export const useGlobalDrawer = () => {
  const context = React.useContext(GlobalDrawerContext)
  if (!context) {
    throw new Error(
      'useGlobalDrawer must be used within a GlobalDrawerProvider'
    )
  }
  return context
}
