import React, { useId } from 'react'
import { Stack, Typography } from '@mui/material'
import { date } from '@trr/frontend-datetime'

import usePickEpiContent from '../../../../../Utils/Content/usePickEpiContent'
import { Deadline } from '../../../../../Components/Deadline/Deadline'

interface ApplicationDeadlineProps {
  applicationDeadline: Date
}

export const ApplicationDeadline: React.FC<ApplicationDeadlineProps> = ({
  applicationDeadline,
}) => {
  const labelId = useId()

  const { applicationDeadlineHeading, ongoingApplication } =
    usePickEpiContent().selectedViewNy.callToAction

  return (
    <Stack spacing={0}>
      <Typography variant="body2" color="text.secondary" id={labelId}>
        {applicationDeadlineHeading}
      </Typography>
      <Stack direction="row" spacing={1} justifyContent="space-between">
        <Typography variant="body1" aria-labelledby={labelId}>
          {applicationDeadline
            ? date.format(applicationDeadline, { year: true })
            : ongoingApplication}
        </Typography>
        {applicationDeadline && <Deadline date={applicationDeadline} />}
      </Stack>
    </Stack>
  )
}
