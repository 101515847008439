import React from 'react'
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'

type IconColor = 'success' | 'default' | 'info'

interface StatusCard {
  heading: string
  body: string
  iconColor?: IconColor
  icon: React.ReactNode
  overline?: string
  action?: {
    onClick: () => void
    label: string
  }
  footer?: React.ReactNode
}

export const StatusCard: React.FC<StatusCard> = ({
  heading,
  overline,
  action,
  body,
  icon,
  footer,
  iconColor = 'default',
}) => {
  const avatarSx: SxProps<Theme> = (t) => {
    switch (iconColor) {
      case 'success':
        return {
          bgcolor: t.palette.secondary.light,
          color: t.palette.secondary.main,
        }
      case 'info':
        return {
          bgcolor: t.palette.surface.blue,
          color: t.palette.info.main,
        }
      default:
        return {
          bgcolor: t.palette.surface.orange,
          color: t.palette.warning.main,
        }
    }
  }

  return (
    <Card variant="outlined">
      <CardHeader
        title={heading}
        avatar={<Avatar sx={avatarSx}>{icon}</Avatar>}
        titleTypographyProps={{ variant: 'h4' }}
        sx={{
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'flex-start', sm: 'center' },
          gap: { xs: 1, sm: 0 },
        }}
      />
      <CardContent>
        {overline && (
          <Typography variant="body2" color="text.secondary" gutterBottom>
            {overline}
          </Typography>
        )}
        <Typography variant="body1">{body}</Typography>
      </CardContent>
      <CardActions
        disableSpacing
        sx={{
          justifyContent: action ? 'space-between' : 'flex-end',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'flex-start', sm: 'center' },
          gap: { xs: 1, sm: 0 },
        }}
      >
        {action && (
          <Button
            size="small"
            color="primary"
            onClick={action.onClick}
            variant="text"
          >
            {action.label}
          </Button>
        )}
        {footer && (
          <Typography variant="body2" color="text.secondary" sx={{ mx: 1.5 }}>
            {footer}
          </Typography>
        )}
      </CardActions>
    </Card>
  )
}
