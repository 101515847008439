import { GeneratedCv } from './GeneratedCv.types'

export const mockCv: GeneratedCv = {
  heading: 'CV',
  summary:
    'Emil är en 28-årig kille från Göteborg som alltid har ett leende på läpparna. På dagarna jobbar han som grafisk designer, men på fritiden älskar han att vandra i skogen och fotografera naturen. Emil har ett stort intresse för musik och spelar både gitarr och piano, vilket han ibland delar på sociala medier. Han är en omtänksam vän som alltid lyssnar och har en förmåga att se det positiva i alla situationer.',
  workExperiences: [
    {
      title: 'Snigelfarmare',
      organization: 'Snigel Corporation',
      description:
        'Ansvarade för den dagliga driften av en ekologisk snigelfarm, inklusive uppfödning, skötsel och skörd av sniglar för gastronomisk och kosmetisk användning. Skapade och underhöll optimala levnadsmiljöer genom att justera temperatur, fuktighet och näringsintag. ',
      startYear: '2001',
      startMonth: 'February',
    },
    {
      title: 'Systemutvecklare',
      organization: 'Fujitsu',
      description:
        'Ansvarade över att chefen skulle ha sitt morgonkaffe. Skötte även om att skriva ut dokument (relaterat till bönor) och att sätta på kaffebryggaren.',
      startYear: '2010',
      startMonth: 'June',
    },
  ],
  educations: [
    {
      title: 'Master of Science in Computer Science',
      organization: 'Kungliga Tekniska Högskolan (KTH)',
      description:
        'Genomförde en femårig utbildning inom industriell ekonomi med fokus på kombinationen av teknik, ekonomi och management. Studierna inkluderade kurser inom matematik, produktionssystem, företagsstrategi, finans och ledarskap.',
      startYear: '2005',
      startMonth: 'August',
    },
    {
      title: 'Bachelor of Science in Computer Science',
      organization: 'Mittuniversitetet',
      description:
        'Avslutade en treårig utbildning inom företagsekonomi med inriktning mot marknadsföring och redovisning. Utbildningen omfattade kurser inom ekonomistyrning, bokföring, finansiering, marknadsföring, och affärsjuridik. Fick en bred förståelse för hur företag fungerar, från finansiell rapportering till utveckling av marknadsstrategier.',
      startYear: '2008',
      startMonth: 'April',
    },
    {
      title: 'Bachelor of Science in Computer Science',
      organization: 'Mittuniversitetet',
      description:
        'Avslutade en treårig utbildning inom företagsekonomi med inriktning mot marknadsföring och redovisning. Utbildningen omfattade kurser inom ekonomistyrning, bokföring, finansiering, marknadsföring, och affärsjuridik. Fick en bred förståelse för hur företag fungerar, från finansiell rapportering till utveckling av marknadsstrategier.',
      startYear: '2008',
      startMonth: 'April',
    },
    {
      title: 'Bachelor of Science in Computer Science',
      organization: 'Mittuniversitetet',
      description:
        'Avslutade en treårig utbildning inom företagsekonomi med inriktning mot marknadsföring och redovisning. Utbildningen omfattade kurser inom ekonomistyrning, bokföring, finansiering, marknadsföring, och affärsjuridik. Fick en bred förståelse för hur företag fungerar, från finansiell rapportering till utveckling av marknadsstrategier.',
      startYear: '2008',
      startMonth: 'April',
    },
  ],
  references: [
    {
      name: 'Lars Larsson',
      company: 'TRR',
      role: 'Chef',
      phone: '070-123 45 67',
      email: 'hej@komochhjalpmig.net',
      description:
        'Jag har haft förmånen att arbeta nära Theseus under hans/hennes tid som chef hos oss på TRR. Under den perioden har Theseus utmärkt sig som en ansvarsfull, noggrann och initiativrik medarbetare.',
    },
    {
      name: 'Ben Bensson',
      company: 'PostNord',
      role: 'Springpojke',
      phone: '060-123 45 67',
      email: 'banan@frukt.nu',
      description:
        'Det är med stor glädje jag skriver denna rekommendation för Theseus, som jag haft nöjet att arbeta med under hans/hennes tid på PostNord. Theseus har under hela sin anställning visat en imponerande arbetsmoral, noggrannhet och ett starkt driv att leverera resultat av hög kvalitet.',
    },
  ],
  skills: [
    'C#',
    'Beteendekunskap',
    'Sniglar',
    'Hydrauliska pumpar',
    'Grafisk design',
  ],
  languages: ['Svenska', 'Spanska', 'Engelska'],
  otherCompetences: ['Körkort', 'Gaffeltruckskort'],
  certifications: ['Azure AZ204 1'],
}
