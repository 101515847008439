import React from 'react'
import { useTrackCustomClick } from '@trr/gtm-tracking'
import { EmailRounded } from '@mui/icons-material'

import { useFeatureFlag } from '../../../../../Utils'
import usePickEpiContent from '../../../../../Utils/Content/usePickEpiContent'

import { ApplyButton } from './ApplyButton/ApplyButton'

interface EmailApplyButtonProps {
  to: string
}

export const EmailApplyButton: React.FC<EmailApplyButtonProps> = ({ to }) => {
  const { applyButtonEmail } = usePickEpiContent().selectedViewNy.callToAction

  const trackClick = useTrackCustomClick()
  const { isGtmEnabled } = useFeatureFlag()

  return (
    <ApplyButton
      Icon={EmailRounded}
      label={applyButtonEmail}
      to={`mailto:${to}`}
      onClick={() => {
        if (isGtmEnabled) trackClick('Ansök via e-post')
      }}
      data-testid="cta-apply-mailto-button"
    />
  )
}
