import React from 'react'
import { SmartAccordion } from '@local/Components/Accordion'
import {
  Button,
  Grid2 as Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useFormikContext } from 'formik'
import { useContainerQuery } from '@local/Utils/Hooks/useContainerQuery'

import { Cv } from '../../GeneratedCv.types'
import { useCvGtmTracker } from '../Hooks/useCvGtmTracker'

export const ReferencesField = () => {
  const {
    values: { references },
    handleChange,
    setFieldValue,
  } = useFormikContext<Cv>()
  const { query } = useContainerQuery()
  const { trackChangeOnce } = useCvGtmTracker()

  const removeReference = (index: number) => {
    setFieldValue(
      'references',
      references?.filter((_, i) => i !== index)
    )
  }

  const addNewEmptyReference = () => {
    setFieldValue('references', [
      ...references,
      { name: '', phone: '', email: '', description: '' },
    ])
  }

  return (
    <Stack gap={3}>
      <Typography variant="h6" pt={2} pb={2}>
        Referenser
      </Typography>
      {references?.length > 0 && (
        <Stack gap={1}>
          {references?.map((reference, index) => {
            const referenceParts = [
              reference.name ? `${reference.name}` : 'Ny referens',
              reference.company && reference.role
                ? `, ${reference.role} på ${reference.company}`
                : '',
            ]

            return (
              <SmartAccordion
                title={`${referenceParts[0]} ${referenceParts[1]}`}
                typographyProps={{ variant: 'subtitle1' }}
                key={index}
                divider
              >
                <Grid container spacing={3}>
                  <Grid size={12}>
                    <TextField
                      label="För- och efternamn"
                      value={reference.name}
                      name={`references[${index}].name`}
                      onChange={handleChange}
                      onBlur={() =>
                        trackChangeOnce('references', 'Referens: namn')
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid size={query.down('sm') ? 12 : query.down('lg') ? 6 : 3}>
                    <TextField
                      label="Befattning"
                      value={reference.role}
                      name={`references[${index}].role`}
                      onChange={handleChange}
                      onBlur={() =>
                        trackChangeOnce('references', 'Referens: befattning')
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid size={query.down('sm') ? 12 : query.down('lg') ? 6 : 3}>
                    <TextField
                      label="Företagsnamn"
                      value={reference.company}
                      name={`references[${index}].company`}
                      onChange={handleChange}
                      onBlur={() =>
                        trackChangeOnce('references', 'Referens: företag')
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid size={query.down('sm') ? 12 : query.down('lg') ? 6 : 3}>
                    <TextField
                      label="Telefon"
                      value={reference.phone}
                      name={`references[${index}].phone`}
                      onChange={handleChange}
                      onBlur={() =>
                        trackChangeOnce('references', 'Referens: telefon')
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid size={query.down('sm') ? 12 : query.down('lg') ? 6 : 3}>
                    <TextField
                      label="Mail"
                      value={reference.email}
                      name={`references[${index}].email`}
                      onChange={handleChange}
                      onBlur={() =>
                        trackChangeOnce('references', 'Referens: e-mail')
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid size={12}>
                    <TextField
                      label="Referensutlåtande"
                      value={reference.description}
                      name={`references[${index}].description`}
                      onChange={handleChange}
                      onBlur={() =>
                        trackChangeOnce('references', 'Referens: utlåtande')
                      }
                      multiline
                      rows={4}
                      fullWidth
                    />
                  </Grid>
                  <Button
                    onClick={() => removeReference(index)}
                    size="small"
                    variant="outlined"
                    fullWidth
                    sx={{ alignSelf: 'flex-end' }}
                    data-gtm-label={'Ta bort referens'}
                  >
                    Ta bort
                  </Button>
                </Grid>
              </SmartAccordion>
            )
          })}
        </Stack>
      )}
      <Button
        onClick={addNewEmptyReference}
        size="small"
        variant="outlined"
        data-gtm-label={'Lägg till referens'}
        fullWidth
      >
        Ny referens
      </Button>
    </Stack>
  )
}
