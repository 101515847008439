/* eslint-disable no-console */
import { useFeatureFlag } from '@local/Utils'

declare global {
  interface Window {
    hj: (type: string, data: string) => void
  }
}

export const useHotjar = () => {
  const { isHotjarEnabled } = useFeatureFlag()

  const sendEvent = (eventName: string) => {
    if (!isHotjarEnabled) {
      console.warn('Hotjar feature is not enabled')
    } else if (typeof window?.hj !== 'function') {
      console.error('Hotjar is not a function: ', typeof window?.hj)
    } else {
      window.hj('event', eventName)
    }
  }

  const leaveCvFeedbackEvent = () => sendEvent('cv_feedback')
  const quickApplicationEvent = () => sendEvent('snabbansokan-inskickad')

  return { sendEvent, leaveCvFeedbackEvent, quickApplicationEvent }
}
