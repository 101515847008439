import {
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query'
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers'
import { clone } from 'ramda'

import {
  Facet,
  FacetResponseOptions,
  JobSearchHitResponse,
  JobSearchFacetRequest,
} from '../types/api.types'
import { JobSearchRequest, JobSearchResponse } from '../searchJobAdApi'

type FetchFunction = (
  arg: string | FetchArgs
) => MaybePromise<
  QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>
>

export const getJobAdsMapper = async (
  req: Omit<JobSearchRequest, 'currentFacet'>,
  fetchFunction: FetchFunction
): Promise<JobSearchResponse> => {
  if (req.unspecifiedLocations) {
    req.locations = [
      ...req.locations,
      { id: null, name: 'Ospecificerad Arbetsort', type: 'undefined' },
    ]
  }

  if (req.otherCountries) {
    req.locations = [
      ...req.locations,
      { id: null, name: 'Norge', type: 'undefined' },
      { id: null, name: 'Danmark', type: 'undefined' },
    ]
  }

  const data = await fetchFunction({
    url: '/jobs/search',
    method: 'POST',
    body: req,
  })

  if (data?.error) {
    return Promise.reject(data.error)
  }

  return data.data as JobSearchResponse
}

export const mapperLocationHelper = (
  job: Partial<
    Pick<
      JobSearchHitResponse,
      'cities' | 'municipalities' | 'regions' | 'countries'
    >
  >
): string => {
  if (job?.cities?.length && job?.cities[0]) {
    return job.cities.join(', ')
  }
  if (job?.municipalities?.length && job?.municipalities[0]) {
    return job.municipalities.join(', ')
  }
  if (job?.regions?.length && job?.regions[0]) {
    return job.regions.join(', ')
  }

  return job?.countries?.length && job?.countries[0]
    ? job.countries.join(', ')
    : null
}

export const keepCurrentEmptyFacets =
  (currentFacets: JobSearchFacetRequest) =>
  (newFacets: FacetResponseOptions) => {
    const newFacetsCopy = clone(newFacets)
    Object.keys(currentFacets).forEach((facetCategory) => {
      currentFacets[facetCategory].forEach((facet) => {
        const fixedCategory =
          facetCategory === 'jobTitles' ? 'professions' : facetCategory
        if (
          !newFacets[fixedCategory].some(
            (facetItem) => facetItem.name === facet
          )
        ) {
          newFacetsCopy[fixedCategory].push({ name: facet, count: 0 })
        }
      })
    })

    return newFacetsCopy
  }

export const mergeFacetsReducer = (initial: Facet[], res: Facet[]) =>
  initial.reduce((acc, curr) => {
    const found = acc.find((resItem) => resItem.name === curr.name)
    if (found) {
      return acc
    }
    return [...acc, curr]
  }, res)

export const mergeInitialFacets = (
  facets: FacetResponseOptions
): FacetResponseOptions => ({
  ...facets,
  workingHours: mergeFacetsReducer(
    initialFacets.workingHours,
    facets.workingHours
  ),
  employmentTypes: mergeFacetsReducer(
    initialFacets.employmentTypes,
    facets.employmentTypes
  ),
  providers: mergeFacetsReducer(initialFacets.providers, facets.providers),
})

export const initialFacets: Partial<FacetResponseOptions> = {
  workingHours: [
    {
      name: 'Heltid',
      count: 0,
    },
    {
      name: 'Deltid',
      count: 0,
    },
  ],
  employmentTypes: [
    {
      name: 'Tillsvidare- och tidsbegränsad anställning',
      count: 0,
    },
    {
      name: 'Behovs- och timanställning',
      count: 0,
    },
    {
      name: 'Sommarjobb / Feriejobb',
      count: 0,
    },
    {
      name: 'Arbete utomlands',
      count: 0,
    },
  ],
  providers: [
    {
      name: 'jobtechdev',
      count: 0,
    },
    {
      name: 'auranest',
      count: 0,
    },
  ],
}

export const formatLargeNumber = (num: number) => num.toLocaleString('sv-SE')
