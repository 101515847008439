import React from 'react'
import { Stack } from '@mui/material'

import { MatchingProfileWrapper } from './Components/MatchingProfileWrapper'
import { MatchedJobs } from './Components/MatchedJobs'
import { MatchedJobsHeader } from './Components/MatchedJobsHeader'

export const MatchedJobsPage: React.FC = () => (
  <MatchingProfileWrapper>
    <Stack spacing={1}>
      <MatchedJobsHeader />
      <MatchedJobs />
    </Stack>
  </MatchingProfileWrapper>
)
