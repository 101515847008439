import React, { useEffect, useRef } from 'react'
import { Box } from '@mui/material'

import { useGetJobAdsQuery } from '../../Api/searchJobAdApi'
import { DeepLocation, OrderOption } from '../../Api/types/api.types'
import { jobtitlesToUrl, locationsToUrl } from '../../Utils/Helpers'
import { useSearchQueryParams } from '../../Utils/Hooks/useSearchQueryParams'

import { LocationAutocomplete } from './LocationsAutocomplete/LocationAutocomplete'
import { ProfessionsAutocomplete } from './ProfessionsAutocomplete/ProfessionsAutocomplete'
import { FilterAndJobSearchList } from './FilterAndJobSearchList/FilterAndJobSearchList'
import PuffGrid from './PuffGrid/PuffGrid'

export const JobSearch = () => {
  const currentFilters = useRef({})
  const [filterCount, setFilterCount] = React.useState(20)
  const { formattedSearchParams, setTextSearchField, hasActiveQueryParams } =
    useSearchQueryParams()

  const {
    data: jobs,
    isLoading,
    isFetching,
  } = useGetJobAdsQuery(
    {
      page: formattedSearchParams.page,
      pageSize: 14,
      professions: formattedSearchParams.professions,
      locations: formattedSearchParams.locations,
      currentFacet: formattedSearchParams.currentFacet,
      filter: formattedSearchParams.filters,
      orderBy: [formattedSearchParams.orderBy || OrderOption.Relevance],
      otherCountries: formattedSearchParams.otherCountries,
      unspecifiedLocations: formattedSearchParams.unspecifiedLocations,
      currentFilters: currentFilters.current,
      searchAdDescription: formattedSearchParams.searchAdDescription,
      filterCount,
    },
    { skip: !hasActiveQueryParams }
  )

  useEffect(() => {
    currentFilters.current = jobs?.facets
  }, [jobs])

  const handleLocationChange = (
    event: React.MouseEvent<HTMLButtonElement>,
    newLocations: DeepLocation[]
  ) => {
    setTextSearchField(locationsToUrl(newLocations), 'locations')
    setFilterCount(20)
  }

  const handleJobtitleChange = (
    event: React.MouseEvent<HTMLButtonElement>,
    newJobtitles: string[]
  ) => {
    setTextSearchField(jobtitlesToUrl(newJobtitles), 'professions')
    setFilterCount(20)
  }

  const infinityScrollOptions = [20, 50, 100, 150, 200]

  const handleLoadMoreFilters = () => {
    setFilterCount((prev) => infinityScrollOptions.find((x) => x > prev) || 200)
  }

  return (
    <Box>
      <ProfessionsAutocomplete
        jobtitles={formattedSearchParams.professions}
        onChange={handleJobtitleChange}
      />
      <LocationAutocomplete
        locations={formattedSearchParams.locations}
        onChange={handleLocationChange}
      />

      {hasActiveQueryParams && (
        <FilterAndJobSearchList
          jobs={jobs?.jobs}
          facets={jobs?.facets}
          isLoading={isLoading}
          isFetching={isFetching}
          filterCount={filterCount}
          onLoadMoreFilterOptions={handleLoadMoreFilters}
        />
      )}

      {!hasActiveQueryParams && <PuffGrid mt={2} />}
    </Box>
  )
}
