import { Button } from '@mui/material'
import React from 'react'

import {
  ArraySearchParams,
  useSearchQueryParams,
} from '../../../Utils/Hooks/useSearchQueryParams'
import usePickEpiContent from '../../../Utils/Content/usePickEpiContent'
import {
  FacetResponseOptions,
  JobFiltersRequest,
} from '../../../Api/types/api.types'
import { useFeatureFlag } from '../../../Utils'
import { useFilterSectionContext } from '../FilterSectionContext'
import InfinityScrollWrapper from '../../../Components/InfinityScrollWrapper/InfinityScrollWrapper'

import { mapFacetsToOptions } from './FilterBarDesktop'
import { FilterBarDrawer } from './FilterBarDrawer'
import { FilterOption } from './FilterOption'
import { FilterOptionGroupAccordion } from './FilterOptionGroupAccordion'
import Filter from './Typings/Filter'

interface FilterBarMobileProps {
  options: FacetResponseOptions
  filterChange: (filterName: string, group: keyof ArraySearchParams) => void
}

export const FilterBarMobile = ({
  options,
  filterChange,
}: FilterBarMobileProps) => {
  const {
    skills,
    jobTitles,
    employers,
    workingHours,
    employmentTypes,
    allFiltersOptionTitle,
    country,
    region,
    municipality,
  } = usePickEpiContent().sokNy.sokfiltrering
  const { formattedSearchParams, setSearchParams } = useSearchQueryParams()

  const { isLocationFiltersEnabled } = useFeatureFlag()

  const selectedFiltersCount = Object.keys(
    formattedSearchParams?.filters
  ).reduce(
    (acc, key) =>
      acc +
      formattedSearchParams?.filters[key as keyof JobFiltersRequest]?.length,
    0
  )

  const resetAllFilters = () => {
    setSearchParams({
      jobTitles: [],
      employmentTypes: [],
      employers: [],
      skills: [],
      workingHours: [],
      countries: [],
      regions: [],
      municipalities: [],
    })
  }

  const showCountryFilter =
    (options?.countries?.length > 1 ||
      formattedSearchParams?.filters?.countries) &&
    isLocationFiltersEnabled

  const showRegionFilter =
    (options?.regions?.length > 1 || formattedSearchParams?.filters?.regions) &&
    isLocationFiltersEnabled

  const showMunicipalityFilter =
    (options?.municipalities?.length > 1 ||
      formattedSearchParams?.filters?.municipalities) &&
    isLocationFiltersEnabled

  const filters: Filter[] = [
    {
      label: jobTitles,
      filterKey: 'jobTitles',
      optionKey: 'professions',
    },
    {
      label: employers,
      filterKey: 'employers',
      optionKey: 'employers',
    },
    {
      label: skills,
      filterKey: 'skills',
      optionKey: 'skills',
    },
    {
      label: workingHours,
      filterKey: 'workingHours',
      optionKey: 'workingHours',
    },
    {
      label: employmentTypes,
      filterKey: 'employmentTypes',
      optionKey: 'employmentTypes',
    },
    {
      label: country,
      filterKey: 'countries',
      optionKey: 'countries',
      hidden: !showCountryFilter,
    },
    {
      label: region,
      filterKey: 'regions',
      optionKey: 'regions',
      hidden: !showRegionFilter,
    },
    {
      label: municipality,
      filterKey: 'municipalities',
      optionKey: 'municipalities',
      hidden: !showMunicipalityFilter,
    },
  ]

  const { shouldLoadMoreOptions, onLoadMoreOptions } = useFilterSectionContext()

  return (
    <FilterBarDrawer
      title={allFiltersOptionTitle}
      activeFilterCount={selectedFiltersCount}
    >
      <Button
        size="small"
        variant="text"
        sx={{ p: 2 }}
        onClick={resetAllFilters}
        disabled={selectedFiltersCount === 0}
      >
        Rensa allt
      </Button>
      {filters
        .filter((f) => !f.hidden)
        .map((filter) => (
          <FilterOptionGroupAccordion
            key={filter.filterKey}
            title={filter.label}
            selectCount={
              formattedSearchParams?.filters?.[filter.filterKey]?.length
            }
          >
            <InfinityScrollWrapper
              onLoadMore={onLoadMoreOptions}
              shouldLoadMore={shouldLoadMoreOptions(
                options?.[filter.optionKey]?.length ?? 0
              )}
              maxHeight={400}
            >
              <FilterOption
                filterTitle={filter.label}
                selectedFilters={
                  formattedSearchParams?.filters?.[filter.filterKey]
                }
                options={mapFacetsToOptions(options?.[filter.optionKey])}
                onChangeFilter={(filterName: string) =>
                  filterChange(filterName, filter.filterKey)
                }
                buttonProps={{ sx: { pl: 0 } }}
              />
            </InfinityScrollWrapper>
          </FilterOptionGroupAccordion>
        ))}
    </FilterBarDrawer>
  )
}
