import React, { useRef } from 'react'
import { Box } from '@mui/material'

import { JobAd, OrderOption } from '../../Api/types/api.types'
import { LoadingSpinner } from '../LoadingSpinner'
import { HitsAndSortBar } from '../../Features/FilterSection/Components/HitsAndSortBar'
import { CardGrid } from '../CardGrid/CardGrid'
import { SearchPagination } from '../SearchResultListNew/SearchPagination'
import { CardGridItem } from '../CardGrid/CardGridItem'
import { usePagination } from '../../Utils/Hooks/usePagination'

interface SortOption {
  text: string
  type: OrderOption
}

interface JobAdsPage {
  orderOptions: SortOption[]
  order: OrderOption
  page: number
  renderItem: (jobAd: JobAd) => React.ReactNode
  renderHitsText: (hits: number) => string
  EmptyResult?: React.ReactNode
  jobAds: JobAd[]
  isLoading: boolean
  totalHits: number
  totalPages: number
  ExtraHeader?: React.ReactNode
}

export const JobAdsPage: React.FC<JobAdsPage> = ({
  orderOptions,
  renderItem,
  renderHitsText,
  EmptyResult,
  jobAds = [],
  order,
  page = 1,
  isLoading = false,
  totalHits = 0,
  totalPages = 0,
  ExtraHeader = null,
}) => {
  const scrollRef = useRef(null)

  const { handleOrderChange, handlePageChange } = usePagination()

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (totalHits === 0) {
    return EmptyResult ? <section>{EmptyResult}</section> : null
  }

  return (
    <Box
      component="section"
      ref={scrollRef}
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <HitsAndSortBar
        sortValue={order}
        onChangeSort={handleOrderChange}
        sortOptions={orderOptions}
        label={renderHitsText(totalHits)}
        width="100%"
      >
        {ExtraHeader}
      </HitsAndSortBar>
      <CardGrid>
        {jobAds?.map((job) => (
          <CardGridItem key={job.id}>{renderItem(job)}</CardGridItem>
        ))}
      </CardGrid>
      {totalPages > 1 && (
        <SearchPagination
          onChangePage={(newPage) => handlePageChange(newPage, scrollRef)}
          currentPage={page}
          totalPages={totalPages}
          sx={{ mt: 2 }}
        />
      )}
    </Box>
  )
}
