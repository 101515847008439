import React from 'react'
import {
  Autocomplete,
  AutocompleteProps,
  List,
  ListSubheader,
  Typography,
} from '@mui/material'
import { HistoricSearchesResponse } from '@local/Api/types/api.types'

import { AutocompletePopper } from './AutocompletePopper'
import { RoundedTextField } from './RoundedTextField'

export interface AutocompleteOption<T> {
  label: string
  value: T
}

interface SearchAutocompleteProps<T>
  extends Omit<
    AutocompleteProps<
      AutocompleteOption<T> | HistoricSearchesResponse,
      boolean,
      boolean,
      boolean
    >,
    'renderInput' | 'ChipProps'
  > {
  placeholder: string
  characterLimit?: number
  limitHeight?: boolean
  icon: React.ReactNode
}

export const SearchAutocomplete = <T,>({
  placeholder,
  characterLimit = 35,
  limitHeight = false,
  icon,
  ...props
}: SearchAutocompleteProps<T>) => (
  <Autocomplete
    freeSolo
    multiple
    filterOptions={(x) => x}
    openOnFocus
    popupIcon={null}
    ChipProps={{ translate: 'no' }}
    renderInput={(params) => (
      <RoundedTextField
        {...params}
        placeholder={placeholder}
        icon={icon}
        inputProps={{
          ...params.inputProps,
          maxLength: characterLimit,
        }}
      />
    )}
    ListboxProps={
      limitHeight
        ? {
            sx: {
              maxHeight: 500,
            },
          }
        : undefined
    }
    renderGroup={(params) => (
      <React.Fragment key={params.key}>
        <ListSubheader>
          <Typography variant="subtitle2">{params.group}</Typography>
        </ListSubheader>
        <List sx={{ p: 1 }}>{params.children}</List>
      </React.Fragment>
    )}
    PopperComponent={AutocompletePopper}
    {...props}
  />
)
