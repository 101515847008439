export interface JobSearchHitResponse {
  applicationDeadline: string
  applied: boolean
  cities: string[]
  countries: string[]
  employerName: string
  employmentType: string
  descriptionShort: string
  id: string
  isDeleted: boolean
  isTrrRecruitment: boolean
  locations: string[]
  municipalities: string[]
  professions: string[]
  publicationDate: string
  recruiterName: string
  regions: string[]
  saved: boolean
  score: number
  title: string
}

export interface FilterOptionResponse {
  name: string
  count: number
}

export interface FilterOption extends FilterOptionResponse {
  selected: boolean
  parentFacet: keyof FacetOptions
}

export interface Facet {
  name: string
  count?: number
}

export interface FacetOptions {
  jobTitles?: Facet[]
  employmentTypes?: Facet[]
  employers?: Facet[]
  skills?: Facet[]
  workingHours?: Facet[]
}

export interface FacetResponseOptions {
  countries?: Facet[]
  duration?: Facet[]
  employers?: Facet[]
  employmentTypes?: Facet[]
  extractedSkills?: Facet[]
  municipalities?: Facet[]
  professions?: Facet[]
  regions?: Facet[]
  skills?: Facet[]
  workingHours?: Facet[]
  providers?: Facet[]
}

export interface JobFiltersRequest {
  jobTitles?: string[]
  employmentTypes?: string[]
  employers?: string[]
  skills?: string[]
  workingHours?: string[]
}

export interface Pagination {
  page?: number
  pageSize?: number
}

export interface JobSearchFacetRequest {
  employmentTypes: string[]
  employers: string[]
  jobTitles: string[]
  skills: string[]
  workingHours: string[]
  providers: string[]
}

export enum OrderOption {
  Relevance = 'relevance desc',
  SavedDate = 'saveddate desc',
  AppliedDate = 'applieddate desc',
  PublicationDate = 'publicationdate desc',
  ApplicationDeadline = 'applicationdeadline asc',
  Deadline = 'deadline asc',
}

export interface HistoricSearchesResponse {
  id: string
  locationInformations: DeepLocation[]
  locations: string[]
  professions: string[]
  searchTimeStamp: string
  newJobsCount: number
}

export type ApplicationType =
  | 'External'
  | 'Email'
  | 'Text'
  | 'Other'
  | 'TrrRecruitment'

export type WorkingHours = 'Heltid' | 'Deltid' | 'Oidentifierat'

export interface ApplicationInformation {
  type: ApplicationType
  value: string
  deadline?: string
  publicationDate?: string
}

export interface Contact {
  name: string
  description?: string
  phone?: string
  email?: string
}

interface Company {
  name: string
  descriptionHtml?: string
}

export interface Position {
  header: string
  description: string
  descriptionHtml?: string
  vacancies: number
  employmentType: string
  workingHours: WorkingHours[]
  jobTitles: string[]
  trrRecruitmentSkills?: string[]
  employer?: Company
  recruiter?: Company
}

export interface GetSavedJobAdsRequest {
  page: number
  pageSize: number
  orderBy: string
}

export interface DeepLocation {
  id: string
  name: string
  nameWithHighLightedSearchTerm: string
  type: string
  locatedIn?: DeepLocation
}

export interface JobTitle {
  name: string
  id: string
  experience?: number
  jobTitleWithHighLightedSearchTerm?: string
}

export interface JobAdList {
  jobAds: JobAd[]
  totalHits: number
  totalHitsWithDescription?: number
  page: number
  pageSize: number
  totalPages: number
}

export enum SavedTo {
  Applied = 'Applied',
  Saved = 'Saved',
}

export interface JobAd {
  id: string
  header: string
  description: string
  jobTitle: string
  employerName: string
  locationName: string
  locationType: string
  applicationStatus: QuickApplicationStatus
  isSaved: boolean
  isTrrJobAd: boolean
  publishDate: string
  deadline: string
  createdAt: string
  lastModifiedAt: string
}

export interface QuickApplicationFormFields {
  motivation: string
  skills: string[]
}

export enum QuickApplicationStatus {
  Applied = 'Applied',
  Rejected = 'Rejected',
  InProgress = 'InProgress',
  InterviewBooked = 'InterviewBooked',
  OfferPresented = 'OfferPresented',
  Hired = 'Hired',
  Withdrawn = 'Withdrawn',
  None = '',
}

export interface SaveStatus {
  status?: string
  savedTo: SavedTo
  createdAt: string
  lastModifiedAt: string
}
