import React, { PropsWithChildren, useState } from 'react'
import {
  Button,
  IconButton,
  SwipeableDrawer,
  Typography,
  styled,
} from '@mui/material'
import { Close, Tune } from '@mui/icons-material'

import { FilterSelectionIndicator } from './FilterSelectionIndicator'

interface FilterBarDrawerProps extends PropsWithChildren {
  title: string
  activeFilterCount?: number
}

const FilterHeader = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '1rem 1rem 0',
}))

const Wrapper = styled('div')(() => ({
  padding: '1rem',
}))

export const FilterBarDrawer = ({
  title,
  activeFilterCount = 0,
  children,
}: FilterBarDrawerProps) => {
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false)

  const iOS =
    typeof navigator !== 'undefined' &&
    /iPad|iPhone|iPod/.test(navigator.userAgent)

  return (
    <div data-testid="filter-bar-mobile">
      <Button
        startIcon={<Tune />}
        variant="text"
        sx={{ left: '-.3rem', position: 'relative' }}
        onClick={() => setIsFilterDrawerOpen(true)}
      >
        {title}
        {activeFilterCount > 0 && (
          <FilterSelectionIndicator>
            {activeFilterCount}
          </FilterSelectionIndicator>
        )}
      </Button>
      <SwipeableDrawer
        anchor="right"
        open={isFilterDrawerOpen}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        onOpen={() => setIsFilterDrawerOpen(true)}
        onClose={() => setIsFilterDrawerOpen(false)}
        PaperProps={{ sx: { width: '95%' } }}
      >
        <Wrapper>
          <FilterHeader>
            <Typography fontWeight={'bold'}>{title}</Typography>
            <IconButton
              sx={{ right: -10 }}
              onClick={() => setIsFilterDrawerOpen(false)}
            >
              <Close />
            </IconButton>
          </FilterHeader>
          {children}
        </Wrapper>
      </SwipeableDrawer>
    </div>
  )
}
