import React from 'react'
import {
  FormControlLabel,
  Checkbox,
  Box,
  CheckboxProps,
  FormControlProps,
} from '@mui/material'
import { Bookmark, BookmarkBorder } from '@mui/icons-material'

import { useSaveJobAdMutation } from '../../../../../Api/JobAdApi'
import usePickEpiContent from '../../../../../Utils/Content/usePickEpiContent'
import { useOptimistic } from '../../../../../Utils/Hooks/useOptimistic'

interface SaveCheckboxProps {
  jobId: string
  isSaved: boolean
  iconOnly?: boolean
}

export const SaveCheckbox: React.FC<SaveCheckboxProps> = ({
  jobId,
  isSaved = false,
  iconOnly = false,
}) => {
  const { saveLabel } = usePickEpiContent().selectedViewNy.callToAction
  const [optimisticSavedValue, setOptimisticSavedValue] = useOptimistic(isSaved)
  const [save] = useSaveJobAdMutation()

  const handleSave = () => {
    setOptimisticSavedValue(!optimisticSavedValue)
    save({ id: jobId, method: optimisticSavedValue ? 'DELETE' : 'POST' })
  }

  const checkboxProps: Pick<
    CheckboxProps & FormControlProps,
    'checked' | 'onChange'
  > = {
    checked: optimisticSavedValue,
    onChange: handleSave,
  }

  const CheckboxComponent = (
    <Checkbox
      icon={<BookmarkBorder color="inherit" />}
      checkedIcon={<Bookmark color="inherit" />}
      sx={{ color: (t) => t.palette.text.primary }}
      color="default"
      aria-label={iconOnly ? saveLabel : undefined}
      {...(iconOnly ? checkboxProps : {})}
    />
  )

  if (iconOnly) {
    return CheckboxComponent
  }

  return (
    <Box>
      <FormControlLabel
        label={saveLabel}
        control={CheckboxComponent}
        {...(iconOnly ? {} : checkboxProps)}
      />
    </Box>
  )
}
