import React from 'react'
import { CardProps } from '@mui/material'
import { AssignmentRounded } from '@mui/icons-material'

import usePickEpiContent from '../../../../Utils/Content/usePickEpiContent'
import PuffCard from '../../../../Components/PuffCard'

const ImproveResumePuff: React.FC<CardProps> = (props) => {
  const { categorization, title, subtitle, to, action } =
    usePickEpiContent().sokNy.improveResumePuff

  return (
    <PuffCard
      overline={categorization}
      heading={title}
      description={subtitle}
      href={to}
      actionText={action}
      Icon={AssignmentRounded}
      {...props}
    />
  )
}

export default ImproveResumePuff
