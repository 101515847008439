import React from 'react'
import { OrderOption } from '@local/Api/types/api.types'

import { useSearchQueryParams } from '../../../Utils/Hooks/useSearchQueryParams'
import { LoadingSpinner } from '../../../Components/LoadingSpinner'
import usePickEpiContent, {
  replaceEpiVariables,
} from '../../../Utils/Content/usePickEpiContent'
import { SearchJobListItem } from '../../JobSearch/Components/SearchJobListItem'
import { JobAdsPage } from '../../../Components/JobAdsPage/JobAdsPage'
import { useGetMatchedJobsQuery } from '../Api/matchedJobsApi'

import { NoMatchedJobsPuff } from './NoMatchedJobsPuff'

export const MatchedJobs: React.FC = () => {
  const { formattedSearchParams } = useSearchQueryParams()

  const { data: jobResult, isLoading } = useGetMatchedJobsQuery(
    {
      orderBy: formattedSearchParams.orderBy as string,
      page: formattedSearchParams.page,
      pageSize: 14,
    },
    {
      refetchOnMountOrArgChange: 2,
    }
  )

  const { sortBar } = usePickEpiContent().matchadeNy

  const formattedsortBar = [
    {
      text: sortBar['relevance desc'],
      type: OrderOption.Relevance,
    },
    {
      text: sortBar['applicationdeadline asc'],
      type: OrderOption.ApplicationDeadline,
    },
    {
      text: sortBar['publicationdate desc'],
      type: OrderOption.PublicationDate,
    },
  ]

  const hitsText = (hits = 0) =>
    replaceEpiVariables(
      hits === 1 ? sortBar.countSingular : sortBar.countPlural,
      {
        number: hits.toString(),
      }
    )

  if (isLoading) {
    return <LoadingSpinner />
  }

  const noResults = !jobResult || jobResult.totalHits === 0

  if (noResults) {
    return <NoMatchedJobsPuff />
  }

  return (
    <JobAdsPage
      jobAds={jobResult?.jobAds}
      orderOptions={formattedsortBar}
      order={OrderOption.Relevance}
      totalHits={jobResult?.totalHits}
      totalPages={jobResult?.totalPages}
      page={formattedSearchParams.page ?? 1}
      renderItem={(jobAd) => <SearchJobListItem job={jobAd} showNewIndicator />}
      renderHitsText={hitsText}
      isLoading={isLoading}
    />
  )
}
