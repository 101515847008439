import React from 'react'
import { CheckRounded } from '@mui/icons-material'
import { Chip } from '@mui/material'

interface SelectableChipProps {
  label: string
  selected: boolean
  onClick?: () => void
}

const SelectableChip: React.FC<SelectableChipProps> = ({
  label,
  selected,
  onClick,
}) => (
  <Chip
    label={label}
    onClick={onClick}
    variant={selected ? 'filled' : 'outlined'}
    icon={selected && onClick ? <CheckRounded /> : undefined}
    role="checkbox"
    aria-checked={selected ? 'true' : 'false'}
    aria-disabled={onClick ? 'false' : 'true'}
    disabled={!onClick && !selected}
  />
)

export default SelectableChip
