import { pipe } from 'ramda'

import { baseApi } from './baseApi'
import {
  getJobAdsMapper,
  keepCurrentEmptyFacets,
  mergeInitialFacets,
} from './helpers/searchJobAdHelpers'
import {
  OrderOption,
  FacetOptions,
  JobAdList,
  QuickApplicationStatus,
  Pagination,
  JobSearchFacetRequest,
  FacetResponseOptions,
  JobSearchHitResponse,
} from './types/api.types'
import { convertToEnglishLocationTypesCapitalStart } from './helpers/autocompleteHelpers'
import {
  HISTORIC_SEARCHES_TAG,
  historicSearchesApi,
} from './historicSearchesApi'

interface JobAdListWithFacets {
  jobs: JobAdList
  facets: FacetOptions
}

export interface JobSearchResponse {
  jobs: JobSearchHitResponse[]
  facets: FacetResponseOptions
  totalHits: number
  totalHitsWithDescription: number
  totalPages: number
}

export interface JobSearchRequest extends Pagination {
  professions: string[]
  locations: {
    id: string
    name: string
    type: string
  }[]
  filter: JobSearchFacetRequest
  orderBy: string[]
  currentFacet: string
  otherCountries: boolean
  unspecifiedLocations: boolean
  currentFilters?: FacetResponseOptions
  searchAdDescription: boolean
  filterCount: number
}

const searchJobAdApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getJobAds: builder.query<JobAdListWithFacets, JobSearchRequest>({
      providesTags: ['JobAdsListing'],
      keepUnusedDataFor: 3600,
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        await queryFulfilled
        dispatch(
          historicSearchesApi.util.invalidateTags([HISTORIC_SEARCHES_TAG])
        )
      },
      queryFn: async (
        {
          professions,
          locations,
          filter,
          page = 1,
          pageSize,
          orderBy = [OrderOption.Relevance],
          currentFacet = '',
          otherCountries,
          unspecifiedLocations,
          currentFilters = {},
          searchAdDescription,
          filterCount,
        },
        _queryApi,
        _extraOptions,
        fetchData
      ) => {
        try {
          const fetchResult = await getJobAdsMapper(
            {
              professions,
              locations: locations.map((location) => ({
                ...location,
                type: convertToEnglishLocationTypesCapitalStart(location.type),
              })),
              filter,
              page,
              pageSize,
              orderBy,
              otherCountries,
              unspecifiedLocations,
              searchAdDescription,
              filterCount,
            },
            fetchData
          )

          const hasFacetLocally = currentFilters?.[currentFacet] ?? false

          const filterCategoryToKeep = hasFacetLocally
            ? { [currentFacet]: currentFilters?.[currentFacet] }
            : {}

          const result: JobAdListWithFacets = {
            jobs: {
              jobAds: fetchResult.jobs.map((job) => ({
                id: job.id,
                header: job.title,
                employerName: job.employerName,
                locationName: job.locations
                  .map((location) => location)
                  .join(', '),
                jobTitle: job.professions.join(', '),
                description: job.descriptionShort,
                locationType: null,
                applicationStatus: job.applied
                  ? QuickApplicationStatus.Applied
                  : null,
                isSaved: job.saved,
                publishDate: job.publicationDate,
                deadline: job.applicationDeadline,
                isTrrJobAd: job.isTrrRecruitment,
                createdAt: null,
                lastModifiedAt: null,
              })),
              totalHits: fetchResult.totalHits,
              totalHitsWithDescription: fetchResult.totalHitsWithDescription,
              page: null,
              pageSize: null,
              totalPages: fetchResult.totalPages,
            },
            facets: pipe(
              mergeInitialFacets,
              keepCurrentEmptyFacets(filter)
            )({
              ...fetchResult.facets,
              ...filterCategoryToKeep,
            }),
          }

          return {
            data: result,
          }
        } catch (error) {
          return { error }
        }
      },
    }),
    getJobAdsCount: builder.query<Pick<JobSearchResponse, 'totalHits'>, void>({
      query: () => ({
        url: '/jobs/search/count',
      }),
    }),
  }),
})

export const {
  useGetJobAdsQuery,
  util: searchJobAdApiUtil,
  useGetJobAdsCountQuery,
} = searchJobAdApi
