import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Link as MuiLink,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  SxProps,
  Theme,
} from '@mui/material'
import React, { useId } from 'react'
import { Close, Email, Phone, SendOutlined } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { useDevice, useUser } from '@trr/app-shell-data'
import usePickEpiContent, {
  replaceEpiVariablesWithJSX,
} from '@local/Utils/Content/usePickEpiContent'
import { TruncateSx } from '@local/Utils/Styles'

interface SuccessfulApplicationDialogProps {
  onClose: () => void
  isOpen: boolean
}

const ListItemIconMobileSx: SxProps<Theme> = {
  minWidth: (t) => t.spacing(4),
}

export const SuccessfulApplicationDialog: React.FC<
  SuccessfulApplicationDialogProps
> = ({ isOpen, onClose }) => {
  const {
    heading,
    description,
    buttonLabel,
    buttonLink,
    settingsLink,
    settingsLinkLabel,
    missingInfo,
    contactHeading,
  } = usePickEpiContent().selectedViewNy.successfulApplicationDialog

  const user = useUser()

  const { isMobile } = useDevice()

  const contactHeadingId = useId()

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm">
      <DialogTitle sx={{ p: 1, m: 0 }} display="flex" justifyContent="flex-end">
        <IconButton aria-label="close" onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <ListItem>
            {!isMobile && (
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: 'surface.purple' }}>
                  <SendOutlined color="primary" />
                </Avatar>
              </ListItemAvatar>
            )}
            <ListItemText
              primary={heading}
              primaryTypographyProps={{ variant: 'h5' }}
            />
          </ListItem>

          <Typography>
            {replaceEpiVariablesWithJSX(description, {
              settingsLink: (
                <MuiLink component={Link} to={settingsLink} key="settingsLink">
                  {settingsLinkLabel}
                </MuiLink>
              ),
            })}
          </Typography>
          <Typography id={contactHeadingId}>{contactHeading}</Typography>
          <Stack
            component={List}
            spacing={1}
            aria-labelledby={contactHeadingId}
            py={0}
          >
            <ListItem>
              <ListItemIcon sx={isMobile ? ListItemIconMobileSx : undefined}>
                <Phone sx={{ color: (t) => t.palette.text.primary }} />
              </ListItemIcon>
              <ListItemText
                primary={user.mobilePhone || missingInfo}
                primaryTypographyProps={{ sx: TruncateSx }}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon sx={isMobile ? ListItemIconMobileSx : undefined}>
                <Email sx={{ color: (t) => t.palette.text.primary }} />
              </ListItemIcon>
              <ListItemText
                primary={user.email || missingInfo}
                primaryTypographyProps={{ sx: TruncateSx }}
              />
            </ListItem>
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          variant="text"
          component={Link}
          to={buttonLink + 'matchade'}
        >
          {buttonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
