import React from 'react'
import { Box, Typography, TypographyProps } from '@mui/material'
import { date } from '@trr/frontend-datetime'

import usePickEpiContent, {
  replaceEpiVariables,
} from '../../../../Utils/Content/usePickEpiContent'

interface AdvertSubtitleProps extends TypographyProps {
  publicationDate: Date
  recruiterName?: string
}

export const AdvertSubtitle: React.FC<AdvertSubtitleProps> = ({
  publicationDate,
  recruiterName,
  ...props
}) => {
  const { recruiterText } = usePickEpiContent().selectedViewNy.jobadContent
  return (
    <Typography variant="body2" {...props}>
      {publicationDate && date.format(publicationDate, { year: true })}
      {recruiterName && (
        <span>
          {publicationDate && ` \u2022 `}
          <Box component="span" sx={{ color: 'text.secondary' }}>
            {replaceEpiVariables(recruiterText, {
              recruiter: recruiterName,
            })}
          </Box>
        </span>
      )}
    </Typography>
  )
}
