import React, { useEffect } from 'react'
import { useTheme } from '@mui/material'
import { Breakpoint } from '@mui/system'

interface ContainerQueryContextType {
  query: {
    down: (br: Breakpoint) => boolean
    up: (br: Breakpoint) => boolean
  }
}

const ContainerQueryContext =
  React.createContext<ContainerQueryContextType | null>(null)

interface ContainerQueryProviderProps {
  containerRef: React.RefObject<HTMLDivElement>
  breakPoints?: Partial<Record<Breakpoint, number>>
}

export const ContainerQueryProvider: React.FC<
  React.PropsWithChildren & ContainerQueryProviderProps
> = ({ containerRef, breakPoints, children }) => {
  const [currentWidth, setCurrentWidth] = React.useState<number>(
    containerRef.current?.clientWidth
  )
  breakPoints = { ...useTheme().breakpoints.values, ...breakPoints }

  useEffect(() => {
    const current = containerRef.current
    const observer = new ResizeObserver((entries) => {
      setCurrentWidth(entries[0].contentRect.width)
    })
    observer.observe(current)
    return () => current && observer.unobserve(current)
  }, [containerRef])

  const down = (br: Breakpoint) => currentWidth < breakPoints[br]
  const up = (br: Breakpoint) => currentWidth > breakPoints[br]

  return (
    <ContainerQueryContext.Provider
      value={{
        query: { down, up },
      }}
    >
      {children}
    </ContainerQueryContext.Provider>
  )
}

export const useContainerQuery = () => {
  const context = React.useContext(ContainerQueryContext)
  if (context === undefined) {
    throw new Error(
      'useContainerQuery must be used within a ContainerQueryProvider'
    )
  }
  return context
}
