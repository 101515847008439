import React, { PropsWithChildren, useState } from 'react'

interface SmartAccordionProps extends PropsWithChildren {
  currentOpenAccordion?: string | null
  setCurrentOpenAccordion: (panelId: string | null) => void
}

export const SmartAccordionContext =
  React.createContext<SmartAccordionProps | null>(null)

export const SmartAccordionProvider: React.FC<
  { defaultOpenAccordion?: string | null } & PropsWithChildren
> = ({ defaultOpenAccordion, children }) => {
  const [currentOpenAccordion, setCurrentOpenAccordion] = useState<
    string | null
  >(defaultOpenAccordion)

  const handleSetCurrentOpenAccordion = (panelId: string | null) => {
    setCurrentOpenAccordion((prev) =>
      !!prev && prev === panelId ? null : panelId
    )
  }

  return (
    <SmartAccordionContext.Provider
      value={{
        currentOpenAccordion,
        setCurrentOpenAccordion: handleSetCurrentOpenAccordion,
      }}
    >
      {children}
    </SmartAccordionContext.Provider>
  )
}
