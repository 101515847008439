import { baseApi } from './baseApi'
import {
  convertToSwedishTypesRecursive,
  convertToLocationsWithHighlight,
  convertToJobTitlesWithHighlight,
} from './helpers/autocompleteHelpers'
import { DeepLocation, JobTitle } from './types/api.types'

const autocompleteApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getLocationsWithHighlight: builder.query<DeepLocation[], string>({
      query: (location) => ({
        url: `/referencedata/locations/suggest/${location}`,
      }),
      keepUnusedDataFor: 0,
      transformResponse: (response: DeepLocation[]) =>
        response
          .map((loc) => convertToSwedishTypesRecursive(loc))
          .map((job) => convertToLocationsWithHighlight(job)),
    }),
    getLocations: builder.query<DeepLocation[], string>({
      query: (location) => {
        return {
          url: `/referencedata/locations/suggest/${location}`,
        }
      },
      keepUnusedDataFor: 0,
      transformResponse: (response: DeepLocation[]) =>
        response.map((loc) => convertToSwedishTypesRecursive(loc)),
    }),
    getJobTitles: builder.query<JobTitle[], string>({
      query: (job) => ({
        url: `/referencedata/jobtitles/v2/suggestions/${job}`,
      }),
      keepUnusedDataFor: 0,
      transformResponse: (jobTitles: JobTitle[]) =>
        jobTitles.map((job) => convertToJobTitlesWithHighlight(job)),
    }),
  }),
})

export const {
  useGetLocationsWithHighlightQuery,
  useGetLocationsQuery,
  useGetJobTitlesQuery,
} = autocompleteApi
