import { ListItem, ListItemText, Typography, useTheme } from '@mui/material'
import React from 'react'

import { HistoricSearchesResponse } from '../../../Api/types/api.types'
import usePickEpiContent from '../../../Utils/Content/usePickEpiContent'

interface HistoricSearchOptionProps
  extends React.HTMLAttributes<HTMLLIElement> {
  option: HistoricSearchesResponse
}

const HistoricSearchOption: React.FC<HistoricSearchOptionProps> = ({
  option,
  ...props
}) => {
  const { palette } = useTheme()

  const { anywhere } = usePickEpiContent().sokNy.yrkenSearch

  return (
    <ListItem {...props} divider={false}>
      <ListItemText
        sx={{ m: 0 }}
        primary={option.professions.join(' | ')}
        translate="no"
        secondary={
          option.locationInformations.length
            ? option.locationInformations
                .map((ls) => `${ls.name}, ${ls.type}`)
                .join(' | ')
            : anywhere
        }
        primaryTypographyProps={{
          noWrap: true,
          overflow: 'ellipsis',
        }}
        secondaryTypographyProps={{
          noWrap: true,
          overflow: 'ellipsis',
        }}
      />
      {option.newJobsCount > 0 && (
        <Typography color={palette.info.main} sx={{ whiteSpace: 'noWrap' }}>
          {`${option.newJobsCount} ${option.newJobsCount === 1 ? 'ny' : 'nya'}`}
        </Typography>
      )}
    </ListItem>
  )
}

export default HistoricSearchOption
