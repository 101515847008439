import React, { FunctionComponent } from 'react'
import { OrderOption } from '@local/Api/types/api.types'

import { useGetSavedJobAdsQuery } from '../../Api/SavedJobAdsApi'
import usePickEpiContent, {
  replaceEpiVariables,
} from '../../Utils/Content/usePickEpiContent'
import EmptyResult from '../../Components/EmptyResult/EmptyResult'
import { SearchJobListItem } from '../JobSearch/Components/SearchJobListItem'
import { JobAdsPage } from '../../Components/JobAdsPage/JobAdsPage'
import { useSearchQueryParams } from '../../Utils/Hooks/useSearchQueryParams'

export const SavedJobsPage: FunctionComponent = () => {
  const { sortBar, emptyResult } = usePickEpiContent().sparadeNy

  const { formattedSearchParams } = useSearchQueryParams()

  const formattedsortBar = [
    {
      text: sortBar['saveddate desc'],
      type: OrderOption.SavedDate,
    },
    {
      text: sortBar['applicationdeadline asc'],
      type: OrderOption.ApplicationDeadline,
    },
    {
      text: sortBar['publicationdate desc'],
      type: OrderOption.PublicationDate,
    },
  ]

  const page = formattedSearchParams.page || 1
  const orderBy =
    (formattedSearchParams.orderBy as OrderOption) || OrderOption.SavedDate

  const { data, isLoading } = useGetSavedJobAdsQuery({
    orderBy,
    page,
    pageSize: 14,
  })

  const renderHitsText = (hits = 0) =>
    replaceEpiVariables(hits === 1 ? sortBar.hitsSingular : sortBar.hits, {
      numberOfHits: hits.toString(),
    })

  return (
    <JobAdsPage
      jobAds={data?.jobAds}
      orderOptions={formattedsortBar}
      order={orderBy}
      page={page}
      totalHits={data?.totalHits}
      totalPages={data?.totalPages}
      isLoading={isLoading}
      renderItem={(jobAd) => <SearchJobListItem job={jobAd} />}
      renderHitsText={renderHitsText}
      EmptyResult={
        <EmptyResult
          heading={emptyResult.title}
          description={emptyResult.body}
        />
      }
    />
  )
}
