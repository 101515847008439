import React from 'react'
import { Grid2 as Grid } from '@mui/material'

export const CardGridItem = ({ children }) => (
  <Grid
    size={{ xs: 12, md: 6 }}
    display={'flex'}
    width={'100%'}
    height={'auto'}
  >
    {children}
  </Grid>
)
