import React from 'react'
import { CardProps } from '@mui/material'
import { RadarRounded } from '@mui/icons-material'
import PuffCard from '@local/Components/PuffCard'

import usePickEpiContent, {
  replaceEpiVariables,
} from '../../../../Utils/Content/usePickEpiContent'

interface SuggestionsPuffProps extends CardProps {
  count: number
}

const SuggestionsPuff: React.FC<SuggestionsPuffProps> = ({
  count,
  ...props
}) => {
  const { categorization, title, subtitle, to, action } =
    usePickEpiContent().sokNy.suggestionsPuff
  const heading = replaceEpiVariables(title, { count: count.toString() })

  return (
    <PuffCard
      overline={categorization}
      heading={heading}
      description={subtitle}
      href={to}
      actionText={action}
      Icon={RadarRounded}
      {...props}
    />
  )
}

export default SuggestionsPuff
