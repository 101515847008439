import React from 'react'
import { useTrackCustomClick } from '@trr/gtm-tracking'
import { BoltOutlined } from '@mui/icons-material'

import { useFeatureFlag } from '../../../../../Utils'
import { quickApplicationFormComponentId } from '../../../QuickApplication/QuickApplicationForm/QuickApplicationForm'
import usePickEpiContent from '../../../../../Utils/Content/usePickEpiContent'

import { ApplyButton } from './ApplyButton/ApplyButton'

export const QuickApplyButton: React.FC = () => {
  const { applyButtonQuickApplication } =
    usePickEpiContent().selectedViewNy.callToAction

  const trackClick = useTrackCustomClick()
  const { isGtmEnabled } = useFeatureFlag()

  return (
    <ApplyButton
      Icon={BoltOutlined}
      label={applyButtonQuickApplication}
      onClick={() => {
        if (isGtmEnabled) trackClick('Ansök via TRR Rekrytering')

        document
          .getElementById(quickApplicationFormComponentId)
          ?.scrollIntoView({ behavior: 'smooth' })
      }}
    />
  )
}
