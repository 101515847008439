import React, { PropsWithChildren } from 'react'
import { Collapse, Divider, Paper, Stack, Typography } from '@mui/material'

import { ApplicationType } from '../../../../Api/types/api.types'
import usePickEpiContent from '../../../../Utils/Content/usePickEpiContent'
import { hasDatePassed } from '../../../../Utils/Helpers/dateHelpers'

import { SaveCheckbox } from './SaveCheckbox/SaveCheckbox'
import { CallToActionHeader } from './CallToActionHeader/CallToActionHeader'
import { ApplicationDeadline } from './ApplicationDeadline/ApplicationDeadline'
import { QuickApplyButton } from './ApplyButtons/QuickApplyButton'
import { EmailApplyButton } from './ApplyButtons/EmailApplyButton'
import { ExternalApplyButton } from './ApplyButtons/ExternalApplyButton'
import { ApplyCheckbox } from './ApplyCheckbox/ApplyCheckbox'

interface AdvertCallToActionProps {
  jobId: string
  isSaved: boolean
  hasApplied: boolean
  isLoading?: boolean
  applicationType: ApplicationType
  applicationInformationValue?: string
  applicationDeadline?: Date
}

const CallToActionWrapper: React.FC<PropsWithChildren> = ({ children }) => (
  <Paper component={Stack} variant="outlined" p={3} spacing={2}>
    {children}
  </Paper>
)

export const AdvertCallToAction: React.FC<AdvertCallToActionProps> = ({
  jobId,
  isSaved,
  hasApplied = false,
  isLoading = false,
  applicationType,
  applicationDeadline,
  applicationInformationValue,
}) => {
  const { quickApplicationDescription } =
    usePickEpiContent().selectedViewNy.callToAction

  const deadlineIsBeforeToday = hasDatePassed(new Date(applicationDeadline))

  const isAppliable =
    (!applicationDeadline || !deadlineIsBeforeToday) && !hasApplied

  const isSaveable = !hasApplied

  const ApplicationDeadlineComponent = (
    <ApplicationDeadline
      applicationDeadline={
        applicationDeadline ? new Date(applicationDeadline) : undefined
      }
    />
  )

  if (applicationType === 'TrrRecruitment') {
    return (
      <CallToActionWrapper>
        <Stack spacing={2} divider={<Divider />}>
          {isSaveable && <SaveCheckbox jobId={jobId} isSaved={isSaved} />}
          {isAppliable && (
            <Stack spacing={2}>
              <CallToActionHeader applicationType={applicationType} />
              <Typography variant="body2">
                {quickApplicationDescription}
              </Typography>
            </Stack>
          )}
          <Stack spacing={2}>
            {ApplicationDeadlineComponent}
            {isAppliable && <QuickApplyButton />}
          </Stack>
        </Stack>
      </CallToActionWrapper>
    )
  }

  const isEmailApplication = applicationType === 'Email'
  const isExternalApplication =
    applicationType === 'External' || applicationType === 'Other'

  if (isEmailApplication || isExternalApplication) {
    return (
      <CallToActionWrapper>
        <Stack spacing={2}>
          {isSaveable && <SaveCheckbox jobId={jobId} isSaved={isSaved} />}
          <Collapse in={!isLoading && isAppliable} mountOnEnter unmountOnExit>
            <Stack spacing={2}>
              <Divider />
              <CallToActionHeader applicationType={applicationType} />
              {ApplicationDeadlineComponent}
              {isEmailApplication && (
                <EmailApplyButton to={applicationInformationValue} />
              )}
              {isExternalApplication && (
                <ExternalApplyButton url={applicationInformationValue} />
              )}
            </Stack>
          </Collapse>
          {!isAppliable && ApplicationDeadlineComponent}
          {!isLoading && (
            <>
              <Divider />
              <ApplyCheckbox jobId={jobId} hasApplied={hasApplied} />
            </>
          )}
        </Stack>
      </CallToActionWrapper>
    )
  }

  return null
}
