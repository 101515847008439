import { debounce } from 'lodash'
import { useEffect, useState } from 'react'

export const useScroll = (opt: { delay: number } = { delay: 200 }) => {
  const [scroll, setScroll] = useState<{ x: number; y: number }>()
  useEffect(() => {
    const handleScroll = () => {
      setScroll({ x: window.scrollX, y: window.scrollY })
    }

    const devouncedHandleScroll = debounce(handleScroll, opt.delay)

    window.addEventListener('scroll', devouncedHandleScroll)

    return () => {
      window.removeEventListener('scroll', devouncedHandleScroll)
    }
  })

  return scroll
}
