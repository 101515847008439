import {
  Autocomplete,
  AutocompleteCloseReason,
  Box,
  InputLabel,
  ListItem,
  ListItemText,
  TextField,
} from '@mui/material'
import parse from 'html-react-parser'
import React, { useState } from 'react'
import { useTrackCustomClick } from '@trr/gtm-tracking'
import { useFeatureFlag } from '@local/Utils'

import { useGetLocationsWithHighlightQuery } from '../../../Api/autocompleteApi'
import { useGetHistoricSearchesQuery } from '../../../Api/historicSearchesApi'
import { DeepLocation } from '../../../Api/types/api.types'
import useDebounce from '../../../Utils/Hooks/useDebounce'
import usePickEpiContent from '../../../Utils/Content/usePickEpiContent'
import { AutocompletePopper } from '../Components/AutocompletePopper'

import {
  getUniqueLocationsFromHistoricalSearches,
  isNotAlreadyInList,
} from './Utils/helpers'
import { JobAdLocation } from './Utils/types'

interface LocationAutocompleteProps {
  locations: JobAdLocation[]
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    newLocations: DeepLocation[]
  ) => void
}

export const LocationAutocomplete = ({
  locations = [],
  onChange,
}: LocationAutocompleteProps) => {
  const { label, placeholder, noResult, searchHistorySubHeader } =
    usePickEpiContent().sokNy.arbetsortSearch

  const trackClick = useTrackCustomClick()
  const { isGtmEnabled } = useFeatureFlag()

  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 300)

  const skipSearch = debouncedSearchTerm.length < 2

  const { data, isFetching } = useGetLocationsWithHighlightQuery(
    debouncedSearchTerm,
    {
      skip: skipSearch,
    }
  )

  const notAlreadySelectedAutocompleteLocations = data?.filter(
    isNotAlreadyInList(locations)
  )

  const { data: historicSearches } = useGetHistoricSearchesQuery()

  const uniqueHistoricSearchLocations =
    getUniqueLocationsFromHistoricalSearches(historicSearches)
      ?.filter(isNotAlreadyInList(locations))
      .slice(0, 7)

  const autocompleteOptions = notAlreadySelectedAutocompleteLocations ?? []
  const historicSearchOptions = uniqueHistoricSearchLocations ?? []

  const showAutocompleteOptions = !skipSearch
  const showHistoricalSearches =
    debouncedSearchTerm.length === 0 &&
    uniqueHistoricSearchLocations?.length > 0

  const options = [
    ...(showAutocompleteOptions ? autocompleteOptions : []),
    ...(showHistoricalSearches ? historicSearchOptions : []),
  ]

  const handleOnClose = (e, reason: AutocompleteCloseReason) => {
    const identifier = 'Platsfältet'
    if (reason === 'selectOption' && isGtmEnabled) {
      if (showHistoricalSearches) {
        trackClick(identifier, { label: 'Användaren valde en senaste sökning' })
      }
      if (showAutocompleteOptions) {
        trackClick(identifier, {
          label: 'Användaren gjorde ett val ur den automatiska kompletteringen',
        })
      }
    }
  }

  return (
    <Box sx={{ mb: 2 }}>
      <InputLabel>{label}</InputLabel>
      <Autocomplete
        // Prevents no options text display on initial focus
        freeSolo={!searchTerm.length}
        multiple
        autoHighlight
        options={options ?? []}
        filterOptions={(x) => x}
        filterSelectedOptions
        value={locations as DeepLocation[]}
        inputValue={searchTerm}
        openOnFocus
        clearOnBlur
        onChange={onChange}
        onClose={handleOnClose}
        onInputChange={(_event, value) => setSearchTerm(value)}
        popupIcon={null}
        PopperComponent={AutocompletePopper}
        ChipProps={{ translate: 'no' }}
        ListboxProps={
          showHistoricalSearches
            ? {
                sx: {
                  maxHeight: 500,
                },
              }
            : undefined
        }
        noOptionsText={
          showAutocompleteOptions && !isFetching && data.length === 0
            ? noResult
            : undefined
        }
        getOptionLabel={(option: DeepLocation) => {
          if (option.type === 'undefined') return null
          return `${option.name}, ${option.type}`
        }}
        renderOption={(props, option) => (
          <ListItem {...props} divider={false}>
            <ListItemText
              primary={parse(
                `${option.nameWithHighLightedSearchTerm ?? option.name}, ${
                  option.type
                }`
              )}
              primaryTypographyProps={{
                noWrap: true,
                overflow: 'ellipsis',
              }}
            />
          </ListItem>
        )}
        groupBy={
          showHistoricalSearches ? () => searchHistorySubHeader : undefined
        }
        renderInput={(params) => (
          <TextField {...params} placeholder={placeholder} />
        )}
        data-testid="location-autocomplete"
      />
    </Box>
  )
}
