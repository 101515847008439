import { useAuthentication } from '@trr/app-shell-data'
import {
  hasDatePassed,
  isLessThanSevenDaysLeft,
} from '@local/Utils/Helpers/dateHelpers'

import { useGetSavedJobAdsQuery } from '../../../Api/SavedJobAdsApi'
import {
  useGetProfileSuggestionsCountQuery,
  useHasCompletedMatchingProfileQuery,
} from '../../../Api/matchingProfileApi'
import { OrderOption } from '../../../Api/types/api.types'
import usePickEpiContent from '../../../Utils/Content/usePickEpiContent'

export interface PuffGridParams {
  hasCompletedMatchingProfile: boolean
  suggestionsCount: number
  isLoading: boolean
  hasError: boolean
  hasSavedJobAdsEndingSoon: boolean
  hasSavedJobAdsEndingSoonPuffContent: boolean
  hasCreateMatchingProfilePuffContent: boolean
  hasSuggestionsPuffContent: boolean
  hasImproveResumePuffContent: boolean
}

const usePuffGrid = (): PuffGridParams => {
  const { sub } = useAuthentication()

  const {
    createMatchingProfilePuff,
    savedAdsEndingSoonPuff,
    suggestionsPuff,
    improveResumePuff,
  } = usePickEpiContent().sokNy

  const {
    data: hasCompletedMatchingProfile,
    isLoading: isLoadingMatchingProfile,
    isError: hasMatchingProfileError,
  } = useHasCompletedMatchingProfileQuery(sub, {
    skip: !createMatchingProfilePuff,
  })

  const {
    data: savedJobAds,
    isLoading: isLoadingSavedJobAds,
    isError: hasSavedJobAdsError,
  } = useGetSavedJobAdsQuery(
    {
      page: 1,
      pageSize: 20,
      orderBy: OrderOption.SavedDate,
    },
    {
      skip: !savedAdsEndingSoonPuff,
    }
  )

  const {
    data: suggestionsCountResponse,
    isLoading: isLoadingSuggestionsCount,
    isError: hasSuggestionsCountError,
  } = useGetProfileSuggestionsCountQuery(sub, {
    skip: !suggestionsPuff || !hasCompletedMatchingProfile,
  })

  const hasError =
    hasMatchingProfileError || hasSavedJobAdsError || hasSuggestionsCountError

  const isLoading =
    isLoadingMatchingProfile ||
    isLoadingSavedJobAds ||
    isLoadingSuggestionsCount

  const hasSavedJobAdsEndingSoon = savedJobAds?.jobAds?.some(
    (ad) =>
      isLessThanSevenDaysLeft(new Date(ad.deadline)) &&
      !hasDatePassed(new Date(ad.deadline))
  )

  return {
    hasCompletedMatchingProfile,
    suggestionsCount: suggestionsCountResponse ?? 0,
    isLoading,
    hasError,
    hasSavedJobAdsEndingSoon,
    hasSavedJobAdsEndingSoonPuffContent: !!savedAdsEndingSoonPuff,
    hasCreateMatchingProfilePuffContent: !!createMatchingProfilePuff,
    hasImproveResumePuffContent: !!improveResumePuff,
    hasSuggestionsPuffContent: !!suggestionsPuff,
  }
}

export default usePuffGrid
