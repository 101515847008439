import { Box, CircularProgress } from '@mui/material'
import React from 'react'

export const LoadingSpinner: React.FC = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress color="primary" />
    </Box>
  )
}
