import React from 'react'
import { Chip } from '@mui/material'

export const BetaChip: React.FC = () => (
  <Chip
    label="BETA"
    size="small"
    variant="filled"
    color="primary"
    sx={{
      bgcolor: 'primary.main',
      color: 'white',
    }}
  />
)
