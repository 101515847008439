import { GeneratedCv } from '../Features/SelectedJob/GeneratedCv/GeneratedCv.types'

import { baseApi } from './baseApi'

export interface GeneratedCvRequest {
  adId: string
  body: {
    text: string
  }
  //   language: LanguageCode
  //   systemMessage?: string
  //   cvContent: string
}

const generatedCvApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    generateCv: builder.query<GeneratedCv, GeneratedCvRequest>({
      query: ({ adId, body }) => ({
        url: `/jobs/ads/${adId}/resume`,
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const { useGenerateCvQuery } = generatedCvApi
