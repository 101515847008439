import React from 'react'
import { Box, Typography, styled } from '@mui/material'
import parseHtml from 'html-react-parser'

import { formatJobText } from '../../Hooks/formatJobText'
import usePickEpiContent, {
  replaceEpiVariables,
} from '../../../../Utils/Content/usePickEpiContent'

interface AdvertContentProps {
  description: string
  htmlDescription?: string
  companyHtmlDescription?: string
  companyName?: string
}

const FormattedJobText = styled('div')(({ theme }) => ({
  '& h1, h2, h3, h4, h5, h6': {
    ...theme.typography.h6,
    marginBlockStart: theme.spacing(2),
    marginBlockEnd: 0,
  },
  '& p': {
    margin: 'revert',
    ...theme.typography.body1,
  },
  'p:empty::after': {
    content: '" "',
    display: 'inline-block',
  },
  'li + li': {
    marginTop: theme.spacing(2),
  },
  lineHeight: theme.typography.body1.lineHeight,
  color: theme.palette.text.primary,
  '& a': { color: theme.palette.primary.main },
  wordBreak: 'break-word',
  whiteSpace: 'pre-wrap',
}))

export const AdvertContent: React.FC<AdvertContentProps> = ({
  description,
  htmlDescription,
  companyHtmlDescription,
  companyName,
}) => {
  const { employerHeadline } = usePickEpiContent().selectedViewNy.jobadContent

  return (
    <Box>
      <Typography variant="h5">Om tjänsten</Typography>
      <FormattedJobText>
        {parseHtml(htmlDescription ?? formatJobText(description) ?? '')}
      </FormattedJobText>
      {companyHtmlDescription && (
        <>
          <Typography variant="h5">
            {replaceEpiVariables(employerHeadline, {
              employer: companyName,
            })}
          </Typography>
          <FormattedJobText>
            {parseHtml(companyHtmlDescription ?? '')}
          </FormattedJobText>
        </>
      )}
    </Box>
  )
}
