import React from 'react'
import { isNewerThanSevenDaysOld } from '@local/Utils/Helpers/dateHelpers'
import { Chip } from '@mui/material'

interface NewIndicatorProps {
  publishDate: Date
  lastSearchDate?: Date
}

export const NewIndicator: React.FC<NewIndicatorProps> = ({
  publishDate,
  lastSearchDate,
}) => {
  const isNewSinceLastSearch = lastSearchDate && publishDate > lastSearchDate
  const isNewSinceLastWeek =
    !lastSearchDate && isNewerThanSevenDaysOld(publishDate)

  return isNewSinceLastWeek || isNewSinceLastSearch ? (
    <Chip label={'Ny'} variant="status-info" sx={{ height: 0 }} />
  ) : null
}
