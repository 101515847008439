import { baseApi } from './baseApi'

export interface MatchingProfileResponse {
  nonCompletedWizardSteps: unknown[]
  cvPdf?: { id: string; name: string }
}

export interface MatchingProfileSuggestionsResponse {
  competences: unknown[]
  locations: unknown[]
  professions: unknown[]
}

export const profileApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    hasCompletedMatchingProfile: builder.query<boolean, string>({
      query: (clientId) => `/client-match/profiles/${clientId}`,
      transformResponse: (baseQueryReturnValue: MatchingProfileResponse) => {
        return baseQueryReturnValue.nonCompletedWizardSteps.length === 0
      },
      keepUnusedDataFor: 0,
    }),
    getMatchProfile: builder.query<MatchingProfileResponse, string>({
      query: (clientId) => `/client-match/profiles/${clientId}`,
      keepUnusedDataFor: 0,
    }),
    getProfileSuggestionsCount: builder.query<number, string>({
      query: (clientId) => `/client-match/profiles/${clientId}/suggestions`,
      transformResponse: (response: MatchingProfileSuggestionsResponse) =>
        (response?.competences?.length ?? 0) +
        (response?.locations?.length ?? 0) +
        (response?.professions?.length ?? 0),
      keepUnusedDataFor: 0,
    }),
  }),
})

export const {
  useHasCompletedMatchingProfileQuery,
  useGetProfileSuggestionsCountQuery,
  useGetMatchProfileQuery,
} = profileApi
