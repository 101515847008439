import React, { useState } from 'react'
import { Box, Button } from '@mui/material'
import { useHotjar } from '@local/Utils/Hooks/useHotjar'
import { GetJobAdResponse } from '@local/Api/JobAdApi'

import {
  useDeleteQuickApplicationMutation,
  useGetQuickApplicationQuery,
} from '../../../Api/quickApplicationApi'
import { useFeatureFlag } from '../../../Utils'

import { QuickApplicationForm } from './QuickApplicationForm/QuickApplicationForm'
import { SuccessfulApplicationDialog } from './QuickApplicationForm/Dialogs/SuccessfulApplicationDialog/SuccessfulApplicationDialog'

interface QuickApplicationProps {
  job: GetJobAdResponse
}

const QuickApplication: React.FC<QuickApplicationProps> = ({ job }) => {
  const [isSuccessApplicationDialogOpen, setIsSuccessApplicationDialogOpen] =
    useState(false)
  const { isDeleteQuickApplicationEnabled } = useFeatureFlag()

  const { quickApplicationEvent } = useHotjar()

  const { data: quickApplication, isSuccess: isSuccessQuickApplication } =
    useGetQuickApplicationQuery(job.id, {
      skip: !job || job.application.type !== 'TrrRecruitment',
    })

  const [deleteApplication] = useDeleteQuickApplicationMutation()

  const showApplicationForm =
    job.application.type === 'TrrRecruitment' &&
    isSuccessQuickApplication &&
    !quickApplication

  const showDeleteApplicationButton =
    isDeleteQuickApplicationEnabled &&
    job.application.type === 'TrrRecruitment' &&
    quickApplication

  return (
    <Box>
      {showDeleteApplicationButton && (
        <Button
          color="error"
          onClick={() =>
            deleteApplication({
              jobId: job.id,
              applicationId: quickApplication?.id,
            })
          }
          style={{ marginTop: 200 }}
        >
          Avbryt ansökan
        </Button>
      )}
      {showApplicationForm && (
        <QuickApplicationForm
          jobId={job.id}
          requiredSkills={job.position.trrRecruitmentSkills ?? []}
          onApplicationSuccess={() => {
            setIsSuccessApplicationDialogOpen(true)
            quickApplicationEvent()
            window.scrollTo({ top: 0, behavior: 'smooth' })
          }}
        />
      )}
      <SuccessfulApplicationDialog
        isOpen={isSuccessApplicationDialogOpen}
        onClose={() => setIsSuccessApplicationDialogOpen(false)}
      />
    </Box>
  )
}

export default QuickApplication
