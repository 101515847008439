import React from 'react'
import {
  Button,
  ButtonTypeMap,
  ExtendButtonBase,
  IconButton,
} from '@mui/material'
import { useDevice } from '@trr/app-shell-data'

export const ActionButton: ExtendButtonBase<
  ButtonTypeMap<unknown, 'button'>
> = (props) => {
  const { isMobile, isTablet } = useDevice()

  if (isMobile || isTablet) {
    return (
      <IconButton
        color="primary"
        title={typeof props.children === 'string' ? props.children : ''}
        {...props}
      >
        {props.startIcon}
      </IconButton>
    )
  }

  return <Button variant="text" size="small" {...props} />
}
