import React from 'react'
import { FeedbackCard } from '@local/Components/FeedbackCard/FeedbackCard'
import usePickEpiContent from '@local/Utils/Content/usePickEpiContent'
import { useHotjar } from '@local/Utils/Hooks/useHotjar'

export const HotjarCvFeedback: React.FC = () => {
  const { leaveCvFeedbackEvent } = useHotjar()
  const {
    title,
    description,
    primaryButton,
    secondaryButton,
    secondaryButtonLink,
  } = usePickEpiContent().createCv
  const handleTriggerFeedback = () => {
    leaveCvFeedbackEvent()
  }

  return (
    <FeedbackCard
      title={title}
      description={description}
      primaryButtonLabel={primaryButton}
      primaryButtonProps={{
        onClick: handleTriggerFeedback,
      }}
      secondaryButtonLabel={secondaryButton}
      secondaryButtonProps={{
        href: secondaryButtonLink,
      }}
    />
  )
}
