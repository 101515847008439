import React from 'react'
import { CelebrationOutlined } from '@mui/icons-material'
import { date } from '@trr/frontend-datetime'

import { StatusCard } from '../../../../../Components/StatusCard/StatusCard'
import { MyApplicationDrawer } from '../../MyApplicationDrawer/MyApplicationDrawer'
import usePickEpiContent, {
  replaceEpiVariables,
} from '../../../../../Utils/Content/usePickEpiContent'

interface HiredStatusCardProps {
  applicationDate: Date
  lastUpdatedDate: Date
}

export const HiredStatusCard: React.FC<HiredStatusCardProps> = ({
  applicationDate,
  lastUpdatedDate,
}) => {
  const [isOpenApplicationDrawer, setIsOpenApplicationDrawer] =
    React.useState(false)

  const {
    hiredHeading,
    hiredDescription,
    applicationDateText,
    openApplicationButtonLabel,
  } = usePickEpiContent().selectedViewNy.applicationStatus

  return (
    <>
      <MyApplicationDrawer
        isOpen={isOpenApplicationDrawer}
        onClose={() => setIsOpenApplicationDrawer(false)}
      />
      <StatusCard
        heading={hiredHeading}
        overline={date.format(lastUpdatedDate, { year: true })}
        body={hiredDescription}
        icon={<CelebrationOutlined />}
        iconColor="success"
        action={{
          label: openApplicationButtonLabel,
          onClick: () => setIsOpenApplicationDrawer(true),
        }}
        footer={replaceEpiVariables(applicationDateText, {
          date: date.format(applicationDate, { year: true }),
        })}
      />
    </>
  )
}
