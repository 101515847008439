import { OrderOption } from '@local/Api/types/api.types'

import { scrollIntoView } from '../Helpers/scrollIntoView'

import { useSearchQueryParams } from './useSearchQueryParams'

export const usePagination = () => {
  const { setSearchNumberField, setSearchParamField } = useSearchQueryParams()

  const handlePageChange = (
    page: number,
    scrollRef?: React.MutableRefObject<unknown>
  ) => {
    setSearchNumberField(page, 'page')
    scrollRef && scrollIntoView(scrollRef)
  }

  const handleOrderChange = (sortOption: OrderOption) => {
    setSearchParamField(sortOption, 'orderBy')
  }

  return { handlePageChange, handleOrderChange }
}
