import React, { PropsWithChildren } from 'react'
import { View, ViewProps } from '@react-pdf/renderer'
import { useTheme } from '@mui/material'

import { pdfStyles as s } from '../styles'

interface PdfStackProps extends ViewProps {
  spacing: number
  sidebar?: boolean
}

export const PdfStack: React.FC<PropsWithChildren<PdfStackProps>> = ({
  spacing,
  children,
  style,
  sidebar = false,
  ...props
}) => {
  const theme = useTheme()
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: spacing,
        paddingRight: '3px',
        ...(sidebar ? s.sidebar : {}),
        ...style,
      }}
      {...props}
    >
      {sidebar && (
        <View
          style={{
            width: '.5px',
            height: '100%',
            position: 'absolute',
            top: 0,
            backgroundColor: theme.palette.divider,
          }}
        />
      )}
      {children}
    </View>
  )
}
