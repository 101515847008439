import {
  Box,
  Button,
  Stack,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  Typography,
} from '@mui/material'
import React, { useId } from 'react'
import { useUser } from '@trr/app-shell-data'

import usePickEpiContent from '../../../../../Utils/Content/usePickEpiContent'
import { useLazyGetUserQuery } from '../../../../../Api/userApi'
import { useVisibilityChange } from '../../../../../Utils/Hooks/useVisibilityChange'

export const ContactSection: React.FC = () => {
  const content = usePickEpiContent().selectedViewNy.quickApplication

  const emailLabelId = useId()
  const mobilePhoneLabelId = useId()

  const contextUser = useUser()
  const [fetchUser, { data: fetchedUser }] = useLazyGetUserQuery()

  useVisibilityChange((ev) => {
    ev.target.visibilityState === 'visible' && fetchUser()
  })

  const user = fetchedUser ?? contextUser

  const leftTableCellSx: SxProps = {
    pl: 0,
    color: (t: Theme) => t.palette.text.secondary,
    width: '40%',
  }

  const rightTableCellSx: SxProps = {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  }

  return (
    <Stack>
      <Stack spacing={3} direction="row" alignItems="center">
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h6" gutterBottom>
            {content.contactHeading}
          </Typography>
        </Box>
        <Button
          variant="text"
          size="small"
          sx={{ flexShrink: 0 }}
          href={content.contactSettingsLink}
          target="_blank"
        >
          {content.contactSettingsLinkLabel}
        </Button>
      </Stack>
      <Table sx={{ width: '100%', tableLayout: 'fixed' }}>
        <TableBody>
          <TableRow>
            <TableCell id={emailLabelId} sx={leftTableCellSx}>
              {content.contactEmail}
            </TableCell>
            <TableCell sx={rightTableCellSx} aria-labelledby={emailLabelId}>
              {user.email}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell id={mobilePhoneLabelId} sx={leftTableCellSx}>
              {content.contactPhone}
            </TableCell>
            <TableCell
              aria-labelledby={mobilePhoneLabelId}
              sx={rightTableCellSx}
            >
              {user.mobilePhone}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Stack>
  )
}
