import { useState } from 'react'
import { useFeatureFlag } from '@local/Utils'
import { useTrackCustomClick } from '@trr/gtm-tracking'
import { useFormikContext } from 'formik'

import { Cv } from '../../GeneratedCv.types'

export const useCvGtmTracker = () => {
  const { values, initialValues } = useFormikContext<Cv>()
  const track = useTrackCustomClick()
  const { isGtmEnabled } = useFeatureFlag()
  const [changedFields, setChangedFields] = useState<string[]>([])

  const trackChangeOnce = (identifier: keyof Cv, label: string) => {
    if (
      isGtmEnabled &&
      !changedFields.includes(identifier) &&
      values[identifier] !== initialValues[identifier]
    ) {
      track('cv_field_change', { label })
      setChangedFields([...changedFields, identifier])
    }
  }

  return { trackChangeOnce }
}
