import React, { useEffect, useState } from 'react'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { Paper, Stack, Typography } from '@mui/material'
import { DownloadForOfflineRounded, EditRounded } from '@mui/icons-material'

import { Cv } from '../GeneratedCv.types'
import { useWindowResizeListener } from '../../../../Utils/Hooks/useWindowResizeListener'

import { PdfDocument } from './Document/PdfDocument'
import { ActionButton } from './Components/ActionButton'
import { Viewer } from './Viewer/Viewer'

interface CvPdfProps {
  cv: Cv
  onEditClick: () => void
}

export const CvPdf: React.FC<CvPdfProps> = ({ cv, onEditClick }) => {
  const pdfDocument = PdfDocument({ cv })
  const [width, setWidth] = useState(100)

  const windowWidth = useWindowResizeListener()

  useEffect(() => {
    setWidth(document.getElementById('cv-pdf-container')?.clientWidth ?? 150)
  }, [cv, windowWidth])

  return (
    <Paper
      variant="outlined"
      sx={{ borderTopRightRadius: 16, borderTopLeftRadius: 16, width: '100%' }}
      id="cv-pdf-container"
    >
      <Stack
        direction="row"
        spacing={3}
        alignItems="flex-end"
        bgcolor={(t) => t.palette.surface.grey}
        borderRadius="inherit"
        px={3}
        pt={3}
        pb={2}
      >
        <Stack
          direction="row"
          spacing={3}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            Detta är ett AI-genererat CV. Granska och justera innehållet så det
            blir som du vill ha det.
          </Typography>
          <Stack direction="row" spacing={1} alignSelf="center">
            <ActionButton
              startIcon={<EditRounded />}
              onClick={onEditClick}
              data-gtm-label="Redigera CV"
            >
              Redigera
            </ActionButton>
            <ActionButton
              startIcon={<DownloadForOfflineRounded />}
              component={PDFDownloadLink}
              document={pdfDocument}
              download
              data-gtm-label="Ladda ner CV"
            >
              PDF
            </ActionButton>
          </Stack>
        </Stack>
      </Stack>
      <Viewer doc={pdfDocument} width={width} />
    </Paper>
  )
}
