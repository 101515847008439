import { includes } from 'ramda'

import { convertToSwedishLocationTypes } from '../../../../Api/helpers/autocompleteHelpers'
import { HistoricSearchesResponse } from '../../../../Api/types/api.types'

import { JobAdLocation } from './types'

export const getUniqueLocationsFromHistoricalSearches = (
  historicSearches: HistoricSearchesResponse[]
) =>
  historicSearches
    ?.map((hs) =>
      hs.locationInformations
        .map((loc) => ({
          ...loc,
          type: convertToSwedishLocationTypes(loc.type),
        }))
        // Reverse the array so that the most recent searches appear first
        .reverse()
    )
    .flat()
    .filter(
      (loc, index, self) =>
        index ===
        self.findIndex((t) => t.name === loc.name && t.type === loc.type)
    )

export const isNotAlreadyInList =
  (alreadyAddedLocations: JobAdLocation[]) => (location: JobAdLocation) =>
    !includes(
      {
        name: location.name.replace(/(<([^>]+)>)/gi, ''),
        type: location.type,
        id: null,
      },
      alreadyAddedLocations
    )
