import React, { useMemo, Suspense } from 'react'

import { LoadingSpinner } from '../LoadingSpinner'

import { useAppShellData } from './AppShellData'

type Frontend = 'beta-tester-widget'

interface Props {
  frontend: Frontend
}

export const MicroFrontendLoader = ({ frontend }: Props) => {
  const MicroFrontend = useMemo(
    () => React.lazy(() => System.import(frontend)),
    [frontend]
  )
  const appShellData = useAppShellData((data) => data)

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <MicroFrontend appShellData={appShellData} />
    </Suspense>
  )
}

export default MicroFrontendLoader
