import React, { useCallback, useEffect } from 'react'
import { Box, BoxProps } from '@mui/material'
import { useScrollTracker } from '@local/Utils/Hooks/useScrollTracker'

interface InfinityScrollWrapper extends BoxProps {
  shouldLoadMore: boolean
  onLoadMore: () => void
  scrollThreshold?: number
  maxHeight?: number
  preventOverscroll?: boolean
}

const THROTTLE_MS = 100

const InfinityScrollWrapper: React.FC<InfinityScrollWrapper> = ({
  onLoadMore,
  shouldLoadMore,
  scrollThreshold = 1000,
  maxHeight,
  preventOverscroll = false,
  ...boxProps
}) => {
  const lastRun = React.useRef<number>(null)
  const boxRef = React.useRef<HTMLDivElement>(null)

  useScrollTracker(boxRef, 200)

  const handleLoadMore = useCallback(() => {
    if (
      !boxRef ||
      !shouldLoadMore ||
      (lastRun.current && Date.now() - lastRun.current < THROTTLE_MS)
    )
      return

    const { scrollTop, scrollHeight, clientHeight } = boxRef.current
    const isAtBottom = scrollHeight - scrollTop - clientHeight < scrollThreshold

    if (isAtBottom) {
      onLoadMore()
      lastRun.current = Date.now()
    }
  }, [boxRef, shouldLoadMore, onLoadMore, scrollThreshold])

  useEffect(() => {
    handleLoadMore()
  }, [handleLoadMore])

  return (
    <Box
      sx={{
        overflowY: 'auto',
        height: '100%',
        maxHeight,
        overscrollBehavior:
          preventOverscroll || shouldLoadMore ? 'contain' : 'auto',
      }}
      ref={boxRef}
      onScroll={handleLoadMore}
      {...boxProps}
    />
  )
}

export default InfinityScrollWrapper
