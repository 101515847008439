import { OrderOption } from '@local/Api/types/api.types'
import {
  NumberParam,
  withDefault,
  QueryParamConfig,
  StringParam,
  useQueryParams,
  DelimitedArrayParam,
  BooleanParam,
} from 'use-query-params'

export interface SearchParams {
  locations: QueryParamConfig<string[], string[]>
  jobTitles: QueryParamConfig<string[], string[]>
  page: QueryParamConfig<number, number>
  orderBy: QueryParamConfig<string, string>
  searchTimeStamp: QueryParamConfig<string, string>
  currentFacet: QueryParamConfig<string, string>
  employmentTypes: QueryParamConfig<string[], string[]>
  employers: QueryParamConfig<string[], string[]>
  skills: QueryParamConfig<string[], string[]>
  workingHours: QueryParamConfig<string[], string[]>
  professions: QueryParamConfig<string[], string[]>
  otherCountries: QueryParamConfig<boolean, boolean>
  unspecifiedLocations: QueryParamConfig<boolean, boolean>
  providers: QueryParamConfig<string[], string[]>
  historicSearchTimeStamp: QueryParamConfig<string, string>
  searchAdDescription: QueryParamConfig<boolean, boolean>
  countries: QueryParamConfig<string[], string[]>
  municipalities: QueryParamConfig<string[], string[]>
  regions: QueryParamConfig<string[], string[]>
}

const defaultParams = {
  locations: withDefault(DelimitedArrayParam, []),
  jobTitles: withDefault(DelimitedArrayParam, []),
  page: withDefault(NumberParam, 1),
  orderBy: withDefault(StringParam, ''),
  searchTimeStamp: withDefault(StringParam, ''),
  currentFacet: withDefault(StringParam, ''),
  employmentTypes: withDefault(DelimitedArrayParam, []),
  employers: withDefault(DelimitedArrayParam, []),
  skills: withDefault(DelimitedArrayParam, []),
  workingHours: withDefault(DelimitedArrayParam, []),
  professions: withDefault(DelimitedArrayParam, []),
  otherCountries: withDefault(BooleanParam, false),
  unspecifiedLocations: withDefault(BooleanParam, false),
  providers: withDefault(DelimitedArrayParam, []),
  historicSearchTimeStamp: withDefault(StringParam, ''),
  searchAdDescription: withDefault(BooleanParam, false),
  countries: withDefault(DelimitedArrayParam, []),
  municipalities: withDefault(DelimitedArrayParam, []),
  regions: withDefault(DelimitedArrayParam, []),
}

export type NumberSearchParams = Pick<SearchParams, 'page'>
export type StringSearchParams = Pick<
  SearchParams,
  'orderBy' | 'locations' | 'professions' | 'currentFacet'
>
export type BooleanSearchParams = Pick<
  SearchParams,
  'otherCountries' | 'unspecifiedLocations' | 'searchAdDescription'
>
export type ArraySearchParams = Pick<
  SearchParams,
  | 'locations'
  | 'jobTitles'
  | 'employmentTypes'
  | 'employers'
  | 'skills'
  | 'workingHours'
  | 'professions'
  | 'providers'
  | 'countries'
  | 'municipalities'
  | 'regions'
>

export const useSearchQueryParams = () => {
  const [searchParams, setSearchParams] = useQueryParams(defaultParams, {
    updateType: 'replaceIn',
    removeDefaultsFromUrl: true,
  })

  const formattedSearchParams = {
    locations: searchParams.locations.map((location: string) => ({
      name: location.split(',')[0],
      type: location.split(',')[1],
      id: location.split(',')[2],
    })),
    professions: searchParams.professions as string[],
    page: searchParams.page,
    orderBy: searchParams.orderBy,
    searchTimeStamp: searchParams.searchTimeStamp,
    currentFacet: searchParams.currentFacet,
    otherCountries: searchParams.otherCountries,
    unspecifiedLocations: searchParams.unspecifiedLocations,
    searchAdDescription: searchParams.searchAdDescription,
    filters: {
      jobTitles: searchParams.jobTitles as string[],
      employmentTypes: searchParams.employmentTypes as string[],
      employers: searchParams.employers as string[],
      skills: searchParams.skills as string[],
      workingHours: searchParams.workingHours as string[],
      providers: searchParams.providers as string[],
      countries: searchParams.countries as string[],
      municipalities: searchParams.municipalities as string[],
      regions: searchParams.regions as string[],
    },
  }

  const hasActiveQueryParams =
    formattedSearchParams.professions.length > 0 ||
    formattedSearchParams.locations.length > 0

  const evaluateCurrentFacet = (
    value: string,
    fieldName: keyof ArraySearchParams
  ) => {
    if (searchParams[fieldName].includes(value)) {
      return searchParams[fieldName]?.length === 1 ? '' : fieldName
    }

    return fieldName
  }

  const setArraySearchParamField = (
    value: string,
    fieldName: keyof ArraySearchParams
  ) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore

    const currentFacet = evaluateCurrentFacet(value, fieldName)

    if (!searchParams[fieldName]?.includes(value)) {
      setSearchParams({
        [fieldName]: searchParams[fieldName]?.length
          ? [...searchParams[fieldName], value]
          : [value],
        currentFacet:
          currentFacet !== 'jobTitles' ? currentFacet : 'professions',
        page: 1,
      })
    } else {
      setSearchParams({
        [fieldName]: searchParams[fieldName].filter(
          (facet: string) => facet !== value
        ),
        currentFacet:
          currentFacet !== 'jobTitles' ? currentFacet : 'professions',
        page: 1,
      })
    }
  }

  const setOrderOption = (value: OrderOption) => {
    setSearchParams({
      orderBy: value,
      page: 1,
    })
  }

  const setTextSearchField = (
    value: string[] | string,
    fieldName: keyof StringSearchParams
  ) => {
    if (fieldName === 'professions' && Array.isArray(value)) {
      setSearchParams(
        {
          professions: value,
          locations: searchParams.locations,
          page: 1,
          orderBy: searchParams.orderBy,
        },
        'replace'
      )
    } else if (fieldName === 'locations' && Array.isArray(value)) {
      setSearchParams(
        {
          professions: searchParams.professions,
          locations: value,
          page: 1,
          orderBy: searchParams.orderBy,
        },
        'replace'
      )
    } else if (fieldName === 'currentFacet' && typeof value === 'string') {
      setSearchParams({
        currentFacet: value,
      })
    }
  }

  const setSearchParamField = (
    value: string,
    fieldName: keyof StringSearchParams
  ) => {
    setSearchParams({
      [fieldName]: value,
      page: 1,
    })
  }

  const setSearchNumberField = (
    value: number,
    fieldName: keyof NumberSearchParams
  ) => {
    if (fieldName === 'page') {
      setSearchParams({
        [fieldName]: value,
      })
    } else {
      setSearchParams({
        [fieldName]: value,
        page: 1,
      })
    }
  }

  const setBooleanSearchField = (
    value: boolean,
    fieldName: keyof BooleanSearchParams
  ) => {
    setSearchParams({
      [fieldName]: value,
      page: 1,
    })
  }

  return {
    searchParams,
    formattedSearchParams,
    hasActiveQueryParams,
    setSearchParams,
    setSearchParamField,
    setSearchNumberField,
    setOrderOption,
    setTextSearchField,
    setArraySearchParamField,
    setBooleanSearchField,
  }
}
