import { useFeatureFlag } from '@local/Utils'
import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
  Typography,
  colors,
} from '@mui/material'
import { useTrackCustomClick } from '@trr/gtm-tracking'
import React from 'react'

export interface Option {
  key: string
  title: string
  count: number
}

interface FilterOptionProps {
  filterTitle: string
  subtitle?: string
  options: Option[]
  selectedFilters: string[]
  onChangeFilter: (key: string) => void
  buttonProps?: ListItemButtonProps
}

export const FilterOption = ({
  filterTitle,
  subtitle,
  options,
  selectedFilters,
  onChangeFilter,
  buttonProps,
}: FilterOptionProps) => {
  const isChecked = (key: string) =>
    selectedFilters?.some((selectedFilter) => selectedFilter === key)

  const trackFilterUsage = useTrackCustomClick()
  const { isGtmEnabled } = useFeatureFlag()

  const handleFilterClick = (option: Option) => {
    onChangeFilter(option.key)

    if (isGtmEnabled) {
      const isFilterEnabled = !isChecked(option.key)
      trackFilterUsage(
        isFilterEnabled ? 'Filtrering' : 'Filtrering - borttagning av filter',
        { label: filterTitle }
      )
    }
  }

  return (
    <List
      sx={{ paddingBottom: '.5rem' }}
      disablePadding
      data-testid="filter-option-list"
    >
      {subtitle && (
        <Typography
          sx={{
            padding: '0 0 0 .7rem',
            marginBottom: '.5rem',
            color: colors.grey[600],
          }}
          variant="subtitle2"
          gutterBottom
        >
          {subtitle}
        </Typography>
      )}
      {options?.length === 0 && (
        <Typography variant="subtitle2">{'noFiltersText'}</Typography>
      )}
      {options?.map((option: Option) => (
        <ListItem
          key={option.key}
          secondaryAction={
            <Typography
              variant="body1"
              sx={{
                color: option.count > 0 ? colors.grey[600] : colors.grey[400],
                marginLeft: (theme) => theme.spacing(1),
              }}
            >
              {option.count}
            </Typography>
          }
          disablePadding
          divider={false}
        >
          <ListItemButton
            role="button"
            sx={{ paddingTop: 0, paddingBottom: 0 }}
            onClick={() => handleFilterClick(option)}
            dense
            disabled={option.count === 0 && !isChecked(option.key)}
            {...buttonProps}
          >
            <ListItemIcon sx={{ minWidth: 0 }}>
              <Checkbox
                edge="start"
                checked={isChecked(option.key)}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': option.title }}
              />
            </ListItemIcon>
            <ListItemText
              id={option.key}
              primary={option.title.replace(/\//gi, ' / ')}
            />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  )
}
