import React, { PropsWithChildren, useContext } from 'react'

interface FilterSectionContext {
  onLoadMoreOptions: () => void
  shouldLoadMoreOptions: (currentOptionsCount: number) => boolean
}

const FilterSectionContext = React.createContext<FilterSectionContext>({
  onLoadMoreOptions: () => ({}),
  shouldLoadMoreOptions: () => false,
})

interface FilterSectionContextProviderProps extends PropsWithChildren {
  isFetching: boolean
  filterCount: number
  onLoadMoreOptions: () => void
  maxFilterOptions: number
}

export const FilterSectionContextProvider: React.FC<
  FilterSectionContextProviderProps
> = ({
  children,
  isFetching,
  maxFilterOptions,
  onLoadMoreOptions,
  filterCount,
}) => {
  const shouldLoadMoreOptions = (currentOptionsCount: number) =>
    currentOptionsCount === filterCount &&
    !isFetching &&
    filterCount < maxFilterOptions

  return (
    <FilterSectionContext.Provider
      value={{
        onLoadMoreOptions,
        shouldLoadMoreOptions,
      }}
    >
      {children}
    </FilterSectionContext.Provider>
  )
}

export const useFilterSectionContext = () => useContext(FilterSectionContext)
