import React, { forwardRef } from 'react'
import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  Stack,
  StackProps,
} from '@mui/material'
import { SwapVert } from '@mui/icons-material'
import { useFeatureFlag } from '@local/Utils'
import { useTrackCustomClick } from '@trr/gtm-tracking'
import { useDevice } from '@trr/app-shell-data'

import { OrderOption } from '../../../Api/types/api.types'

interface SortOption {
  text: string
  type: OrderOption
}

interface HitsAndSortBarProps extends StackProps {
  sortValue: OrderOption
  onChangeSort: (value: OrderOption) => void
  sortOptions: SortOption[]
  label: string
}

export const HitsAndSortBar = forwardRef<HTMLDivElement, HitsAndSortBarProps>(
  (
    {
      sortValue,
      onChangeSort,
      sortOptions,
      label,
      children,
      ...containerProps
    }: HitsAndSortBarProps,
    ref
  ) => {
    const trackClick = useTrackCustomClick()
    const { isGtmEnabled } = useFeatureFlag()

    const { isMobile } = useDevice()

    const handleSortChange = (event: SelectChangeEvent<OrderOption>) => {
      if (isGtmEnabled) {
        trackClick('Sortera annonser', { label: event.target.value })
      }
      onChangeSort(event.target.value as OrderOption)
    }

    return (
      <Stack direction="column" {...containerProps}>
        <Box
          ref={ref}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Stack direction={'row'} alignItems={'center'} gap={3}>
            <Typography variant="body1" minWidth={(theme) => theme.spacing(12)}>
              {label}
            </Typography>
            {!isMobile && children}
          </Stack>

          <Select
            id="order-by"
            data-testid="order-by"
            variant="standard"
            disableUnderline
            IconComponent={SwapVert}
            onChange={handleSortChange}
            value={sortValue}
            defaultValue={sortValue}
            sx={{ padding: 0 }}
            MenuProps={{ PaperProps: { sx: { maxHeight: 240 } } }}
            renderValue={(selected: string) => (
              <Typography variant="body2" padding={1}>
                {sortOptions.find((item) => item.type === selected)?.text}
              </Typography>
            )}
          >
            {sortOptions.map((item: SortOption) => (
              <MenuItem key={item.type} value={item.type}>
                {sortOptions.find((option) => option.type === item.type)?.text}
              </MenuItem>
            ))}
          </Select>
        </Box>
        {isMobile && <Box sx={{ mb: 1 }}>{children}</Box>}
      </Stack>
    )
  }
)
