import React, { useLayoutEffect } from 'react'
import { Pagination, PaginationProps } from '@mui/material'

interface SearchPaginationProps extends PaginationProps {
  currentPage: number
  totalPages: number
  onChangePage: (page: number) => void
}

export const SearchPagination: React.FunctionComponent<
  SearchPaginationProps
> = ({ currentPage, totalPages, onChangePage, ...paginationProps }) => {
  const page = currentPage <= totalPages && currentPage > 0 ? currentPage : 1

  const handlePageChange = (event: React.ChangeEvent, page: number) => {
    onChangePage(page)
  }

  useLayoutEffect(() => {
    if (page !== currentPage) {
      onChangePage(page)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  return (
    <Pagination
      page={page}
      count={totalPages}
      onChange={handlePageChange}
      data-testid="pagination"
      translate="no"
      {...paginationProps}
    />
  )
}
