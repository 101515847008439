import { baseApi } from './baseApi'
import {
  ApplicationInformation,
  Contact,
  DeepLocation,
  Position,
  SaveStatus,
  SavedTo,
} from './types/api.types'

export interface ToggleJobAdStatusRequest {
  id: string
  method: 'POST' | 'DELETE'
}

export interface SaveStatusResponse {
  saveStatus: SaveStatus[]
  isSaved: boolean
  hasApplied: boolean
}

export interface GetJobAdResponse {
  id: string
  application: ApplicationInformation
  contacts: Contact[]
  position: Position
  location: DeepLocation
}

const jobAdApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getJobAd: builder.query<GetJobAdResponse, string>({
      query: (id) => ({
        url: `/jobs/ads/${id}`,
        method: 'GET',
      }),
      providesTags: ['JobAd'],
    }),
    getJobAdStatuses: builder.query<SaveStatusResponse, string>({
      query: (id) => ({
        url: `/jobs/ads/${id}/savestatus`,
        method: 'GET',
      }),
      providesTags: ['JobAd'],
      transformResponse: (
        response: Pick<SaveStatusResponse, 'saveStatus'>
      ) => ({
        ...response,
        isSaved: response.saveStatus.some(
          (status) => status?.savedTo === SavedTo.Saved
        ),
        hasApplied: response.saveStatus.some(
          (status) => status?.savedTo === SavedTo.Applied
        ),
      }),
    }),
    getJobAdIndexData: builder.query<string, string>({
      query: (id) => ({
        url: `/jobs/${id}/raw`,
        method: 'GET',
      }),
      providesTags: ['JobAd'],
      transformResponse: (response: object) => {
        const objCopy = { ...response }
        delete objCopy['position']['description']
        return JSON.stringify(objCopy, null, 2)
      },
    }),
    saveJobAd: builder.mutation<void, ToggleJobAdStatusRequest>({
      query: ({ id, method = 'POST' }) => ({
        url: `/jobs/${id}/save`,
        method: method,
      }),
      invalidatesTags: ['JobAd', 'MatchedJobs', 'JobAdsListing'],
    }),
    applyJobAd: builder.mutation<void, ToggleJobAdStatusRequest>({
      query: ({ id, method = 'POST' }) => ({
        url: `/jobs/${id}/applied`,
        method: method,
      }),
      invalidatesTags: ['JobAd', 'SavedJobs', 'AppliedJobs', 'JobAdsListing'],
    }),
  }),
})

export const {
  useGetJobAdQuery,
  useSaveJobAdMutation,
  useApplyJobAdMutation,
  useGetJobAdIndexDataQuery,
  useGetJobAdStatusesQuery,
} = jobAdApi
