import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { useDevice } from '@trr/app-shell-data'

import usePickEpiContent from '../../../../../Utils/Content/usePickEpiContent'
import SelectableChip from '../../../../../Components/SelectableChip/SelectableChip'
import { useQuickApplicationForm } from '../hooks/useQuickApplicationForm'

interface SkillsFieldProps {
  options?: string[]
}

export const SkillsField: React.FC<SkillsFieldProps> = ({ options = [] }) => {
  const content = usePickEpiContent().selectedViewNy.quickApplication

  const { values, setValues } = useQuickApplicationForm()

  const { isMobile } = useDevice()

  const addSkill = (skill: string) => {
    setValues((prev) => ({
      ...prev,
      skills: [...prev.skills, skill],
    }))
  }

  const removeSkill = (skill: string) => {
    setValues((prev) => ({
      ...prev,
      skills: prev.skills.filter((s) => s !== skill),
    }))
  }

  const handleSkillClick = (skill: string) => () => {
    if (values?.skills.includes(skill)) {
      removeSkill(skill)
    } else {
      addSkill(skill)
    }
  }

  return (
    <Stack spacing={3}>
      <Box>
        <Typography variant="h6" gutterBottom>
          {content.skillsHeading}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {isMobile
            ? content.skillsDescriptionShort
            : content.skillsDescriptionLong}
        </Typography>
      </Box>
      <Stack gap={1} direction="row" flexWrap="wrap">
        {options.map((option) => (
          <SelectableChip
            key={option}
            label={option}
            onClick={handleSkillClick(option)}
            selected={values?.skills.includes(option)}
          />
        ))}
      </Stack>
    </Stack>
  )
}
