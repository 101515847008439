import React from 'react'
import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import { Assignment, BoltOutlined } from '@mui/icons-material'

import { ApplicationType } from '../../../../../Api/types/api.types'
import usePickEpiContent from '../../../../../Utils/Content/usePickEpiContent'

interface CallToActionHeaderProps {
  applicationType: ApplicationType
}

export const CallToActionHeader: React.FC<CallToActionHeaderProps> = ({
  applicationType,
}) => {
  const { title, quickApplicationTitle } =
    usePickEpiContent().selectedViewNy.callToAction

  const isTrrRecruitment = applicationType === 'TrrRecruitment'

  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'surface.purple' }}>
          {isTrrRecruitment ? (
            <BoltOutlined color="primary" />
          ) : (
            <Assignment color="primary" />
          )}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={isTrrRecruitment ? quickApplicationTitle : title}
        primaryTypographyProps={{
          variant: 'h6',
          sx: { overflow: 'hidden', textOverflow: 'ellipsis' },
        }}
      />
    </ListItem>
  )
}
