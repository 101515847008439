import React from 'react'
import { OrderOption } from '@local/Api/types/api.types'

import usePickEpiContent, {
  replaceEpiVariables,
} from '../../Utils/Content/usePickEpiContent'
import EmptyResult from '../../Components/EmptyResult/EmptyResult'
import { JobAdsPage } from '../../Components/JobAdsPage/JobAdsPage'
import { useSearchQueryParams } from '../../Utils/Hooks/useSearchQueryParams'

import { AppliedJobListItem } from './Components/AppliedJobListItem'
import { useGetAppliedJobsQuery } from './Api/appliedJobsApi'

const formattedsortBar = [
  {
    text: 'Senast ansökta',
    type: OrderOption.AppliedDate,
  },
  {
    text: 'Sista ansökningsdatum',
    type: OrderOption.Deadline,
  },
  {
    text: 'Publiceringsdatum',
    type: OrderOption.PublicationDate,
  },
]

export const AppliedJobsPage: React.FC = () => {
  const { sortBar, emptyResult } = usePickEpiContent().ansokta

  const { formattedSearchParams } = useSearchQueryParams()

  const page = formattedSearchParams.page || 1
  const orderBy =
    (formattedSearchParams.orderBy as OrderOption) || OrderOption.SavedDate

  const { data, isLoading } = useGetAppliedJobsQuery({
    orderBy,
    page,
    pageSize: 14,
  })

  const renderHitsText = (hits = 0) =>
    replaceEpiVariables(hits === 1 ? sortBar.hitsSingular : sortBar.hits, {
      numberOfHits: hits.toString(),
    })

  return (
    <JobAdsPage
      jobAds={data?.jobAds}
      orderOptions={formattedsortBar}
      order={OrderOption.AppliedDate}
      totalHits={data?.totalHits}
      totalPages={data?.totalPages}
      page={page}
      renderItem={(jobAd) => <AppliedJobListItem job={jobAd} />}
      renderHitsText={renderHitsText}
      EmptyResult={
        <EmptyResult
          heading={emptyResult.title}
          description={emptyResult.body}
        />
      }
      isLoading={isLoading}
    />
  )
}
