import React from 'react'
import { OpenInNewRounded } from '@mui/icons-material'

import usePickEpiContent from '../../../../../Utils/Content/usePickEpiContent'

import { ApplyButton } from './ApplyButton/ApplyButton'

interface ExternalApplyButtonProps {
  url: string
}

export const ExternalApplyButton: React.FC<ExternalApplyButtonProps> = ({
  url,
}) => {
  const { applyButtonText } = usePickEpiContent().selectedViewNy.callToAction

  return (
    <ApplyButton
      Icon={OpenInNewRounded}
      label={applyButtonText}
      to={url}
      data-testid="cta-apply-button"
    />
  )
}
