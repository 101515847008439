import React from 'react'
import { BoxProps, Box, Typography, Stack, styled } from '@mui/material'

import EmptyIllustration from './EmptyIllustration'

interface EmptyResultProps extends BoxProps {
  heading: string
  description: string
}

const IllustrationWrapper = styled(Box)(({ theme }) => ({
  maxWidth: '386px',
  padding: theme.spacing(0, 5),
}))

const EmptyResult: React.FC<EmptyResultProps> = ({
  heading,
  description,
  ...boxProps
}) => (
  <Box {...boxProps}>
    <Stack
      spacing={3}
      justifyContent="center"
      alignItems="center"
      textAlign="center"
    >
      <IllustrationWrapper>
        <EmptyIllustration />
      </IllustrationWrapper>
      <Typography variant="h3" gutterBottom>
        {heading}
      </Typography>
      <Typography variant="body1">{description}</Typography>
    </Stack>
  </Box>
)

export default EmptyResult
