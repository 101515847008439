import { useEpiContent } from '@trr/app-shell-data'
import React from 'react'

import { Content } from '../../App.types'

const usePickEpiContent = useEpiContent<Content>

export default usePickEpiContent

export const replaceEpiVariables = (
  text: string,
  variables: { [key: string]: string } = {}
) => {
  let result = text
  Object.keys(variables).forEach((key) => {
    const regex = new RegExp(`{{${key}}}`, 'g')
    result = variables[key]
      ? result?.replace(regex, variables[key])
      : result?.replace(regex, '')
  })
  return result
}

export const replaceEpiVariablesWithJSX = (
  text: string,
  variables: { [key: string]: JSX.Element } = {}
): JSX.Element => {
  const splitedText = text.split(/{{|}}/)

  return (
    <>
      {splitedText.map((part, index) => {
        if (index % 2 === 0) {
          return part
        }
        return variables[part] ?? part
      })}
    </>
  )
}
