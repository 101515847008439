import React from 'react'
import {
  Alert,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from '@mui/material'
import { BoltOutlined } from '@mui/icons-material'

import { QuickApplicationFormFields } from '../../../../../../Api/types/api.types'
import SelectableChip from '../../../../../../Components/SelectableChip/SelectableChip'
import usePickEpiContent from '../../../../../../Utils/Content/usePickEpiContent'

interface ConfirmApplicationDialogProps {
  formValues: QuickApplicationFormFields
  requiredSkills?: string[]
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

export const ConfirmApplicationDialog: React.FC<
  ConfirmApplicationDialogProps
> = ({ formValues, open, onClose, onConfirm, requiredSkills = [] }) => {
  const content = usePickEpiContent().selectedViewNy.quickApplicationPreview

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Avatar sx={{ bgcolor: 'surface.purple' }}>
          <BoltOutlined color="primary" />
        </Avatar>
        {content.heading}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <Box>
            <Typography variant="subtitle2" gutterBottom>
              {content.previewHeading}
            </Typography>
            <Divider />
          </Box>

          <Box>
            <Typography variant="subtitle1" gutterBottom>
              {content.skillsHeading}
            </Typography>
            <Stack direction="row" gap={1} flexWrap="wrap">
              {requiredSkills.map((skill) => (
                <SelectableChip
                  key={skill}
                  label={skill}
                  selected={formValues?.skills.includes(skill)}
                />
              ))}
            </Stack>
          </Box>
          {requiredSkills.length > 0 && formValues?.skills.length === 0 && (
            <Alert
              severity="info"
              sx={{ alignItems: { xs: 'flex-start', sm: 'center' } }}
            >
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={1}
                alignItems={{ xs: 'flex-start', sm: 'center' }}
              >
                <Box>{content.noSkillsText}</Box>
                <Button
                  variant="outlined"
                  onClick={onClose}
                  size="small"
                  sx={{ flexShrink: 0, whiteSpace: 'nowrap' }}
                >
                  {content.noSkillsButtonText}
                </Button>
              </Stack>
            </Alert>
          )}
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              {content.motivationHeading}
            </Typography>
            <DialogContentText
              sx={{ wordBreak: 'break-word', whiteSpace: 'pre-line' }}
            >
              {formValues?.motivation}
            </DialogContentText>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          {content.closeButtonText}
        </Button>
        <Button onClick={onConfirm} variant="text">
          {content.submitButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
