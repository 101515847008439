export const replaceHtmlEntities = (str: string) => {
  const entities = [
    ['&amp;', '&'],
    ['&quot;', '"'],
    ['&apos;', "'"],
    ['&lt;', '<'],
    ['&gt;', '>'],
  ]

  let newStr = str
  entities.forEach(([entity, replacement]) => {
    newStr = newStr.replaceAll(entity, replacement)
  })

  return newStr
}
