import React from 'react'
import {
  Box,
  Button,
  ButtonProps,
  Fab,
  FabProps,
  Slide,
  SvgIconTypeMap,
} from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

import { useIsInView } from '../../../../../../Utils/Hooks/useIsInView'
import { useScroll } from '../../../../../../Utils/Hooks/useScroll'
import useDebounce from '../../../../../../Utils/Hooks/useDebounce'

type FabAndButtonProps = ButtonProps & FabProps

interface ApplyButtonProps extends FabAndButtonProps {
  Icon: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>
  label: string
  to?: string
  onClick?: () => void
}

export const ApplyButton: React.FC<ApplyButtonProps> = ({
  Icon,
  label,
  to,
  onClick,
  ...buttonProps
}) => {
  const buttonRef = React.useRef(null)
  const buttonView = useIsInView(buttonRef)

  const scroll = useScroll()

  const AdvertContainer = document.getElementById('advert-container-bottom')
  const isAboveAdvertContainerBottom =
    AdvertContainer?.offsetTop - window.innerHeight > scroll?.y

  const showFab =
    buttonView && !buttonView.isIntersecting && isAboveAdvertContainerBottom

  // debounce to prevent flickering when button mounts and unmounts
  const debouncedShowFab = useDebounce(showFab, 200)

  const commonProps = {
    href: to,
    target: to ? '_blank' : undefined,
    onClick,
    ...buttonProps,
  }

  return (
    <>
      <Box ref={buttonRef}>
        <Button
          variant="outlined"
          endIcon={<Icon />}
          {...commonProps}
          sx={{ maxWidth: '-webkit-fill-available', ...commonProps.sx }}
        >
          {label}
        </Button>
      </Box>
      <Box
        position="fixed"
        bottom={(t) => t.spacing(2)}
        left={(t) => t.spacing(2)}
        zIndex={(t) => t.zIndex.fab}
      >
        <Slide in={debouncedShowFab} direction="up">
          <Fab
            variant="extended"
            size="large"
            color="primary"
            sx={{ textTransform: 'none', px: 3, py: 2 }}
            {...commonProps}
          >
            {label}
            <Icon sx={{ ml: 1 }} fontSize="small" />
          </Fab>
        </Slide>
      </Box>
    </>
  )
}
