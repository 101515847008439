import { DeepLocation } from '../types/api.types'

export const convertToEnglishLocationTypesCapitalStart = (type: string) => {
  switch (type.toLocaleLowerCase()) {
    case 'län':
      return 'County'
    case 'tätort':
      return 'City'
    case 'kommun':
      return 'Municipality'
    case 'land':
      return 'Country'
    default:
      return type
  }
}

export const convertToSwedishLocationTypes = (type: string) => {
  switch (type.toLocaleLowerCase()) {
    case 'county':
      return 'län'
    case 'city':
      return 'tätort'
    case 'municipality':
      return 'kommun'
    case 'country':
      return 'land'
    default:
      return type
  }
}

export const convertToSwedishTypesRecursive = (
  deepLocation: DeepLocation
): DeepLocation => ({
  ...deepLocation,
  type: convertToSwedishLocationTypes(deepLocation.type),
  locatedIn: deepLocation.locatedIn
    ? convertToSwedishTypesRecursive(deepLocation.locatedIn)
    : undefined,
})
