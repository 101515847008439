import React from 'react'
import { useFeatureFlag } from '@local/Utils'
import usePickEpiContent, {
  replaceEpiVariables,
} from '@local/Utils/Content/usePickEpiContent'
import PuffCard from '@local/Components/PuffCard'
import { useGetProfileSuggestionsCountQuery } from '@local/Api/matchingProfileApi'
import { useAuthentication } from '@trr/app-shell-data'

export const NoMatchedJobsPuff: React.FC = () => {
  const {
    profileLink,
    noResultTitle,
    noResultBody,
    noResultBodyWithSuggestions,
    noResultLinkText,
    noResultLinkTextWithSuggestions,
  } = usePickEpiContent().matchadeNy.profileBox
  const { sub } = useAuthentication()
  const {
    data: suggestionsCount,
    isLoading,
    isError,
  } = useGetProfileSuggestionsCountQuery(sub)
  const { isSuggestionsBlockEnabled } = useFeatureFlag()

  const showSuggestions = isSuggestionsBlockEnabled && suggestionsCount > 0

  if (isLoading) {
    return null
  }

  return (
    <PuffCard
      heading={noResultTitle}
      description={
        showSuggestions && !isError
          ? replaceEpiVariables(noResultBodyWithSuggestions, {
              count: suggestionsCount.toString(),
              newWord: suggestionsCount === 1 ? 'nytt' : 'nya',
            })
          : noResultBody
      }
      href={profileLink}
      actionText={
        showSuggestions ? noResultLinkTextWithSuggestions : noResultLinkText
      }
    />
  )
}
