import React from 'react'
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  SxProps,
  Typography,
} from '@mui/material'

interface JobCardProps {
  title: string
  preTitle?: string
  preTitleVariant?: 'highlight' | 'info'
  subtitle?: string
  description?: string
  footer: React.ReactNode
  actionIcon?: React.ReactNode
  onActionClick?: () => void
  href: string
}

const LineClampSx: SxProps = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}

export const JobCard: React.FC<JobCardProps> = ({
  title,
  preTitle,
  preTitleVariant = 'highlight',
  subtitle,
  description,
  footer,
  actionIcon,
  href,
}) => (
  <Card
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      width: '100%',
    }}
    data-testid="job-ad-list-item"
  >
    <CardActionArea
      href={href}
      sx={{
        minHeight: 250,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'normal',
      }}
    >
      <CardHeader
        title={
          <Box>
            {preTitle && (
              <Typography
                variant="subtitle2"
                color={
                  preTitleVariant === 'highlight'
                    ? 'info.main'
                    : 'text.secondary'
                }
                component={'p'}
                gutterBottom
              >
                {preTitle}
              </Typography>
            )}
            <Typography variant="h6" component={'h2'} sx={LineClampSx}>
              {title}
            </Typography>
          </Box>
        }
        titleTypographyProps={{ variant: 'h6' }}
        subheader={subtitle}
        action={actionIcon}
      />
      <CardContent>
        {description && (
          <Typography variant="body2" sx={LineClampSx}>
            {description}
          </Typography>
        )}
      </CardContent>
      <CardContent sx={{ marginTop: 'auto' }}>{footer}</CardContent>
    </CardActionArea>
  </Card>
)
