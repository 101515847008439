import React from 'react'
import { Link, Text, View } from '@react-pdf/renderer'

import { PdfSection } from '../../Components/PdfSection'
import { PhoneIphoneIcon } from '../../Icons/PhoneIphoneIcon'
import { pdfStyles as s } from '../../styles'
import { MailOutlineIcon } from '../../Icons/MailOutlineIcon'

interface ContactSectionProps {
  heading: string
  mail: string
  phone: string
}

export const ContactSection = ({
  heading,
  mail,
  phone,
}: ContactSectionProps) => {
  return (
    <PdfSection title={heading} font="h2" style={s.gutter5}>
      <View id="personal">
        {phone && (
          <View
            style={{
              flexDirection: 'row',
              gap: 8,
            }}
          >
            <PhoneIphoneIcon />
            <Text style={s.body1}>
              <Link src={`tel:${phone}`} style={s.link}>
                {phone}
              </Link>
            </Text>
          </View>
        )}
        {mail && (
          <View
            style={{
              flexDirection: 'row',
              gap: 8,
            }}
          >
            <MailOutlineIcon />
            <Text style={s.body1}>
              <Link src={`mailto:${mail}`} style={s.link}>
                {mail}
              </Link>
            </Text>
          </View>
        )}
      </View>
    </PdfSection>
  )
}
