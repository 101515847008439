import React, { useRef } from 'react'
import {
  FacetOptions,
  JobAdList,
  OrderOption,
} from '@local/Api/types/api.types'
import EmptyResult from '@local/Components/EmptyResult/EmptyResult'
import { FilterSection } from '@local/Features/FilterSection/FilterSection'
import usePickEpiContent, {
  replaceEpiVariables,
} from '@local/Utils/Content/usePickEpiContent'
import { useSearchQueryParams } from '@local/Utils/Hooks/useSearchQueryParams'
import { Box } from '@mui/material'

import { IncludeJobAdTextResultsToggle } from '../IncludeJobAdTextResultsToggle/IncludeJobAdTextResultsToggle'
import { SearchJobListItem } from '../Components/SearchJobListItem'
import { JobAdsPage } from '../../../Components/JobAdsPage/JobAdsPage'

interface FilterAndJobSearchListProps {
  isLoading: boolean
  isFetching: boolean
  onLoadMoreFilterOptions: () => void
  filterCount: number
  facets: FacetOptions
  jobs: JobAdList
}

export const FilterAndJobSearchList: React.FC<FilterAndJobSearchListProps> = ({
  isLoading,
  isFetching,
  onLoadMoreFilterOptions,
  filterCount,
  facets,
  jobs,
}) => {
  const filterRef = useRef(null)
  const { formattedSearchParams } = useSearchQueryParams()
  const { sortBar, emptyResult } = usePickEpiContent().sokNy

  const formattedsortBar = [
    {
      text: sortBar['relevance desc'],
      type: OrderOption.Relevance,
    },
    {
      text: sortBar['applicationdeadline asc'],
      type: OrderOption.ApplicationDeadline,
    },
    {
      text: sortBar['publicationdate desc'],
      type: OrderOption.PublicationDate,
    },
  ]

  const hitsText = (hits = 0) =>
    replaceEpiVariables(hits === 1 ? sortBar.hitsSingular : sortBar.hits, {
      numberOfHits: hits.toString(),
    })

  const order =
    (formattedSearchParams.orderBy as OrderOption) || OrderOption.Relevance

  return (
    <Box>
      {!isLoading && (
        <FilterSection
          options={facets}
          ref={filterRef}
          onLoadMoreOptions={onLoadMoreFilterOptions}
          filterCount={filterCount}
          isFetching={isFetching}
        />
      )}
      <JobAdsPage
        isLoading={isLoading}
        jobAds={jobs?.jobAds}
        totalHits={jobs?.totalHits}
        totalPages={jobs?.totalPages}
        orderOptions={formattedsortBar}
        order={order}
        page={formattedSearchParams.page ?? 1}
        renderItem={(jobAd) => (
          <SearchJobListItem job={jobAd} showNewIndicator />
        )}
        renderHitsText={hitsText}
        EmptyResult={
          <EmptyResult
            heading={emptyResult.title}
            description={emptyResult.body}
          />
        }
        ExtraHeader={
          <IncludeJobAdTextResultsToggle
            totalHits={jobs?.totalHits}
            totalHitsWithDescription={jobs?.totalHitsWithDescription}
            sx={{ alignSelf: 'flex-start' }}
          />
        }
      />
    </Box>
  )
}
