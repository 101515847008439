import React, { useEffect } from 'react'
import { useTrackLoaded } from '@trr/gtm-tracking'

import { useGetJobAdQuery } from '../../Api/JobAdApi'
import { useSelectedJobId } from '../../Utils/Hooks/useSelectedJobId'
import { LoadingSpinner } from '../../Components/LoadingSpinner'
import EmptyResult from '../../Components/EmptyResult/EmptyResult'
import usePickEpiContent from '../../Utils/Content/usePickEpiContent'
import { replaceHtmlEntities } from '../../Utils/Helpers/replaceHtmlEntities'

import Advert from './Advert/Advert'

export const SelectedJob: React.FunctionComponent = () => {
  const selectedJobId = useSelectedJobId()
  const { data: job, isLoading, isError } = useGetJobAdQuery(selectedJobId)
  const { title, body } = usePickEpiContent().selectedViewNy.emptyResult

  const trackPageLoad = useTrackLoaded()

  useEffect(() => {
    if (!isLoading) {
      trackPageLoad(
        job?.application?.type === 'TrrRecruitment'
          ? 'TRR annons laddad'
          : 'Publik annons laddad'
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  useEffect(() => {
    const jobTitle = job?.position?.employer?.name
      ? job?.position?.header + ' - ' + job?.position?.employer?.name
      : job?.position?.header
    if (!isLoading && jobTitle) {
      document.title = replaceHtmlEntities(jobTitle)
    }
  }, [isLoading, job?.position?.header, job?.position?.employer?.name])

  if (isLoading) {
    return <LoadingSpinner />
  }
  if (isError || !job) {
    return <EmptyResult heading={title} description={body} />
  }

  return <Advert job={job} />
}
