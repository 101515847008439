import React from 'react'
import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import {
  AccessTimeFilled,
  Business,
  PeopleAltOutlined,
  Place,
  Work,
  WorkHistory,
} from '@mui/icons-material'

import { WorkingHours } from '../../../../Api/types/api.types'
import usePickEpiContent from '../../../../Utils/Content/usePickEpiContent'

interface AdvertGeneralInfoProps {
  employerName?: string
  locations?: string[]
  jobTitles?: string[]
  workingHours?: WorkingHours[]
  employmentType?: string
  availablePositions?: number
}

export const AdvertGeneralInfo: React.FC<AdvertGeneralInfoProps> = ({
  employerName,
  locations,
  jobTitles,
  workingHours,
  employmentType,
  availablePositions,
}) => {
  const {
    locationLabel,
    employerLabel,
    workingHoursLabel,
    jobTitlesLabel,
    employmentTypeLabel,
    availablePositionsLabel,
    unknownEmployer,
  } = usePickEpiContent().selectedViewNy.generalInfo

  const tableData: { icon: JSX.Element; label: string; value: string }[] = [
    {
      icon: <Business />,
      label: employerLabel,
      value: employerName || unknownEmployer,
    },
    { icon: <Place />, label: locationLabel, value: locations?.join(', ') },
    {
      icon: <Work />,
      label: jobTitlesLabel,
      value: jobTitles?.join(', '),
    },
    {
      icon: <AccessTimeFilled />,
      label: workingHoursLabel,
      value: workingHours?.map((x) => x).join(', '),
    },
    {
      icon: <WorkHistory />,
      label: employmentTypeLabel,
      value: employmentType,
    },
    {
      icon: <PeopleAltOutlined />,
      label: availablePositionsLabel,
      value: availablePositions ? availablePositions.toString() : undefined,
    },
  ].filter(({ value }) => !!value)

  return (
    <Table>
      <TableBody>
        {tableData.map(({ icon, label, value }) => (
          <TableRow key={label}>
            <TableCell sx={{ color: 'text.secondary' }} padding="checkbox">
              {icon}
            </TableCell>
            <TableCell sx={{ color: 'text.secondary', maxWidth: '160px' }}>
              {label}
            </TableCell>
            <TableCell aria-label={label}>{value}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
