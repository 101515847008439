import React from 'react'
import { Text } from '@react-pdf/renderer'

import { Experience } from '../../../GeneratedCv.types'
import { PdfSection } from '../../Components/PdfSection'
import { PdfStack } from '../../Components/PdfStack'
import { pdfStyles as s } from '../../styles'

import { formatDateRange } from './helpers/formatDateRange'
import { formatTitle } from './helpers/formatTitle'

interface ExperienceSectionProps {
  heading: string
  experiences: Experience[]
}

export const ExperienceSection = ({
  experiences,
  heading,
}: ExperienceSectionProps) => {
  return (
    <PdfSection title={heading} font="h2" underline>
      <PdfStack spacing={8}>
        {experiences?.map((experience, index) => (
          <PdfSection
            key={experience.organization}
            title={formatTitle(experience, 'Ny erfarenhet')}
            subtitle={formatDateRange(experience)}
            underline={index !== experiences.length - 1}
            font="body1"
            wrap={false}
          >
            <Text style={s.body1}>{experience.description}</Text>
          </PdfSection>
        ))}
      </PdfStack>
    </PdfSection>
  )
}
