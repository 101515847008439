import React from 'react'

import { MatchingProfileWrapper } from './Components/MatchingProfileWrapper'
import { MatchedJobs } from './Components/MatchedJobs'

export const MatchedJobsPage: React.FC = () => (
  <MatchingProfileWrapper>
    <MatchedJobs />
  </MatchingProfileWrapper>
)
