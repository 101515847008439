import { HistoricSearchesResponse } from '../types/api.types'

import { convertToSwedishLocationTypes } from './autocompleteHelpers'

export const excludeLocationsWithNonMappableLocationType = (
  searchHistory: HistoricSearchesResponse[] = []
): HistoricSearchesResponse[] =>
  searchHistory.map((sh) => ({
    ...sh,
    locationInformations: sh.locationInformations.reduce((acc, curr) => {
      const convertedLocationType = convertToSwedishLocationTypes(curr.type)
      if (convertedLocationType === curr.type) return acc
      return [...acc, { ...curr, type: convertedLocationType }]
    }, []),
  }))
