import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteCloseReason,
  ListItem,
  ListItemText,
  SxProps,
} from '@mui/material'
import parse from 'html-react-parser'
import React, { useState } from 'react'
import { useFeatureFlag } from '@local/Utils'
import { useTrackCustomClick } from '@trr/gtm-tracking'
import { Search } from '@mui/icons-material'
import { capitalize } from 'lodash'

import { useGetJobTitlesQuery } from '../../../Api/autocompleteApi'
import { useGetHistoricSearchesQuery } from '../../../Api/historicSearchesApi'
import {
  HistoricSearchesResponse,
  JobTitle,
} from '../../../Api/types/api.types'
import useDebounce from '../../../Utils/Hooks/useDebounce'
import usePickEpiContent from '../../../Utils/Content/usePickEpiContent'
import { useSearchQueryParams } from '../../../Utils/Hooks/useSearchQueryParams'
import {
  AutocompleteOption,
  SearchAutocomplete,
} from '../Components/SearchAutocomplete'
import {
  convertJobTitlesToAutocompleteOptions,
  getFilteredJobTitleAutocompleteOptions,
} from '../Utils/autocompleteHelpers'

import HistoricSearchOption from './HistoricSearchOption'

interface ProfessionsAutocompleteProps {
  jobtitles: JobTitle[]
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    newJobtitles: string[]
  ) => void
  sx?: SxProps
}

export const ProfessionsAutocomplete = ({
  jobtitles = [],
  onChange,
  sx,
}: ProfessionsAutocompleteProps) => {
  const { placeholder, noResult } = usePickEpiContent().sokNy.yrkenSearch
  const autocompleteJobtitles: AutocompleteOption<JobTitle>[] =
    convertJobTitlesToAutocompleteOptions(jobtitles)
  const { isGtmEnabled } = useFeatureFlag()
  const trackClick = useTrackCustomClick()
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 300)
  const skipSearch = debouncedSearchTerm.length < 2

  const { data, isFetching } = useGetJobTitlesQuery(debouncedSearchTerm, {
    skip: skipSearch,
  })

  const { data: historicSearches } = useGetHistoricSearchesQuery()
  const { setSearchParams } = useSearchQueryParams()

  const handleInputChange = (_, value: string) => {
    setSearchTerm(value)
  }

  const handleOnBlur = () => {
    if (searchTerm?.length) {
      onChange(null, [...jobtitles.map((x) => x.name), capitalize(searchTerm)])
    }
    setSearchTerm('')
  }

  const handleOnChange = (
    event: React.SyntheticEvent<Element, Event>,
    values: AutocompleteOption<JobTitle>[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<
      AutocompleteOption<JobTitle> | HistoricSearchesResponse
    >
  ) => {
    let option = details?.option
    if (option) {
      if (typeof option === 'string') {
        option = {
          label: capitalize(option),
          value: { id: null, name: capitalize(option) },
        }
      } else if ('searchTimeStamp' in option) {
        setSearchParams({
          professions: option.professions,
          locations: option.locationInformations.map(
            (loc) => `${loc.name},${loc.type}`
          ),
          historicSearchTimeStamp: option.searchTimeStamp,
        })
        return
      }

      if (reason === 'createOption' || reason === 'selectOption') {
        const formattedValues: AutocompleteOption<JobTitle>[] = values.filter(
          (x) => x.value.name?.trim()
        )
        onChange(
          event,
          formattedValues.map((x) => x.value.name)
        )
        return
      }
    }
    onChange(
      event,
      values.map((x) => x.value.name).filter((x) => x.trim())
    )
  }

  const showAutocompleteOptions = !skipSearch
  const showHistoricalSearches =
    debouncedSearchTerm.length === 0 &&
    !jobtitles.length &&
    historicSearches?.length > 0

  const autocompleteOptions = getFilteredJobTitleAutocompleteOptions(
    data,
    jobtitles,
    debouncedSearchTerm
  )

  const historicSearchesOptions = historicSearches
    ?.filter((hs) => hs.professions.length > 0)
    .slice(0, 7)

  const options: (HistoricSearchesResponse | AutocompleteOption<JobTitle>)[] = [
    ...(showAutocompleteOptions ? autocompleteOptions : []),
    ...(showHistoricalSearches ? historicSearchesOptions : []),
  ]

  const handleOnClose = (_e, reason: AutocompleteCloseReason) => {
    const identifier = 'Arbetsplatsfältet'
    if (isGtmEnabled) {
      if (reason === 'selectOption') {
        if (showHistoricalSearches) {
          trackClick(identifier, {
            label: 'Användaren valde en senaste sökning',
          })
        }
        if (showAutocompleteOptions) {
          trackClick(identifier, {
            label:
              'Användaren gjorde ett val ur den automatiska kompletteringen',
          })
        }
      }
      if (reason === 'createOption') {
        trackClick(identifier, { label: 'Användaren gjorde en fritextsökning' })
      }
    }
  }

  const noOptionsText =
    showAutocompleteOptions && !isFetching && !data?.length
      ? noResult
      : undefined

  const renderOptions = (
    props,
    option: AutocompleteOption<JobTitle> | HistoricSearchesResponse
  ) =>
    'value' in option ? (
      <ListItem {...props} dense divider={false}>
        <ListItemText primary={parse(option.label)} />
      </ListItem>
    ) : (
      <HistoricSearchOption {...props} option={option} />
    )

  const getOptionLabel = (
    opt: AutocompleteOption<JobTitle> | HistoricSearchesResponse
  ) => {
    if ('searchTimeStamp' in opt) {
      return opt.id
    }
    return opt.label
  }

  return (
    <SearchAutocomplete
      sx={sx}
      options={options ?? []}
      placeholder={placeholder}
      value={autocompleteJobtitles}
      inputValue={searchTerm}
      autoHighlight={!showHistoricalSearches}
      onBlur={handleOnBlur}
      onChange={handleOnChange}
      onInputChange={handleInputChange}
      getOptionLabel={getOptionLabel}
      onClose={handleOnClose}
      limitHeight={showHistoricalSearches}
      icon={<Search />}
      noOptionsText={noOptionsText}
      renderOption={renderOptions}
      data-testid="profession-autocomplete"
      groupBy={showHistoricalSearches ? () => 'Senaste sökningar' : undefined}
    />
  )
}
