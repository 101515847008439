import { useEffect } from 'react'

type VisibilityChangeEvent = Event & { target: Document }

export const useVisibilityChange = (
  callbackFn: (ev: VisibilityChangeEvent) => void
) => {
  useEffect(() => {
    document.addEventListener('visibilitychange', callbackFn)

    return () => {
      document.removeEventListener('visibilitychange', callbackFn)
    }
  }, [callbackFn])
}
