import { baseApi } from './baseApi'
import {
  QuickApplicationFormFields,
  QuickApplicationStatus,
} from './types/api.types'

export interface QuickApplictionResponse extends QuickApplicationFormFields {
  id: string
  status: QuickApplicationStatus
  createdWhen: string
  lastModifiedWhen: string
}

export interface QuickApplicationRequest extends QuickApplicationFormFields {
  jobId: string
}

const quickApplicationApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    quickApply: builder.mutation<void, QuickApplicationRequest>({
      query: ({ jobId, ...body }) => ({
        url: `/recruitment/recruitments/${jobId}/application`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_res, _err, { jobId }) => [
        {
          type: 'QuickApplication',
          id: jobId,
        },
        'JobAd',
      ],
    }),
    getQuickApplication: builder.query<QuickApplictionResponse, string>({
      query: (jobId) => ({
        url: `/recruitment/recruitments/${jobId}/application`,
        method: 'GET',
      }),
      providesTags: (_res, _err, jobId) => [
        { type: 'QuickApplication', id: jobId },
      ],
    }),
    deleteQuickApplication: builder.mutation<
      void,
      { applicationId: string; jobId: string }
    >({
      query: ({ applicationId }) => ({
        /// url?
        url: `/recruitment/Applications/${applicationId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_res, _err, { jobId }) => [
        {
          type: 'QuickApplication',
          id: jobId,
        },
        'JobAd',
      ],
    }),
  }),
})

export const {
  useQuickApplyMutation,
  useGetQuickApplicationQuery,
  useDeleteQuickApplicationMutation,
} = quickApplicationApi
