import React from 'react'
import { WatchLater } from '@mui/icons-material'
import { date } from '@trr/frontend-datetime'

import { StatusCard } from '../../../../../Components/StatusCard/StatusCard'
import { MyApplicationDrawer } from '../../MyApplicationDrawer/MyApplicationDrawer'
import usePickEpiContent, {
  replaceEpiVariables,
} from '../../../../../Utils/Content/usePickEpiContent'

interface PendingStatusCardProps {
  applicationDate: Date
}

export const PendingStatusCard: React.FC<PendingStatusCardProps> = ({
  applicationDate,
}) => {
  const [isOpenApplicationDrawer, setIsOpenApplicationDrawer] =
    React.useState(false)

  const {
    inProgressDescription,
    inProgressHeading,
    applicationDateText,
    openApplicationButtonLabel,
  } = usePickEpiContent().selectedViewNy.applicationStatus

  return (
    <>
      <MyApplicationDrawer
        isOpen={isOpenApplicationDrawer}
        onClose={() => setIsOpenApplicationDrawer(false)}
      />
      <StatusCard
        heading={inProgressHeading}
        body={inProgressDescription}
        icon={<WatchLater />}
        iconColor="default"
        action={{
          label: openApplicationButtonLabel,
          onClick: () => setIsOpenApplicationDrawer(true),
        }}
        footer={replaceEpiVariables(applicationDateText, {
          date: date.format(applicationDate, { year: true }),
        })}
      />
    </>
  )
}
