import React from 'react'
import { Document, Page } from '@react-pdf/renderer'

import { Cv } from '../../GeneratedCv.types'
import { pdfStyles as s } from '../styles'
import { PdfStack } from '../Components/PdfStack'

import { ExperienceSection } from './ExperienceSection/ExperienceSection'
import { ContactSection } from './ContactSection/ContactSection'
import { ReferencesSection } from './ReferencesSection/ReferencesSection'
import { SkillsSection } from './SkillsSection/SkillsSection'
import { HeaderSection } from './HeaderSection/HeaderSection'

interface PdfDocumentProps {
  cv: Cv
}

const tmpContent = {
  skillsHeading: 'Kompetenser',
  referencesHeading: 'Referenser',
  workExperiencesHeading: 'Yrkeserfarenhet',
  educationsHeading: 'Utbildningar',
  contactHeading: 'Kontaktuppgifter',
  dateFormat: 'yyyy-MM-dd',
}

export const PdfDocument = ({ cv }: PdfDocumentProps) => {
  const c = tmpContent

  const GOLDEN_RATIO = 1.618

  const MainElement = (
    <PdfStack spacing={8} style={{ flex: GOLDEN_RATIO }}>
      {cv?.workExperiences?.length > 0 && (
        <ExperienceSection
          experiences={cv.workExperiences}
          heading={c.workExperiencesHeading}
        />
      )}
      {cv?.educations?.length > 0 && (
        <ExperienceSection
          experiences={cv.educations}
          heading={c.educationsHeading}
        />
      )}
    </PdfStack>
  )

  const SidebarElement = (
    <PdfStack spacing={8} sidebar>
      <ContactSection
        heading={c.contactHeading}
        mail={cv?.email}
        phone={cv?.phone}
      />
      {cv?.skills?.length > 0 && (
        <SkillsSection skills={cv.skills} heading={c.skillsHeading} />
      )}
      {cv?.references?.filter(Boolean).length > 0 && (
        <ReferencesSection
          references={cv.references.filter(Boolean)}
          heading={c.referencesHeading}
        />
      )}
    </PdfStack>
  )

  return (
    <Document style={{ width: '100%' }}>
      <Page style={s.page} size="A4">
        <PdfStack spacing={40}>
          <HeaderSection
            heading={cv?.fullname}
            summary={cv?.summary}
            profileImage={cv?.profileImage}
          />
          <PdfStack id="content" spacing={16} style={{ flexDirection: 'row' }}>
            {MainElement}
            {SidebarElement}
          </PdfStack>
        </PdfStack>
      </Page>
    </Document>
  )
}
