import React, { useMemo } from 'react'
import {
  FormControlLabel,
  FormControlLabelProps,
  Switch,
  Typography,
} from '@mui/material'
import { useSearchQueryParams } from '@local/Utils/Hooks/useSearchQueryParams'
import usePickEpiContent, {
  replaceEpiVariables,
} from '@local/Utils/Content/usePickEpiContent'
import { useFeatureFlag } from '@local/Utils'
import { useTrackCustomClick } from '@trr/gtm-tracking'

interface IncludeJobAdTextResultsToggleProps
  extends Partial<FormControlLabelProps> {
  totalHits: number
  totalHitsWithDescription: number
  disabled?: boolean
}

export const IncludeJobAdTextResultsToggle = ({
  totalHits,
  totalHitsWithDescription,
  disabled = false,
  ...formControlLabelProps
}: IncludeJobAdTextResultsToggleProps) => {
  const { formattedSearchParams, setBooleanSearchField, setTextSearchField } =
    useSearchQueryParams()
  const {
    showSearchResultsFromJobAdText,
    showSearchResultsFromJobAdTextStatic,
  } = usePickEpiContent().sokNy.resultList
  const { isGtmEnabled } = useFeatureFlag()
  const trackClick = useTrackCustomClick()
  const hitsDifference = totalHitsWithDescription - totalHits
  const totalCount =
    hitsDifference === 0 || formattedSearchParams.searchAdDescription
      ? null
      : hitsDifference?.toString()

  const handleSearchAdDescriptionChange = (_e: unknown, checked: boolean) => {
    setTextSearchField('', 'currentFacet')
    if (isGtmEnabled) {
      trackClick('Sök även i annonstext', {
        label: checked ? 'På' : 'Av',
      })
    }
    setBooleanSearchField(
      !formattedSearchParams.searchAdDescription,
      'searchAdDescription'
    )
  }

  const showDescriptionToggle = useMemo(
    () =>
      totalHitsWithDescription > 0 &&
      !(hitsDifference === 0 && !formattedSearchParams.searchAdDescription),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [totalHitsWithDescription]
  )

  if (!showDescriptionToggle) {
    return null
  }

  return (
    <FormControlLabel
      data-testid="include-job-ad-text-results-toggle"
      control={
        <Switch
          checked={formattedSearchParams.searchAdDescription}
          onChange={handleSearchAdDescriptionChange}
          color="primary"
        />
      }
      disabled={disabled}
      label={
        <Typography>
          {hitsDifference > 0
            ? replaceEpiVariables(showSearchResultsFromJobAdText, {
                count: totalCount,
              })
            : showSearchResultsFromJobAdTextStatic}
        </Typography>
      }
      {...formControlLabelProps}
    />
  )
}
