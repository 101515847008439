import { Experience } from '@local/Features/SelectedJob/GeneratedCv/GeneratedCv.types'

export const formatTitle = (
  experience: Pick<Experience, 'title' | 'organization'>,
  defaultTitle?: string
) => {
  if (experience.title) {
    return experience.organization
      ? `${experience.title} på ${experience.organization}`
      : experience.title
  }

  if (experience.organization) {
    return experience.organization
  }

  return defaultTitle
}
