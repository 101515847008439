import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteCloseReason,
  Box,
  InputLabel,
  ListItem,
  ListItemText,
  TextField,
} from '@mui/material'
import parse from 'html-react-parser'
import { includes } from 'ramda'
import React, { useRef, useState } from 'react'
import { useFeatureFlag } from '@local/Utils'
import { useTrackCustomClick } from '@trr/gtm-tracking'

import { useGetJobTitlesQuery } from '../../../Api/autocompleteApi'
import { useGetHistoricSearchesQuery } from '../../../Api/historicSearchesApi'
import { HistoricSearchesResponse } from '../../../Api/types/api.types'
import useDebounce from '../../../Utils/Hooks/useDebounce'
import usePickEpiContent from '../../../Utils/Content/usePickEpiContent'
import { useSearchQueryParams } from '../../../Utils/Hooks/useSearchQueryParams'
import { AutocompletePopper } from '../Components/AutocompletePopper'

import HistoricSearchOption from './HistoricSearchOption'

interface ProfessionsAutocompleteProps {
  jobtitles: string[]
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    newJobtitles: string[]
  ) => void
}

export const ProfessionsAutocomplete = ({
  jobtitles = [],
  onChange,
}: ProfessionsAutocompleteProps) => {
  const { label, placeholder, noResult, searchHistorySubHeader } =
    usePickEpiContent().sokNy.yrkenSearch

  const { isGtmEnabled } = useFeatureFlag()
  const trackClick = useTrackCustomClick()
  const inputRef = useRef<HTMLDivElement>(null)
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 300)
  const skipSearch = debouncedSearchTerm.length < 2

  const { data, isFetching } = useGetJobTitlesQuery(debouncedSearchTerm, {
    skip: skipSearch,
  })

  const { data: historicSearches } = useGetHistoricSearchesQuery()
  const { setSearchParams } = useSearchQueryParams()

  const handleInputChange = (_, value: string) => {
    setSearchTerm(value)
  }

  const handleOnBlur = () => {
    if (searchTerm?.length) {
      const searchTermCapitalized =
        searchTerm.charAt(0).toUpperCase() + searchTerm.slice(1)
      onChange(null, [...jobtitles, searchTermCapitalized])
    }
    setSearchTerm('')
  }

  const handleOnChange = (
    event: React.SyntheticEvent<Element, Event>,
    values: string[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string | HistoricSearchesResponse>
  ) => {
    if (details && typeof details.option !== 'string') {
      setSearchParams({
        professions: details.option.professions,
        locations: details.option.locationInformations.map(
          (loc) => `${loc.name},${loc.type}`
        ),
        historicSearchTimeStamp: details.option.searchTimeStamp,
      })
      return
    }

    if (reason === 'createOption') {
      const formattedValues = values
        .map((value) =>
          value === details.option
            ? details.option.charAt(0).toUpperCase() + details.option.slice(1)
            : value
        )
        .filter((x) => x.trim())

      onChange(event, formattedValues)
    } else {
      onChange(
        event,
        values.filter((x) => x.trim())
      )
    }
  }

  const notAlreadySelectedJobtitles =
    data
      ?.map(
        (jobtitle) =>
          jobtitle.jobTitleWithHighLightedSearchTerm ?? jobtitle.name
      )
      ?.filter(
        (jobtitle, index, self) =>
          !includes(jobtitle.replace(/(<([^>]+)>)/gi, ''), jobtitles) &&
          self.indexOf(jobtitle) === index
      ) ?? []

  const showAutocompleteOptions = !skipSearch
  const showHistoricalSearches =
    debouncedSearchTerm.length === 0 &&
    !jobtitles.length &&
    historicSearches?.length > 0

  const autocompleteOptions = notAlreadySelectedJobtitles
    .map((x) => x.toLowerCase())
    .includes(`<strong>${debouncedSearchTerm}</strong>`.toLowerCase())
    ? notAlreadySelectedJobtitles
    : [`<i>${debouncedSearchTerm}</i>`, ...notAlreadySelectedJobtitles]

  const historicSearchesOptions = historicSearches
    ?.filter((hs) => hs.professions.length > 0)
    .slice(0, 7)

  const options = [
    ...(showAutocompleteOptions ? autocompleteOptions : []),
    ...(showHistoricalSearches ? historicSearchesOptions : []),
  ]

  const handleOnClose = (e, reason: AutocompleteCloseReason) => {
    const identifier = 'Arbetsplatsfältet'
    if (isGtmEnabled) {
      if (reason === 'selectOption') {
        if (showHistoricalSearches) {
          trackClick(identifier, {
            label: 'Användaren valde en senaste sökning',
          })
        }
        if (showAutocompleteOptions) {
          trackClick(identifier, {
            label:
              'Användaren gjorde ett val ur den automatiska kompletteringen',
          })
        }
      }
      if (reason === 'createOption') {
        trackClick(identifier, { label: 'Användaren gjorde en fritextsökning' })
      }
    }
  }

  return (
    <Box sx={{ mb: 2 }}>
      <InputLabel>{label}</InputLabel>
      <Autocomplete
        freeSolo
        multiple
        options={options ?? []}
        filterOptions={(x) => x}
        value={jobtitles}
        openOnFocus
        inputValue={searchTerm}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        onInputChange={handleInputChange}
        getOptionLabel={(opt) => (typeof opt === 'string' ? opt : opt.id)}
        popupIcon={null}
        onClose={handleOnClose}
        ChipProps={{ translate: 'no' }}
        PopperComponent={AutocompletePopper}
        ListboxProps={
          showHistoricalSearches
            ? {
                sx: {
                  maxHeight: 500,
                },
              }
            : undefined
        }
        noOptionsText={
          showAutocompleteOptions && !isFetching && !data?.length
            ? noResult
            : undefined
        }
        renderOption={(props, option) =>
          typeof option === 'string' ? (
            <ListItem {...props} dense divider={false}>
              <ListItemText primary={parse(option)} />
            </ListItem>
          ) : (
            <HistoricSearchOption {...props} option={option} />
          )
        }
        renderInput={(params) => (
          <TextField {...params} placeholder={placeholder} ref={inputRef} />
        )}
        groupBy={(opt) =>
          typeof opt !== 'string' ? searchHistorySubHeader : undefined
        }
        data-testid="profession-autocomplete"
      />
    </Box>
  )
}
