import React from 'react'
import { HourglassBottom } from '@mui/icons-material'
import { Button, Stack, Typography } from '@mui/material'

export const AnalysingCvErrorBlock: React.FC = () => (
  <Stack gap={5} pt={10} pb={10}>
    <HourglassBottom
      sx={{
        alignSelf: 'center',
        animation: 'spin 2s ease-in-out infinite',
        transform: 'rotate(180deg) scale(2)',
        '@keyframes spin': {
          '0%': { transform: 'rotate(0deg) scale(2)' },
          '20%': { transform: 'rotate(0deg) scale(2)' },
          '80%': { transform: 'rotate(180deg) scale(2)' },
          '100%': { transform: 'rotate(180deg) scale(2)' },
        },
      }}
    />
    <Typography>
      Vi analyserar fortfarande CV:t i din matchningsprofil, vänligen försök
      igen om en stund.
    </Typography>
    <Button onClick={() => window.location.reload()} variant="contained">
      Ladda om sidan
    </Button>
  </Stack>
)
