import { baseApi } from '@local/Api/baseApi'
import {
  JobAdList,
  OrderOption,
  QuickApplicationStatus,
  SavedTo,
} from '@local/Api/types/api.types'

interface AppliedJobsRequest {
  orderBy: OrderOption
  page: number
  pageSize: number
}

export interface AppliedJobsResponse {
  totalHits: number
  page: number
  pageSize: number
  totalPages: number
  jobAds: {
    jobAd: {
      id: string
      header: string
      employerName: string
      jobTitle: string
      descriptionShort: string
      locationName: string
      locationType: string
      publishDate: string
      deadline: string
      isTrrRecruitment: boolean
    }
    saveStatus: {
      status: QuickApplicationStatus
      savedTo: SavedTo
      createdAt: string
      lastModifiedAt: string
    }[]
  }[]
}

export const appliedJobsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAppliedJobs: builder.query<JobAdList, AppliedJobsRequest>({
      providesTags: ['AppliedJobs'],
      query: (params) => ({
        url: '/jobs/ads/saved',
        params: {
          ...params,
          savedTo: SavedTo.Applied,
        },
      }),
      transformResponse: (response: AppliedJobsResponse) => ({
        jobAds: response.jobAds?.map((job) => ({
          id: job.jobAd.id,
          header: job.jobAd.header,
          employerName: job.jobAd.employerName,
          locationName: job.jobAd.locationName,
          jobTitle: job.jobAd.jobTitle,
          description: job.jobAd.descriptionShort,
          locationType: job.jobAd.locationType,
          applicationStatus: job.saveStatus?.find(
            (x) => x.savedTo === SavedTo.Applied
          ).status,
          createdAt: new Date(
            job.saveStatus?.find(
              (x) => x.savedTo === SavedTo.Applied
            )?.createdAt
          ).toDateString(),
          lastModifiedAt: new Date(
            job.saveStatus?.find(
              (x) => x.savedTo === SavedTo.Applied
            )?.lastModifiedAt
          ).toDateString(),
          isSaved: job.saveStatus.some((x) => x.savedTo === SavedTo.Saved),
          publishDate: job.jobAd.publishDate,
          deadline: job.jobAd.deadline,
          isTrrJobAd: job.jobAd.isTrrRecruitment,
        })),
        totalHits: response.totalHits,
        page: response.page,
        pageSize: response.pageSize,
        totalPages: response.totalPages,
      }),
    }),
  }),
})

export const { useGetAppliedJobsQuery } = appliedJobsApi
