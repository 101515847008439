import { useEffect, useState } from 'react'

export const useIsInView = <T extends HTMLElement>(
  ref: React.MutableRefObject<T>
) => {
  const [entry, setEntry] = useState<IntersectionObserverEntry>()

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setEntry(entry))

    if (!ref.current) return

    observer.observe(ref?.current)
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect()
    }
  }, [ref])

  return entry
}
