import { Font, StyleSheet } from '@react-pdf/renderer'

Font.registerHyphenationCallback((word) => [word])

export const pdfStyles = StyleSheet.create({
  page: {
    padding: 36,
    lineHeight: 1.4,
  },
  h1: {
    fontSize: 24,
    fontWeight: 900,
  },
  h2: {
    fontSize: 14,
    fontWeight: 900,
  },
  h3: {
    fontSize: 12,
    fontWeight: 900,
  },
  body1: {
    fontSize: 12,
  },
  body2: {
    fontSize: 10,
  },
  link: {
    color: 'black',
    textDecoration: 'none',
  },
  borderBottom: {
    borderBottom: '1px solid black',
  },
  textGrey: {
    color: 'grey',
  },
  italic: {
    fontStyle: 'italic',
  },
  gutter1: {
    marginBottom: 1,
  },
  gutter2: {
    marginBottom: 2,
  },
  gutter3: {
    marginBottom: 4,
  },
  gutter4: {
    marginBottom: 8,
  },
  gutter5: {
    marginBottom: 16,
  },
  gutterRight: {
    marginRight: 24,
  },
  sidebar: {
    padding: 4,
    paddingLeft: 16,
    flex: 1,
    minHeight: '100%',
  },
})
