import { useLayoutEffect, useState } from 'react'

export const useWindowResizeListener = () => {
  const [width, setWidth] = useState(0)
  useLayoutEffect(() => {
    const updateWidth = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', updateWidth)

    return window.removeEventListener('reszie', updateWidth)
  }, [])

  return width
}
