import React from 'react'
import { Text, View, Image } from '@react-pdf/renderer'

import { PdfStack } from '../../Components/PdfStack'
import { pdfStyles as s } from '../../styles'

interface HeaderSectionProps {
  heading: string
  summary: string
  profileImage: string
}

export const HeaderSection = ({
  heading,
  summary,
  profileImage,
}: HeaderSectionProps) => (
  <PdfStack id="header" spacing={8} style={{ flexDirection: 'row' }}>
    {profileImage && (
      <View style={{ flex: 2, marginTop: 16 }}>
        <View style={{ width: 200, height: 120 }}>
          <Image
            src={profileImage}
            style={{
              width: 80,
              height: 'auto',
              objectFit: 'contain',
              borderRadius: '50%',
            }}
          />
        </View>
      </View>
    )}
    <View style={{ flex: 9 }}>
      <Text style={[s.h1, s.gutter3]}>{heading}</Text>
      <Text style={[s.body1, s.gutterRight]}>{summary}</Text>
    </View>
  </PdfStack>
)
