import React from 'react'
import { Grid2 as Grid } from '@mui/material'

export const CardGrid = ({ children }) => {
  return (
    <Grid
      container
      rowSpacing={3}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      alignItems="stretch"
      width={'100%'}
    >
      {children}
    </Grid>
  )
}
