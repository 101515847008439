import { Add } from '@mui/icons-material'
import { Button, Snackbar, Stack, Typography } from '@mui/material'
import React from 'react'

export const EditFormActions: React.FC = () => {
  const [isSnackbarOpen, setIsSnackbarOpen] = React.useState(false)
  const handleAddSection = () => {
    setIsSnackbarOpen(true)
  }

  return (
    <Stack gap={2}>
      <Typography variant="h6" pt={2} pb={2}>
        Lägg till fler sektioner
      </Typography>
      <Stack gap={1.5} direction="row" flexWrap="wrap" mb={8}>
        <Button
          color="inherit"
          variant="outlined"
          size="small"
          onClick={handleAddSection}
          data-gtm-label="Skapa ny sektion: hobby"
        >
          Hobbies
        </Button>
        <Button
          color="inherit"
          variant="outlined"
          size="small"
          onClick={handleAddSection}
          data-gtm-label="Skapa ny sektion: kurs"
        >
          Kurser
        </Button>
        <Button
          color="inherit"
          variant="outlined"
          size="small"
          onClick={handleAddSection}
          data-gtm-label="Skapa ny sektion: länk"
        >
          Länkar
        </Button>
        <Button
          variant="outlined"
          size="small"
          sx={{ textWrap: 'nowrap' }}
          startIcon={<Add />}
          onClick={handleAddSection}
          data-gtm-label="Skapa ny sektion: generell"
        >
          Skapa sektion
        </Button>
      </Stack>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={() => setIsSnackbarOpen(false)}
        message="Kul att du vill lägga till fler sektioner. Det går inte att göra riktigt än men är på gång!"
      />
    </Stack>
  )
}
