import { useTrackCustomClick } from '@trr/gtm-tracking'
import { useEffect, useState } from 'react'
import { debounce } from 'lodash'

export const useScrollTracker = (
  componentRef: React.MutableRefObject<HTMLDivElement>,
  yPixelOffsetTrigger: number
) => {
  const [scrollY, setScrollY] = useState(0)
  const [hasTrackedEvent, setHasTrackedEvent] = useState(false)
  const sendTrackEvent = useTrackCustomClick()

  const trackScroll = () => {
    if (!hasTrackedEvent) {
      setScrollY(componentRef.current?.scrollTop)

      if (scrollY > yPixelOffsetTrigger) {
        setHasTrackedEvent(true)
        sendTrackEvent('filter_scroll', {
          label: 'Användaren använde infinityscroll',
        })
      }
    }
  }

  const debouncedTrackScroll = debounce(trackScroll, 200)

  useEffect(() => {
    const addScrollEvent = () => {
      componentRef.current.addEventListener('scroll', debouncedTrackScroll)
    }

    addScrollEvent()

    const componentCopy = componentRef
    return () => {
      componentCopy?.current?.removeEventListener(
        'scroll',
        debouncedTrackScroll
      )
    }
  })
}
