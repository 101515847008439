// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const scrollIntoView = (ref: React.MutableRefObject<any>) => {
  if (!ref?.current) return

  const rect = ref.current.getBoundingClientRect()
  if (rect.top < 0 || rect.bottom > window.innerHeight) {
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    })
  }
}
