import { merge } from 'lodash'

import { Content } from '../../App.types'

import { fallbackContent } from './fallbackContent'

type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>
}

export const mergeContentWithFallback = (
  content: DeepPartial<Content>
): typeof fallbackContent => merge(fallbackContent, content)
