import React, { forwardRef, MutableRefObject, Ref } from 'react'
import { Stack } from '@mui/material'
import { Formik, FormikProps } from 'formik'
import { SmartAccordionProvider } from '@local/Components/Accordion'
import { ContainerQueryProvider } from '@local/Utils/Hooks/useContainerQuery'

import { Cv } from '../GeneratedCv.types'

import { WorkExperienceField } from './WorkExperience/WorkExperienceField'
import { PersonalInfoField } from './PersonalInfo/PersonalInfoField'
import { EducationField } from './Education/EducationField'
import { ReferencesField } from './References/ReferencesField'
import { CompetencesField } from './Competences/CompetencesField'
import { EditFormActions } from './EditFormActions/EditFormActions'

interface CreateCvFormProps {
  cv: Cv
}

export const CreateCvForm = forwardRef<unknown, CreateCvFormProps>(
  (props, ref) => {
    const stackParentRef = React.createRef<HTMLDivElement>()
    return (
      <Formik
        initialValues={props.cv}
        onSubmit={() => {}}
        innerRef={
          ref as Ref<FormikProps<CreateCvFormProps>> &
            MutableRefObject<FormikProps<Cv>>
        }
      >
        <SmartAccordionProvider>
          <Stack gap={2} ref={stackParentRef}>
            <ContainerQueryProvider
              containerRef={stackParentRef}
              breakPoints={{ xs: 300, sm: 400, md: 600, lg: 800 }}
            >
              <PersonalInfoField />
              <CompetencesField />
              <WorkExperienceField />
              <EducationField />
              <ReferencesField />
              <EditFormActions />
            </ContainerQueryProvider>
          </Stack>
        </SmartAccordionProvider>
      </Formik>
    )
  }
)
