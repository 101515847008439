import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteCloseReason,
  ListItem,
  ListItemText,
  SxProps,
} from '@mui/material'
import parse from 'html-react-parser'
import React, { useState } from 'react'
import { useTrackCustomClick } from '@trr/gtm-tracking'
import { useFeatureFlag } from '@local/Utils'
import { FmdGood } from '@mui/icons-material'

import { useGetLocationsQuery } from '../../../Api/autocompleteApi'
import { useGetHistoricSearchesQuery } from '../../../Api/historicSearchesApi'
import { DeepLocation } from '../../../Api/types/api.types'
import useDebounce from '../../../Utils/Hooks/useDebounce'
import usePickEpiContent from '../../../Utils/Content/usePickEpiContent'
import {
  AutocompleteOption,
  SearchAutocomplete,
} from '../Components/SearchAutocomplete'
import {
  convertAutocompleteOptionsToDeepLocation,
  convertDeepLocationsToAutocompleteOptions,
  getFilteredLocationAutocompleteOptions,
  getUniqueLocationsFromHistoricalSearches,
  isLocationNotAlreadyInList,
} from '../Utils/autocompleteHelpers'

interface LocationAutocompleteProps {
  locations: DeepLocation[]
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    newLocations: DeepLocation[]
  ) => void
  sx?: SxProps
}

export const LocationAutocomplete = ({
  locations = [],
  onChange,
  sx,
}: LocationAutocompleteProps) => {
  const { placeholder, noResult } = usePickEpiContent().sokNy.arbetsortSearch
  const { isGtmEnabled } = useFeatureFlag()
  const trackClick = useTrackCustomClick()
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 300)
  const skipSearch = debouncedSearchTerm.length < 2

  const { data, isFetching } = useGetLocationsQuery(debouncedSearchTerm, {
    skip: skipSearch,
  })

  const addedLocationOptions =
    convertDeepLocationsToAutocompleteOptions(locations)
  const autocompleteOptions = getFilteredLocationAutocompleteOptions(
    data,
    locations
  )

  const { data: historicSearches } = useGetHistoricSearchesQuery()

  const uniqueHistoricSearchLocations: AutocompleteOption<DeepLocation>[] =
    getUniqueLocationsFromHistoricalSearches(historicSearches)
      ?.filter(isLocationNotAlreadyInList(locations))
      .slice(0, 7)
      ?.map((loc) => ({
        label: loc.name,
        value: loc,
      }))

  const historicSearchOptions = uniqueHistoricSearchLocations ?? []

  const showAutocompleteOptions = !skipSearch
  const showHistoricalSearches =
    debouncedSearchTerm.length === 0 &&
    uniqueHistoricSearchLocations?.length > 0

  const options: AutocompleteOption<DeepLocation>[] = [
    ...(showAutocompleteOptions ? autocompleteOptions : []),
    ...(showHistoricalSearches ? historicSearchOptions : []),
  ]

  const handleOnClose = (e, reason: AutocompleteCloseReason) => {
    const identifier = 'Platsfältet'
    if (reason === 'selectOption' && isGtmEnabled) {
      if (showHistoricalSearches) {
        trackClick(identifier, { label: 'Användaren valde en senaste sökning' })
      }
      if (showAutocompleteOptions) {
        trackClick(identifier, {
          label: 'Användaren gjorde ett val ur den automatiska kompletteringen',
        })
      }
    }
  }

  const handleOnChange = (
    event: React.SyntheticEvent<Element, Event>,
    values: AutocompleteOption<DeepLocation>[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<AutocompleteOption<DeepLocation>>
  ) => {
    const option = details?.option
    if (option) {
      if (typeof option === 'string') {
        return
      }
      if (reason === 'createOption' || reason === 'selectOption') {
        const filteredValues: DeepLocation[] =
          convertAutocompleteOptionsToDeepLocation(values).filter(
            (x) => x.id !== option.value.id
          )

        onChange(event, [...filteredValues, option.value])
        return
      }
    }

    onChange(
      event,
      values.map((x) => x.value).filter((x) => x.id !== option.value.id)
    )
  }

  const handleInputChange = (_, value) => {
    setSearchTerm(value)
  }

  const noOptionsText =
    showAutocompleteOptions && !isFetching && !data?.length
      ? noResult
      : undefined

  const renderOptions = (props, option: AutocompleteOption<DeepLocation>) => (
    <ListItem
      {...props}
      divider={false}
      key={`${props.key} ${option.value.type}`}
    >
      <ListItemText
        primary={parse(
          `${option.value.nameWithHighLightedSearchTerm ?? option.value.name}, ${
            option.value.type
          }`
        )}
        primaryTypographyProps={{
          noWrap: true,
          overflow: 'ellipsis',
        }}
      />
    </ListItem>
  )

  return (
    <SearchAutocomplete
      sx={sx}
      options={options ?? []}
      placeholder={placeholder}
      value={addedLocationOptions}
      inputValue={searchTerm}
      autoHighlight={!showHistoricalSearches}
      onChange={handleOnChange}
      onClose={handleOnClose}
      onInputChange={handleInputChange}
      limitHeight={showHistoricalSearches}
      icon={<FmdGood />}
      noOptionsText={noOptionsText}
      getOptionLabel={(option: AutocompleteOption<DeepLocation>) =>
        option.label
      }
      renderOption={renderOptions}
      data-testid="location-autocomplete"
      groupBy={showHistoricalSearches ? () => 'Senaste sökningar' : undefined}
    />
  )
}
