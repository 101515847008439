import React, { forwardRef } from 'react'
import { useMediaQuery, useTheme } from '@mui/material'

import {
  ArraySearchParams,
  useSearchQueryParams,
} from '../../Utils/Hooks/useSearchQueryParams'
import { FacetOptions } from '../../Api/types/api.types'

import { FilterBarDesktop } from './Components/FilterBarDesktop'
import { FilterBarMobile } from './Components/FilterBarMobile'
import { FilterSectionContextProvider } from './FilterSectionContext'

interface FilterSectionProps {
  options: FacetOptions
  onLoadMoreOptions: () => void
  filterCount: number
  isFetching: boolean
}

export const FilterSection = forwardRef<HTMLDivElement, FilterSectionProps>(
  (
    { options, onLoadMoreOptions, filterCount, isFetching }: FilterSectionProps,
    ref
  ) => {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

    const { setArraySearchParamField } = useSearchQueryParams()
    const handleFilterChange = (
      filterName: string,
      group: keyof ArraySearchParams
    ) => {
      setArraySearchParamField(filterName, group)
    }

    return (
      <FilterSectionContextProvider
        isFetching={isFetching}
        filterCount={filterCount}
        onLoadMoreOptions={onLoadMoreOptions}
        maxFilterOptions={200}
      >
        {isDesktop ? (
          <FilterBarDesktop
            filterChange={handleFilterChange}
            ref={ref}
            options={options}
          />
        ) : (
          <FilterBarMobile
            filterChange={handleFilterChange}
            options={options}
          />
        )}
      </FilterSectionContextProvider>
    )
  }
)
