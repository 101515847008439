import {
  DeepLocation,
  HistoricSearchesResponse,
  JobTitle,
} from '@local/Api/types/api.types'
import { capitalize } from 'lodash'
import { convertToSwedishLocationTypes } from '@local/Api/helpers/autocompleteHelpers'

import { AutocompleteOption } from '../Components/SearchAutocomplete'

export const getUniqueLocationsFromHistoricalSearches = (
  historicSearches: HistoricSearchesResponse[]
): DeepLocation[] =>
  historicSearches
    ?.map((hs) =>
      hs.locationInformations
        .map((loc) => ({
          ...loc,
          type: convertToSwedishLocationTypes(loc.type),
        }))
        // Reverse the array so that the most recent searches appear first
        .reverse()
    )
    .flat()
    .filter(
      (loc, index, self) =>
        index ===
        self.findIndex((t) => t.name === loc.name && t.type === loc.type)
    )

export const convertAutocompleteOptionsToDeepLocation = (
  value: AutocompleteOption<DeepLocation>[]
): DeepLocation[] => {
  return value.map((v) => v.value)
}

export const convertDeepLocationsToAutocompleteOptions = (
  locations: DeepLocation[]
): AutocompleteOption<DeepLocation>[] =>
  locations.map((l) => ({
    label: `${l.name}, ${l.type}`,
    value: l,
  }))

export const convertJobTitlesToAutocompleteOptions = (
  jobTitles: JobTitle[]
): AutocompleteOption<JobTitle>[] =>
  jobTitles.map((j) => ({
    label: j.name,
    value: j,
  }))

export const isLocationNotAlreadyInList =
  (alreadyAddedLocations: DeepLocation[]) => (location: DeepLocation) =>
    !alreadyAddedLocations.find(
      (l) => l.name === location.name && l.type === location.type
    )

export const isJobTitleNotAlreadyInList =
  (alreadyAddedJobtitles: JobTitle[]) => (jobtitle: JobTitle) =>
    !alreadyAddedJobtitles.find(
      (j) =>
        jobtitle?.name && j?.name.toLowerCase() === jobtitle.name.toLowerCase()
    )

export const getFilteredLocationAutocompleteOptions = (
  locationsToFilter: DeepLocation[],
  alreadyAddedLocations: DeepLocation[]
): AutocompleteOption<DeepLocation>[] => {
  const notAlreadySelectedAutocompleteLocations = locationsToFilter?.filter(
    isLocationNotAlreadyInList(alreadyAddedLocations)
  )
  return (
    notAlreadySelectedAutocompleteLocations?.map((loc) => ({
      label: loc.name,
      value: loc,
    })) ?? []
  )
}

export const getFilteredJobTitleAutocompleteOptions = (
  jobTitlesToFilter: JobTitle[],
  alreadyAddedJobTitles: JobTitle[],
  freeTextJobTitleString: string
): AutocompleteOption<JobTitle>[] => {
  const notAlreadySelectedAutocompleteJobtitles = (
    [
      ...(freeTextJobTitleString
        ? [
            {
              name: capitalize(freeTextJobTitleString),
              jobTitleWithHighLightedSearchTerm: `<i>${capitalize(freeTextJobTitleString)}</i>`,
            },
          ]
        : []),
      ...(jobTitlesToFilter ?? []),
    ] as JobTitle[]
  )?.filter(isJobTitleNotAlreadyInList(alreadyAddedJobTitles))

  return (
    notAlreadySelectedAutocompleteJobtitles?.map((j) => ({
      label: j?.jobTitleWithHighLightedSearchTerm,
      value: j,
    })) ?? []
  )
}
