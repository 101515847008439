import {
  Box,
  BoxProps,
  Stack,
  StackProps,
  Tab,
  TabProps,
  Tabs,
  TabsProps,
} from '@mui/material'
import React from 'react'

interface DynamicTabsProps<
  TabKey extends string,
  DefaultTabKey extends TabKey,
> {
  tabs: {
    label: TabKey
    renderLabel?: (value: string) => React.ReactNode
    content: React.ReactNode
    unmountOnExit?: boolean
    tabProps?: TabProps
    contentProps?: BoxProps
  }[]
  defaultTab?: DefaultTabKey
  tabsProps?: TabsProps
  tabProps?: TabProps
  contentProps?: BoxProps
  containerProps?: StackProps
}

export const DynamicTabs = <
  TabKey extends string,
  DefaultTabKey extends TabKey,
>({
  tabs,
  defaultTab,
  tabProps,
  tabsProps,
  contentProps,
  containerProps,
}: DynamicTabsProps<TabKey, DefaultTabKey>): JSX.Element => {
  const defaultTabLabel = defaultTab ?? tabs[0]?.label
  const [currentTabKey, setCurrentTabKey] =
    React.useState<TabKey>(defaultTabLabel)

  const [openedTabs, setOpenedTabs] = React.useState<TabKey[]>([
    defaultTabLabel,
  ])

  const tabsWithContent = tabs.filter((tab) => !!tab.content)

  if (tabsWithContent.length === 0) return null

  if (tabsWithContent.length === 1)
    return <Box {...containerProps}>{tabsWithContent[0].content}</Box>

  const handleTabsChange = (_event: unknown, tab: TabKey) => {
    setCurrentTabKey(tab)
    setOpenedTabs((prev) => (prev.includes(tab) ? prev : [...prev, tab]))
  }

  return (
    <Stack {...containerProps}>
      <Tabs
        value={currentTabKey}
        onChange={handleTabsChange}
        variant="scrollable"
        scrollButtons="auto"
        {...tabsProps}
      >
        {tabs.map((tab) => {
          const label = tab.renderLabel ? tab.renderLabel(tab.label) : tab.label
          return (
            <Tab
              key={tab.label}
              value={tab.label}
              label={label}
              {...tabProps}
              {...tab.tabProps}
            />
          )
        })}
      </Tabs>
      {tabsWithContent
        .map((tab) =>
          openedTabs.includes(tab.label) &&
          (tab.unmountOnExit ? currentTabKey === tab.label : true) ? (
            <Box
              key={tab.label}
              {...contentProps}
              {...tab.contentProps}
              display={currentTabKey === tab.label ? 'block' : 'none'}
            >
              {tab.content}
            </Box>
          ) : null
        )
        .filter(Boolean)}
    </Stack>
  )
}
