import React from 'react'
import { Text } from '@react-pdf/renderer'

import { PdfSection } from '../../Components/PdfSection'
import { pdfStyles as s } from '../../styles'

interface SkillsSectionProps {
  heading: string
  skills: string[]
}

export const SkillsSection = ({ skills, heading }: SkillsSectionProps) => (
  <PdfSection title={heading} font="h2" style={s.gutter5}>
    <Text style={s.body1}>{skills.join(' • ')}</Text>
  </PdfSection>
)
