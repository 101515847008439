import React, { forwardRef } from 'react'
import { Button, Stack } from '@mui/material'

import {
  ArraySearchParams,
  useSearchQueryParams,
} from '../../../Utils/Hooks/useSearchQueryParams'
import usePickEpiContent from '../../../Utils/Content/usePickEpiContent'
import {
  Facet,
  FacetResponseOptions,
  JobFiltersRequest,
} from '../../../Api/types/api.types'
import { useFeatureFlag } from '../../../Utils'
import { useFilterSectionContext } from '../FilterSectionContext'
import InfinityScrollWrapper from '../../../Components/InfinityScrollWrapper/InfinityScrollWrapper'

import { FilterOptionGroup } from './FilterOptionGroup'
import { FilterOption, Option } from './FilterOption'
import Filter from './Typings/Filter'

interface FilterBarDesktopProps {
  options: FacetResponseOptions
  filterChange: (filterName: string, group: keyof ArraySearchParams) => void
}

export const mapFacetsToOptions = (facets: Facet[]): Option[] =>
  facets?.map((facet) => ({
    key: facet.name,
    title: facet.name,
    count: facet.count,
  }))

// eslint-disable-next-line react/display-name
export const FilterBarDesktop = forwardRef<
  HTMLDivElement,
  FilterBarDesktopProps
>(({ options, filterChange }, ref) => {
  const { searchParams, formattedSearchParams, setSearchParams } =
    useSearchQueryParams()
  const {
    skills,
    jobTitles,
    employers,
    workingHours,
    employmentTypes,
    country,
    region,
    municipality,
  } = usePickEpiContent().sokNy.sokfiltrering

  const { isProvidersEnabled, isLocationFiltersEnabled } = useFeatureFlag()

  const hasFiltersSelected = Object.keys(formattedSearchParams?.filters).some(
    (key) => formattedSearchParams?.filters[key]?.length
  )

  const resetFilterGroups = (listOfGroups: (keyof ArraySearchParams)[]) => {
    const resetObj = listOfGroups.reduce((acc, group) => {
      acc[group] = []
      return acc
    }, {} as Partial<JobFiltersRequest>)
    setSearchParams({ ...resetObj, currentFacet: '', page: 1 })
  }

  const onChangeFilter =
    (group: keyof ArraySearchParams) => (filterName: string) => {
      filterChange(filterName, group)
    }

  const resetFilters = () => {
    setSearchParams(
      {
        locations: searchParams.locations,
        professions: searchParams.professions,
      },
      'replace'
    )
  }

  const showCountryFilter =
    (options?.countries?.length > 1 ||
      formattedSearchParams?.filters?.countries?.length) &&
    isLocationFiltersEnabled

  const showRegionFilter =
    (options?.regions?.length > 1 ||
      formattedSearchParams?.filters?.regions?.length) &&
    isLocationFiltersEnabled

  const showMunicipalityFilter =
    (options?.municipalities?.length > 1 ||
      formattedSearchParams?.filters?.municipalities?.length) &&
    isLocationFiltersEnabled

  const filters: Filter[] = [
    {
      label: jobTitles,
      filterKey: 'jobTitles',
      optionKey: 'professions',
      translate: true,
    },
    {
      label: employers,
      filterKey: 'employers',
      optionKey: 'employers',
    },
    {
      label: skills,
      filterKey: 'skills',
      optionKey: 'skills',
      translate: true,
    },
    {
      label: workingHours,
      filterKey: 'workingHours',
      optionKey: 'workingHours',
      translate: true,
    },
    {
      label: employmentTypes,
      filterKey: 'employmentTypes',
      optionKey: 'employmentTypes',
      translate: true,
    },
    {
      label: 'Providers',
      filterKey: 'providers',
      optionKey: 'providers',
      hidden: !isProvidersEnabled,
    },
    {
      label: country,
      filterKey: 'countries',
      optionKey: 'countries',
      hidden: !showCountryFilter,
    },
    {
      label: region,
      filterKey: 'regions',
      optionKey: 'regions',
      hidden: !showRegionFilter,
    },
    {
      label: municipality,
      filterKey: 'municipalities',
      optionKey: 'municipalities',
      hidden: !showMunicipalityFilter,
    },
  ]

  const { shouldLoadMoreOptions, onLoadMoreOptions } = useFilterSectionContext()

  return (
    <Stack
      direction="row"
      flexWrap={'wrap'}
      gap={1}
      ref={ref}
      data-testid="filter-bar-desktop"
      mb={3}
      mt={3}
    >
      {filters
        .filter((filter) => !filter.hidden)
        .map((filter) => (
          <FilterOptionGroup
            key={filter.filterKey}
            label={filter.label}
            totalCount={options?.[filter.optionKey]?.length}
            selectCount={
              formattedSearchParams?.filters?.[filter.filterKey]?.length
            }
            translate={filter.translate}
            onResetFilters={() => resetFilterGroups([filter.filterKey])}
          >
            <InfinityScrollWrapper
              onLoadMore={onLoadMoreOptions}
              shouldLoadMore={shouldLoadMoreOptions(
                options?.[filter.optionKey]?.length ?? 0
              )}
              preventOverscroll
            >
              <FilterOption
                filterTitle={filter.label}
                selectedFilters={
                  formattedSearchParams?.filters?.[filter.filterKey]
                }
                options={mapFacetsToOptions(options?.[filter.optionKey])}
                onChangeFilter={onChangeFilter(filter.filterKey)}
              />
            </InfinityScrollWrapper>
          </FilterOptionGroup>
        ))}

      {hasFiltersSelected && (
        <Button
          size="small"
          variant="text"
          onClick={resetFilters}
          data-testid="reset-filters-button"
          sx={{ height: '32px' }}
        >
          Rensa allt
        </Button>
      )}
    </Stack>
  )
})
