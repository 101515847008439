import { Popper } from '@mui/material'
import React from 'react'

export const AutocompletePopper = (props) => (
  <Popper
    {...props}
    placement="bottom-start"
    modifiers={[
      {
        name: 'flip',
        enabled: false,
      },
    ]}
  />
)
