import { View, ViewProps, Text } from '@react-pdf/renderer'
import React, { PropsWithChildren } from 'react'
import { useTheme } from '@mui/material'

import { pdfStyles as s } from '../styles'

interface PdfSectionProps extends ViewProps {
  title: string
  subtitle?: string
  font: 'h1' | 'h2' | 'h3' | 'body1' | 'body2'
  underline?: boolean
}

export const PdfSection: React.FC<PropsWithChildren<PdfSectionProps>> = ({
  title,
  subtitle,
  font = 'body1',
  underline,
  children,
  ...viewProps
}) => {
  const theme = useTheme()
  return (
    <>
      <View
        {...viewProps}
        style={{
          paddingBottom: underline ? 12 : 0,
          ...viewProps.style,
        }}
      >
        <Text
          style={[s[font], { marginBottom: subtitle ? 0 : s[font].fontSize }]}
        >
          {title}
        </Text>
        {subtitle && (
          <Text style={[s.body2, s.textGrey, s.gutter4]}>{subtitle}</Text>
        )}
        {children}
        {underline && (
          <View
            style={{
              marginTop: 12,
              height: '.5px',
              backgroundColor: theme.palette.divider,
              width: '100%',
            }}
          />
        )}
      </View>
    </>
  )
}
