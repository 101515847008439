import {
  Box,
  ClickAwayListener,
  IconButton,
  Popper,
  Stack,
  SxProps,
  Theme,
  Typography,
  styled,
} from '@mui/material'
import React, { PropsWithChildren, ReactNode, UIEvent } from 'react'

const FilterWrapper = styled(Box)(({ theme }) => ({
  background: 'white',
  width: 386,
  boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.2)',
  display: 'inline-block',
  marginTop: theme.spacing(1),
  borderRadius: theme.spacing(1),
}))

const Content = styled(Stack)(({ theme }) => ({
  maxHeight: 670,
  padding: theme.spacing(0, 2, 0),
  overflowY: 'auto',
}))

const PopperHeader = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isscrollable',
})<{ isscrollable: boolean }>(({ theme, isscrollable }) => ({
  display: 'flex',
  position: 'relative',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(3),
  borderBottom: '1px solid',
  borderColor: isscrollable ? theme.palette.divider : 'transparent',
  transition: 'all 0.2s ease',
}))

const PopperFooter = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isscrollable',
})<{ isscrollable: boolean }>(({ theme, isscrollable }) => ({
  padding: theme.spacing(1),
  borderTop: '1px solid',
  direction: 'rtl',
  borderColor: isscrollable ? theme.palette.divider : 'transparent',
  transition: 'all 0.2s ease',
}))

interface CustomPopperProps extends PropsWithChildren {
  label: string
  headerIcon: ReactNode
  open: boolean
  anchor: HTMLElement
  footer?: ReactNode
  wrapperSx?: SxProps<Theme>
  translate?: boolean
  onClickHeaderIcon: () => void
  onClickAway: (event: MouseEvent | TouchEvent) => void
}

export const CustomPopper = ({
  label,
  headerIcon,
  onClickHeaderIcon,
  footer,
  open,
  anchor,
  wrapperSx,
  translate,
  onClickAway,
  children,
}: CustomPopperProps) => {
  const [isScrollable, setIsScrollable] = React.useState(false)

  const handleOnScroll = (event: UIEvent<HTMLDivElement>) => {
    setIsScrollable((event.target as HTMLDivElement)?.scrollTop !== 0)
  }

  return (
    <Popper
      open={open}
      placement="bottom-start"
      anchorEl={anchor}
      modifiers={[
        {
          name: 'flip',
          enabled: false,
        },
      ]}
      sx={{ zIndex: 1 }}
    >
      <ClickAwayListener onClickAway={onClickAway}>
        <FilterWrapper sx={{ ...wrapperSx }}>
          <PopperHeader isscrollable={isScrollable}>
            <Typography variant="body1" fontWeight="bold">
              {label}
            </Typography>
            <IconButton
              onClick={onClickHeaderIcon}
              sx={{ position: 'absolute', right: 15 }}
            >
              {headerIcon}
            </IconButton>
          </PopperHeader>
          <Content
            onScroll={handleOnScroll}
            translate={translate ? 'yes' : 'no'}
          >
            {children}
          </Content>
          <PopperFooter isscrollable={isScrollable}>{footer}</PopperFooter>
        </FilterWrapper>
      </ClickAwayListener>
    </Popper>
  )
}
