import React from 'react'
import { Typography } from '@mui/material'

import { ApplicationType } from '../../../../Api/types/api.types'
import usePickEpiContent from '../../../../Utils/Content/usePickEpiContent'

interface AdvertOverlineProps {
  applicationType: ApplicationType
}

export const AdvertOverline: React.FC<AdvertOverlineProps> = ({
  applicationType,
}) => {
  const { trrAdOverline, publicAdOverline } =
    usePickEpiContent().selectedViewNy.jobadContent

  if (applicationType === 'TrrRecruitment') {
    return (
      <Typography variant="body1" color="info.main">
        {trrAdOverline}
      </Typography>
    )
  }

  return (
    <Typography variant="body1" color="text.secondary">
      {publicAdOverline}
    </Typography>
  )
}
