import React from 'react'
import { FeedbackCard } from '@local/Components/FeedbackCard/FeedbackCard'
import usePickEpiContent from '@local/Utils/Content/usePickEpiContent'
import { useHotjar } from '@local/Utils/Hooks/useHotjar'

export const HotjarCvFeedback: React.FC = () => {
  const { leaveCvFeedbackEvent } = useHotjar()
  const { feedback } = usePickEpiContent().createCv
  const handleTriggerFeedback = () => {
    leaveCvFeedbackEvent()
  }

  return (
    <FeedbackCard
      title={feedback.title}
      description={feedback.description}
      primaryButtonLabel={feedback.primaryButton}
      primaryButtonProps={{
        onClick: handleTriggerFeedback,
      }}
      secondaryButtonLabel={feedback.secondaryButton}
      secondaryButtonProps={{
        href: feedback.secondaryButtonLink,
      }}
    />
  )
}
