import { replaceHtmlEntities } from '../../../Utils/Helpers/replaceHtmlEntities'

export const formatJobText = (text = ''): string => {
  const headers = [
    'OM FÖRETAGET',
    'Om oss:',
    'Om oss',
    'Personliga egenskaper:',
    'Arbetsuppgifter, exempel:',
    'Arbetsuppgifter:',
    'Kvalifikationer:',
    'Om dig',
    'Om tjänsten',
    'Om uppdraget',
    'Arbetsuppgifter:',
    'Ansvar och arbetsuppgifter',
    'Om bolaget',
    'Krav',
    'Krav:',
    'Krav för tjänsten',
    'Kvalifikationer',
    'Din profil',
    'Personliga egenskaper',
    'Vi erbjuder dig!',
    'OM ANSÖKNINGSPROCESSEN',
    'Låter det intressant?',
    'Som person är du:',
    'Övrig information:',
    'Övrig information',
    'ÖVRIGT',
    'Övrig information',
    'Vad erbjuder vi dig?',
    'Villkor och ansökan',
    'Omfattning:',
    'Ansökan',
    'Vi söker dig som',
    'Arbetsuppgifter',
    'Om arbetsplatsen',
    'Om arbetsplatsen:',
  ]

  const stripTextFromHTML = text.replace(/(<([^>]+)>)/gi, '')
  let fixedText = stripTextFromHTML
  for (const header of headers) {
    const re = new RegExp(`(^${header})\n`, 'gim')

    if (fixedText.substr(fixedText.indexOf(header) - 1).indexOf('>'))
      fixedText = fixedText.replace(re, `<h6>$1</h6>`)
  }
  return replaceHtmlEntities(fixedText)
}
