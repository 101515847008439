import React, { PropsWithChildren } from 'react'
import { useHasCompletedMatchingProfileQuery } from '@local/Api/matchingProfileApi'
import { LoadingSpinner } from '@local/Components/LoadingSpinner'
import { useAuthentication } from '@trr/app-shell-data'
import PuffCard from '@local/Components/PuffCard'
import usePickEpiContent from '@local/Utils/Content/usePickEpiContent'
import EmptyResult from '@local/Components/EmptyResult/EmptyResult'
import { isUserEnded } from '@local/Utils/Helpers'

export const MatchingProfileWrapper: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { userRoles, sub } = useAuthentication()
  const isEnded = isUserEnded(userRoles)

  const { data: hasCompletedMatchingProfile, isLoading } =
    useHasCompletedMatchingProfileQuery(sub, { skip: isEnded })

  const {
    profileLink,
    noProfileTitle,
    noProfileBody,
    noProfileLinkText,
    endedProfileTitle,
    endedProfileBody,
  } = usePickEpiContent().matchadeNy.profileBox

  if (isEnded) {
    return (
      <EmptyResult heading={endedProfileTitle} description={endedProfileBody} />
    )
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!hasCompletedMatchingProfile) {
    return (
      <PuffCard
        heading={noProfileTitle}
        description={noProfileBody}
        actionText={noProfileLinkText}
        href={profileLink}
      />
    )
  }

  return <>{children}</>
}
