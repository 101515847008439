import React from 'react'

import { SmartAccordionContext } from '../Context/SmartAccordionProvider'

export const useSmartAccordion = () => {
  const context = React.useContext(SmartAccordionContext)
  if (!context) {
    throw new Error(
      'useSmartAccordion must be used within an SmartAccordionProvider'
    )
  }
  return context
}
