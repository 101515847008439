import React from 'react'
import { JobCard } from '@local/Components/JobCard/JobCard'
import { Chip, Typography } from '@mui/material'
import { JobAd, QuickApplicationStatus } from '@local/Api/types/api.types'
import usePickEpiContent, {
  replaceEpiVariables,
} from '@local/Utils/Content/usePickEpiContent'
import { date } from '@trr/frontend-datetime'

enum ChipStatuses {
  Success = 'status-success',
  Warning = 'status-warning',
  Info = 'status-info',
}

interface AppliedJobListItemProps {
  job: JobAd
}

export const AppliedJobListItem: React.FC<AppliedJobListItemProps> = ({
  job,
}) => {
  const {
    jobAdLink,
    recruitmentStatusTextHired,
    recruitmentStatusTextRejected,
    recruitmentStatusTextApplied,
    appliedDateText,
    highlightedTextPublic,
    highlightedTextTrr,
  } = usePickEpiContent().ansokta.resultList

  const getChipContent = (): { text: string; status: ChipStatuses } => {
    switch (job.applicationStatus) {
      case QuickApplicationStatus.Withdrawn:
      case QuickApplicationStatus.Rejected:
        return {
          text: recruitmentStatusTextRejected,
          status: ChipStatuses.Info,
        }
      case QuickApplicationStatus.Hired:
        return {
          text: recruitmentStatusTextHired,
          status: ChipStatuses.Success,
        }
      case QuickApplicationStatus.Applied:
      case QuickApplicationStatus.OfferPresented:
      case QuickApplicationStatus.InterviewBooked:
      case QuickApplicationStatus.InProgress:
      default:
        return {
          text: recruitmentStatusTextApplied,
          status: ChipStatuses.Warning,
        }
    }
  }

  const subtitle = [job.jobTitle, job.employerName, job.locationName]
    .filter(Boolean)
    .join(', ')

  const renderFooter = () => {
    const lastModifiedDate = job.lastModifiedAt
      ? date.format(new Date(job.lastModifiedAt), { year: true })
      : ''

    if (job.isTrrJobAd) {
      const applicantStatus = getChipContent()

      const applicantStatusText = replaceEpiVariables(applicantStatus.text, {
        date: lastModifiedDate,
      })

      return (
        <Chip
          variant={applicantStatus.status}
          label={applicantStatusText}
          sx={{ height: 0 }}
        />
      )
    }

    return (
      <Typography variant="body2" color="text.secondary">
        {replaceEpiVariables(appliedDateText, {
          date: lastModifiedDate,
        })}
      </Typography>
    )
  }

  return (
    <JobCard
      href={`${jobAdLink}${job.id}`}
      title={job.header}
      subtitle={subtitle}
      {...(job.isTrrJobAd
        ? { preTitle: highlightedTextTrr, preTitleVariant: 'highlight' }
        : { preTitle: highlightedTextPublic, preTitleVariant: 'info' })}
      description={job.description}
      footer={renderFooter()}
    />
  )
}
