import React, { PropsWithChildren } from 'react'
import { useUser } from '@trr/app-shell-data'
import { useFeatureFlag } from '@local/Utils'
import MicroFrontendLoader from '@local/Components/MicroFrontendLoader'

export const BetaTestWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const user = useUser()
  const hasEnabledBetaTestingRole =
    user && 'isBetaTestUser' in user ? user.isBetaTestUser : false
  const { isBetaTestEnabled } = useFeatureFlag()

  if (!hasEnabledBetaTestingRole && isBetaTestEnabled) {
    return <MicroFrontendLoader frontend="beta-tester-widget" />
  }

  return <>{children}</>
}
