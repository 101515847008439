import React from 'react'

import { QuickApplicationStatus } from '../../../../Api/types/api.types'

import { HiredStatusCard } from './HiredStatusCard/HiredStatusCard'
import { RejectedSatatusCard } from './RejectedStatusCard/RejectedStatusCard'
import { PendingStatusCard } from './PendingStatusCard/PendingStatusCard'
import { AppliedStatusCard } from './AppliedStatusCard/AppliedStatusCard'

interface ApplicationStatusMapperProps {
  status?: QuickApplicationStatus
  hasAppliedExternally: boolean
  applicationDate?: Date
  lastUpdatedDate?: Date
}

export const ApplicationStatusMapper: React.FC<
  ApplicationStatusMapperProps
> = ({ status, hasAppliedExternally, applicationDate, lastUpdatedDate }) => {
  if (hasAppliedExternally) {
    return <AppliedStatusCard applicationDate={applicationDate} />
  }

  switch (status) {
    case QuickApplicationStatus.Hired:
      return (
        <HiredStatusCard
          lastUpdatedDate={lastUpdatedDate}
          applicationDate={applicationDate}
        />
      )
    case QuickApplicationStatus.Withdrawn:
    case QuickApplicationStatus.Rejected:
      return (
        <RejectedSatatusCard
          applicationDate={applicationDate}
          lastUpdatedDate={lastUpdatedDate}
        />
      )
    case QuickApplicationStatus.Applied:
    case QuickApplicationStatus.InterviewBooked:
    case QuickApplicationStatus.OfferPresented:
    case QuickApplicationStatus.InProgress:
      return <PendingStatusCard applicationDate={applicationDate} />
    default:
      return null
  }
}
