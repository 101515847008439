import React from 'react'
import { CardProps } from '@mui/material'
import { BookmarkBorderRounded } from '@mui/icons-material'

import usePickEpiContent from '../../../../Utils/Content/usePickEpiContent'
import PuffCard from '../../../../Components/PuffCard'

const SavedAdsEndingSoonPuff: React.FC<CardProps> = (props) => {
  const { categorization, subtitle, action, to, title } =
    usePickEpiContent().sokNy.savedAdsEndingSoonPuff

  return (
    <PuffCard
      overline={categorization}
      heading={title}
      description={subtitle}
      href={`${to}sparade`}
      actionText={action}
      Icon={BookmarkBorderRounded}
      {...props}
    />
  )
}

export default SavedAdsEndingSoonPuff
