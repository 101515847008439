import React from 'react'
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import { BoltOutlined, Close } from '@mui/icons-material'
import { date } from '@trr/frontend-datetime'

import { useGetQuickApplicationQuery } from '../../../../Api/quickApplicationApi'
import { useSelectedJobId } from '../../../../Utils/Hooks/useSelectedJobId'
import { useGetJobAdQuery } from '../../../../Api/JobAdApi'
import SelectableChip from '../../../../Components/SelectableChip/SelectableChip'
import usePickEpiContent, {
  replaceEpiVariables,
} from '../../../../Utils/Content/usePickEpiContent'

import { ContactSection } from './ContactSection/ContactSection'

interface MyApplicationDrawerProps {
  isOpen: boolean
  onClose: () => void
}

export const MyApplicationDrawer: React.FC<MyApplicationDrawerProps> = ({
  isOpen,
  onClose,
}) => {
  const selectedJobId = useSelectedJobId()
  const content = usePickEpiContent().selectedViewNy.myQuickApplication

  const { data: application } = useGetQuickApplicationQuery(selectedJobId)
  const { data: jobAd } = useGetJobAdQuery(selectedJobId)

  return (
    <Drawer
      anchor="right"
      open={isOpen && !!application}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: '100vw',
          p: { xs: 2, sm: 3, md: 5 },
        },
      }}
    >
      <Stack
        divider={<Divider />}
        spacing={3}
        position="relative"
        maxWidth={477}
      >
        <Box>
          <IconButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              zIndex: 1,
            }}
          >
            <Close />
          </IconButton>
          <ListItem sx={{ mt: 4 }}>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: 'surface.purple' }}>
                <BoltOutlined color="primary" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={content.heading}
              primaryTypographyProps={{ variant: 'h4' }}
              secondary={replaceEpiVariables(content.applicationDateText, {
                date: date.format(application.createdWhen, { year: true }),
              })}
            />
          </ListItem>
        </Box>
        <Box>
          <Typography variant="h6" gutterBottom>
            {content.skillsHeading}
          </Typography>
          <Stack direction="row" gap={1} flexWrap="wrap">
            {jobAd.position.trrRecruitmentSkills?.map((skill) => (
              <SelectableChip
                key={skill}
                label={skill}
                selected={application.skills.includes(skill)}
              />
            ))}
          </Stack>
        </Box>
        <Box>
          <Typography variant="h6" gutterBottom>
            {content.motivationHeading}
          </Typography>
          <Typography
            color="text.secondary"
            sx={{ wordBreak: 'break-word', whiteSpace: 'pre-line' }}
          >
            {application.motivation}
          </Typography>
        </Box>
        <ContactSection />
      </Stack>
    </Drawer>
  )
}
