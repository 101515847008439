import React, { PropsWithChildren } from 'react'

import { CustomDrawer } from '../Components/CustomDrawer'

interface DrawerContent {
  title: string
  content: React.ReactNode
}

interface DrawerContextProps {
  openDrawer: () => void
  closeDrawer: () => void
  setDrawerContent: (content: DrawerContent) => void
}

export const GlobalDrawerContext = React.createContext<DrawerContextProps>(null)

export const GlobalDrawerProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [open, setOpen] = React.useState(false)
  const [drawer, setDrawer] = React.useState<DrawerContent>(null)

  const openDrawer = () => {
    setOpen(true)
  }

  const closeDrawer = () => {
    setOpen(false)
  }

  const setDrawerContent = (content: DrawerContent) => {
    setDrawer(content)
  }

  return (
    <GlobalDrawerContext.Provider
      value={{
        openDrawer,
        closeDrawer,
        setDrawerContent,
      }}
    >
      {children}
      <CustomDrawer
        open={open}
        title={drawer?.title}
        handleClose={closeDrawer}
        anchor="right"
        variant="persistent"
      >
        {drawer?.content}
      </CustomDrawer>
    </GlobalDrawerContext.Provider>
  )
}
