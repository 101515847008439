import React, { PropsWithChildren } from 'react'

interface ConditionalWrapperProps extends PropsWithChildren {
  condition: boolean
  wrapper: React.FC<PropsWithChildren>
}

const ConditionalWrapper: React.FC<ConditionalWrapperProps> = ({
  condition,
  wrapper,
  children,
}) => (condition ? wrapper({ children }) : <>{children}</>)

export default ConditionalWrapper
