import React from 'react'
import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import { Divider, Typography, TypographyProps } from '@mui/material'

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  '&::before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(() => ({
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  padding: 0,
}))

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: 0,
}))

interface CustomAccordionProps extends React.PropsWithChildren {
  title: string
  expanded: boolean
  panelId: string
  typographyProps?: TypographyProps
  divider?: boolean
  handleClick: (panelId: string | boolean) => void
}

export const CustomAccordion: React.FC<CustomAccordionProps> = ({
  title,
  panelId,
  expanded,
  typographyProps = { variant: 'h6' },
  divider = false,
  handleClick,
  children,
}) => {
  const handlePanelChange =
    (panel: string) => (_event: React.SyntheticEvent, newExpanded: boolean) => {
      handleClick(newExpanded ? panel : false)
    }

  return (
    <>
      <Accordion expanded={expanded} onChange={handlePanelChange(panelId)}>
        <AccordionSummary
          aria-controls={`${panelId}-content`}
          id={`${panelId}-header`}
        >
          <Typography {...typographyProps}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ pt: 2, pb: 2 }}>{children}</AccordionDetails>
      </Accordion>
      {divider && <Divider sx={{ mt: 0 }} />}
    </>
  )
}
