import { Experience } from '../../../../GeneratedCv.types'

export const formatDateRange = ({
  startYear,
  startMonth,
  endYear,
  endMonth,
}: Pick<
  Experience,
  'startYear' | 'startMonth' | 'endYear' | 'endMonth'
>): string => {
  if (!startYear) {
    return 'Ej angivet'
  }

  const startDate = `${startYear}${startMonth ? ` ${startMonth}` : ''}`

  const endDate = `${endYear ? `${endYear}` : ''}${endMonth ? ` ${endMonth}` : ''}`

  return `${startDate} - ${endDate || 'Nuvarande'}`
}
