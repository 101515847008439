import { Box, CircularProgress } from '@mui/material'
import React from 'react'

export const LoadingSpinner: React.FC = () => {
  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'center' }}
      data-testid="loading-spinner"
    >
      <CircularProgress color="primary" />
    </Box>
  )
}
