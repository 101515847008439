import React from 'react'
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
  CardProps,
  SvgIconTypeMap,
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { useDevice } from '@trr/app-shell-data'

import ConditionalWrapper from './ConditionalWrapper'

export interface PuffCardProps extends CardProps {
  overline?: string
  heading: string
  description: string
  Icon?: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>
  href?: string
  actionText?: string
  requireButtonClick?: boolean
}

const PuffCard: React.FC<PuffCardProps> = ({
  overline,
  heading,
  description,
  Icon,
  href,
  actionText,
  requireButtonClick = false,
  ...props
}) => {
  const { isMobile } = useDevice()
  const showActionArea = !!href && !requireButtonClick

  return (
    <Card
      sx={{
        height: '100%',
        wordBreak: 'break-word',
      }}
      {...props}
    >
      <ConditionalWrapper
        condition={showActionArea}
        wrapper={({ children }) => (
          <CardActionArea
            href={href}
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
            disableRipple
            data-testid="puff-card-action-area"
          >
            {children}
          </CardActionArea>
        )}
      >
        {overline && (
          <CardHeader
            title={
              <Typography variant="subtitle2" color="text.secondary">
                {overline}
              </Typography>
            }
          />
        )}
        <CardContent sx={{ width: '100%', flexGrow: 1 }}>
          <ListItem sx={{ alignItems: 'start' }}>
            {!isMobile && !!Icon && (
              <ListItemAvatar sx={{ minWidth: 0, mr: 1 }}>
                <Avatar sx={{ bgcolor: (t) => t.palette.surface?.purple }}>
                  <Icon
                    sx={{ fill: (t) => t.palette.primary.main }}
                    fontSize="small"
                  />
                </Avatar>
              </ListItemAvatar>
            )}
            <ListItemText
              primary={heading}
              secondary={description}
              primaryTypographyProps={{ variant: 'h6', mb: 1 }}
              sx={{ my: 0 }}
            />
          </ListItem>
        </CardContent>
        <CardActions>
          {actionText && (
            <Button
              size="small"
              variant="text"
              href={!showActionArea ? href : undefined}
            >
              {actionText}
            </Button>
          )}
        </CardActions>
      </ConditionalWrapper>
    </Card>
  )
}

export default PuffCard
