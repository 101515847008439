import React from 'react'
import { useSaveJobAdMutation } from '@local/Api/JobAdApi'
import { TurnedIn, TurnedInNot } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { useOptimistic } from '@local/Utils/Hooks/useOptimistic'

interface SaveJobButtonProps {
  jobId: string
  isSaved: boolean
}

export const SaveJobButton: React.FC<SaveJobButtonProps> = ({
  jobId,
  isSaved,
}) => {
  const [isSavedOptimistic, setIsSavedOptimistic] = useOptimistic(isSaved)
  const [setSaved] = useSaveJobAdMutation()

  const handleSaveClick: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    event.preventDefault()
    event.stopPropagation()
    setIsSavedOptimistic(!isSavedOptimistic)

    const action = isSavedOptimistic ? 'DELETE' : 'POST'
    setSaved({ id: jobId, method: action })
  }

  return (
    <IconButton
      onClick={handleSaveClick}
      data-testid={isSavedOptimistic ? 'remove-save-button' : 'save-button'}
    >
      {isSavedOptimistic ? <TurnedIn /> : <TurnedInNot />}
    </IconButton>
  )
}
