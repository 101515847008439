import { useGetQuickApplicationQuery } from '../../../Api/quickApplicationApi'
import { useSelectedJobId } from '../../../Utils/Hooks/useSelectedJobId'

import { useSelectedJob } from './useSelectedJob'

export const useSelectedJobApplication = () => {
  const selectedJobId = useSelectedJobId()
  const { data: job } = useSelectedJob()
  return useGetQuickApplicationQuery(selectedJobId, {
    skip: !selectedJobId || !job || job.application?.type !== 'TrrRecruitment',
  })
}
