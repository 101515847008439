import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'

import getConfig from '../Utils/getConfig'

import { prepareHeaders } from './prepareHeaders'

export interface GetUserResponse {
  email: string
  mobilePhone: string
}

const { PUBLIC_WEB_API_URL } = getConfig()

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${PUBLIC_WEB_API_URL}`,
    prepareHeaders,
  }),
  refetchOnFocus: true,
  endpoints: (builder) => ({
    getUser: builder.query<GetUserResponse, void>({
      query: () => ({
        url: '/user',
        method: 'GET',
      }),
      transformResponse: (response: GetUserResponse[]) => response[0],
    }),
  }),
})

export const { useLazyGetUserQuery } = userApi
