import React from 'react'
import { SmartAccordion } from '@local/Components/Accordion'
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  TextField,
} from '@mui/material'
import { useFormikContext } from 'formik'

import { Cv } from '../../GeneratedCv.types'
import { useCvGtmTracker } from '../Hooks/useCvGtmTracker'

export const CompetencesField = () => {
  const {
    values: { skills },
    setFieldValue,
  } = useFormikContext<Cv>()
  const { trackChangeOnce } = useCvGtmTracker()

  const toggleSkill = (
    _event: unknown,
    _value: unknown,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string>
  ) => {
    const skill = details?.option
    if (reason === 'clear') {
      trackChangeOnce('skills', 'Kompetens: rensade allt')
      return setFieldValue('skills', [])
    }
    if (skills?.includes(skill)) {
      trackChangeOnce('skills', 'Kompetens: tog bort kompetens')
      setFieldValue(
        'skills',
        skills.filter((s) => s !== skill)
      )
    } else {
      trackChangeOnce('skills', 'Kompetens: lade till kompetens')
      setFieldValue('skills', [...(skills ?? []), skill])
    }
  }

  return (
    <SmartAccordion title="Kompetenser">
      <Autocomplete
        multiple
        freeSolo
        value={skills}
        onChange={toggleSkill}
        options={[]}
        renderInput={(params) => (
          <TextField {...params} label="Erfarenhet" size="small" />
        )}
      />
    </SmartAccordion>
  )
}
