import React from 'react'
import {
  Button,
  ButtonProps,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material'

interface FeedbackCardProps {
  title: string
  description: string
  primaryButtonLabel: string
  primaryButtonProps: ButtonProps
  secondaryButtonLabel?: string
  secondaryButtonProps?: ButtonProps
}

export const FeedbackCard: React.FC<FeedbackCardProps> = ({
  title,
  description,
  primaryButtonLabel,
  secondaryButtonLabel,
  primaryButtonProps,
  secondaryButtonProps,
}) => (
  <Card
    sx={{
      backgroundColor: 'surface.pink',
    }}
  >
    <CardHeader title={title} titleTypographyProps={{ variant: 'h5' }} />
    <CardContent>
      <Typography variant="body2">{description}</Typography>
    </CardContent>
    <CardActions sx={{ px: 3, py: 2 }}>
      <Button size="small" variant="outlined" {...primaryButtonProps}>
        {primaryButtonLabel}
      </Button>
      {secondaryButtonLabel && (
        <Button size="small" variant="text" {...secondaryButtonProps}>
          {secondaryButtonLabel}
        </Button>
      )}
    </CardActions>
  </Card>
)
