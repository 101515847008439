import { baseApi } from './baseApi'
import { excludeLocationsWithNonMappableLocationType } from './helpers/historicSearchHelpers'
import { HistoricSearchesResponse } from './types/api.types'

export const HISTORIC_SEARCHES_TAG = 'HistoricSearches'

export const historicSearchesApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getHistoricSearches: builder.query<HistoricSearchesResponse[], void>({
      query: () => ({
        url: `/jobs/history`,
        method: 'GET',
        params: {
          count: 20,
        },
      }),
      providesTags: [HISTORIC_SEARCHES_TAG],
      transformResponse: excludeLocationsWithNonMappableLocationType,
    }),
    deleteHistoricSearch: builder.mutation<void, string>({
      query: (id) => ({
        url: `/jobs/history/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [HISTORIC_SEARCHES_TAG],
    }),
  }),
})

export const { useGetHistoricSearchesQuery, useDeleteHistoricSearchMutation } =
  historicSearchesApi
