import { baseApi } from './baseApi'
import {
  GetSavedJobAdsRequest,
  JobAdList,
  QuickApplicationStatus,
  SavedTo,
} from './types/api.types'

export interface SavedJobsResponse {
  totalHits: number
  page: number
  pageSize: number
  totalPages: number
  jobAds: {
    jobAd: {
      id: string
      header: string
      employerName: string
      jobTitle: string
      descriptionShort: string
      locationName: string
      locationType: string
      publishDate: string
      deadline: string
      isTrrRecruitment: boolean
    }
    saveStatus: {
      status: QuickApplicationStatus
      savedTo: SavedTo
      createdAt: string
      lastModifiedAt: string
    }[]
  }[]
}

const savedJobAdsApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getSavedJobAds: builder.query<JobAdList, GetSavedJobAdsRequest>({
      keepUnusedDataFor: 0,
      providesTags: ['SavedJobs'],
      query: (params) => ({
        url: '/jobs/ads/saved',
        params: {
          ...params,
          savedTo: SavedTo.Saved,
          notSavedTo: SavedTo.Applied,
        },
      }),
      transformResponse: (response: SavedJobsResponse) => ({
        jobAds: response.jobAds?.map((job) => ({
          id: job.jobAd.id,
          header: job.jobAd.header,
          employerName: job.jobAd.employerName,
          locationName: job.jobAd.locationName,
          jobTitle: job.jobAd.jobTitle,
          description: job.jobAd.descriptionShort,
          locationType: job.jobAd.locationType,
          applicationStatus: job.saveStatus?.find(
            (x) => x.savedTo === SavedTo.Applied
          )?.status,
          createdAt: new Date(
            job.saveStatus?.find(
              (x) => x.savedTo === SavedTo.Applied
            )?.createdAt
          ).toDateString(),
          lastModifiedAt: new Date(
            job.saveStatus?.find(
              (x) => x.savedTo === SavedTo.Applied
            )?.lastModifiedAt
          ).toDateString(),
          isSaved: job.saveStatus.some((x) => x.savedTo === SavedTo.Saved),
          publishDate: job.jobAd.publishDate,
          deadline: job.jobAd.deadline,
          isTrrJobAd: job.jobAd.isTrrRecruitment,
        })),
        totalHits: response.totalHits,
        page: response.page,
        pageSize: response.pageSize,
        totalPages: response.totalPages,
      }),
    }),
  }),
})

export const { useGetSavedJobAdsQuery, util: savedJobAdUtil } = savedJobAdsApi
