import { Box, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { useDevice } from '@trr/app-shell-data'

import usePickEpiContent, {
  replaceEpiVariables,
} from '../../../../../Utils/Content/usePickEpiContent'
import { useQuickApplicationForm } from '../hooks/useQuickApplicationForm'
import { MAX_MOTIVATION_LENGTH } from '../hooks/useQuickApplicationValidation'

export const MotivationField: React.FC = () => {
  const content = usePickEpiContent().selectedViewNy.quickApplication

  const { values, setValues, errors } = useQuickApplicationForm()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues((prev) => ({ ...prev, motivation: event.target.value }), true)
  }

  const { isMobile } = useDevice()

  return (
    <Stack spacing={3}>
      <Box>
        <Typography variant="h6" gutterBottom>
          {content.motivationHeading}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {isMobile
            ? content.motivationDescriptionShort
            : content.motivationDescriptionLong}
        </Typography>
      </Box>
      <TextField
        multiline
        fullWidth
        minRows={4}
        maxRows={12}
        label={content.motivationFieldLabel}
        helperText={
          errors.motivation ||
          replaceEpiVariables(content.motivationHelperText, {
            count: values.motivation.length.toString(),
            max: MAX_MOTIVATION_LENGTH.toString(),
          })
        }
        value={values.motivation}
        error={!!errors.motivation}
        onChange={handleChange}
      />
    </Stack>
  )
}
