import { useEffect, useState } from 'react'

const useDebounce = <T,>(value: T, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      return () => {
        clearTimeout(handler)
      }
    },
    // Only re-call effect if value or delay changes
    [value, delay]
  )

  return debouncedValue
}

export default useDebounce
