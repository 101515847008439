import React from 'react'
import {
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material'

import { Contact } from '../../../../Api/types/api.types'
import usePickEpiContent from '../../../../Utils/Content/usePickEpiContent'

interface AdvertContactProps {
  contact?: Contact
}

export const AdvertContact: React.FC<AdvertContactProps> = ({ contact }) => {
  const { nameLabel, emailLabel, phoneLabel, description, noContactText } =
    usePickEpiContent().selectedViewNy.contact

  if (!contact?.name && !contact?.email && !contact?.phone)
    return <Typography color="text.secondary">{noContactText}</Typography>

  const contactArray = [
    {
      id: 'name',
      value: contact.name,
      label: nameLabel,
      content: contact.name,
    },
    {
      id: 'email',
      value: contact.email,
      label: emailLabel,
      content: <Link href={`mailto:${contact.email}`}>{contact.email}</Link>,
    },
    {
      id: 'phone',
      value: contact.phone,
      label: phoneLabel,
      content: <Link href={`tel:${contact.phone}`}>{contact.phone}</Link>,
    },
  ].filter((contact) => contact.value)

  return (
    <Stack spacing={3}>
      <Typography variant="body1">{description}</Typography>
      <TableContainer>
        <Table>
          <TableBody>
            {contactArray.map(({ label, content, id }) => (
              <TableRow key={label}>
                <TableCell
                  id={id}
                  align="left"
                  sx={{ color: (t) => t.palette.text.secondary }}
                  padding="none"
                >
                  {label}
                </TableCell>
                <TableCell aria-labelledby={id}>{content}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )
}
