import { combineReducers, PreloadedState } from 'redux'
import { baseApi } from '@local/Api/baseApi'
import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'

import { userApi } from '../Api/userApi'

const rootReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
})

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      // adding the api middleware enables caching, invalidation, polling and other features of `rtk-query`
      getDefaultMiddleware().concat([baseApi.middleware, userApi.middleware]),
    preloadedState,
  })

const Store = setupStore()
export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = typeof Store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()

export default Store
