import { either, isNil, isEmpty } from 'ramda'

import { DeepLocation } from '../../Api/types/api.types'

type MapToOutput<I, U = I> = (v: I) => U

export const checkNullUndefined: MapToOutput<unknown, boolean> = either(
  isEmpty,
  isNil
)

export const isUserEnded = (roles: string[]) =>
  roles?.includes('TIOAvslutad') ?? false

export const locationsToUrl = (locations: Partial<DeepLocation>[]) =>
  locations.map((loc) => {
    const typeText = loc?.type ? `,${loc.type}` : ''
    const idText = loc?.id ? `,${loc.id}` : ''
    return `${loc.name}${typeText}${idText}`?.replace(/(<([^>]+)>)/gi, '')
  })

export const jobtitlesToUrl = (jobtitles: string[]) =>
  jobtitles.map((jobtitle) => jobtitle?.replace(/(<([^>]+)>)/gi, ''))
