import React from 'react'
import { Grid2 as Grid, Grid2Props as GridProps } from '@mui/material'
import { useFeatureFlag } from '@local/Utils'

import CreateMatchingProfilePuff from './Puffs/CreateMatchingProfilePuff'
import InterviewPuff from './Puffs/InterviewPuff'
import usePuffGrid from './usePuffGrid'
import SavedAdsEndingSoonPuff from './Puffs/SavedAdsEndingSoonPuff'
import SuggestionsPuff from './Puffs/SuggestionsPuff'
import ImproveResumePuff from './Puffs/ImproveResumePuff'

const PuffGrid: React.FC<GridProps> = (props) => {
  const {
    hasCompletedMatchingProfile,
    isLoading,
    hasError,
    suggestionsCount,
    hasSavedJobAdsEndingSoon,
    hasSavedJobAdsEndingSoonPuffContent,
    hasCreateMatchingProfilePuffContent,
    hasSuggestionsPuffContent,
    hasImproveResumePuffContent,
  } = usePuffGrid()

  const { isSuggestionsBlockEnabled } = useFeatureFlag()

  const LeftPuff = (() => {
    if (hasCompletedMatchingProfile) {
      if (
        isSuggestionsBlockEnabled &&
        hasSuggestionsPuffContent &&
        suggestionsCount > 0
      ) {
        return (
          <SuggestionsPuff
            count={suggestionsCount}
            data-testid="suggestions-puff"
          />
        )
      }
      if (hasImproveResumePuffContent) {
        return <ImproveResumePuff data-testid="resume-puff" />
      }
    } else {
      if (hasCreateMatchingProfilePuffContent) {
        return (
          <CreateMatchingProfilePuff data-testid="create-matching-profile-puff" />
        )
      }
    }
  })()

  const RightPuff = (() => {
    if (hasSavedJobAdsEndingSoonPuffContent && hasSavedJobAdsEndingSoon) {
      return <SavedAdsEndingSoonPuff data-testid="saved-ads-ending-soon-puff" />
    }

    return <InterviewPuff data-testid="interview-puff" />
  })()

  if (isLoading || hasError || (!LeftPuff && !RightPuff)) {
    return null
  }

  return (
    <Grid container spacing={2} alignItems="stretch" {...props} role="grid">
      {LeftPuff && (
        <Grid size={{ xs: 12, md: 6 }} role="gridcell">
          {LeftPuff}
        </Grid>
      )}
      {RightPuff && (
        <Grid size={{ xs: 12, md: 6 }} role="gridcell">
          {RightPuff}
        </Grid>
      )}
    </Grid>
  )
}

export default PuffGrid
