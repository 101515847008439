import React from 'react'
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material'
import parse from 'html-react-parser'
import { BoltOutlined } from '@mui/icons-material'

import usePickEpiContent from '../../../../../../Utils/Content/usePickEpiContent'

interface QuickApplicationHelperDialogProps {
  isOpen: boolean
  onClose: () => void
}

const QuickApplicationHelperDialog: React.FC<
  QuickApplicationHelperDialogProps
> = ({ isOpen, onClose }) => {
  const {
    quickApplicationHelperDialog: { mainBody },
    quickApplication: { helperDialogCloseButtonLabel, helperDialogTitle },
  } = usePickEpiContent().selectedViewNy
  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle sx={{ py: 3 }}>
        <ListItem>
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: 'surface.purple' }}>
              <BoltOutlined color="primary" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={helperDialogTitle}
            primaryTypographyProps={{ variant: 'h6' }}
          />
        </ListItem>
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ whiteSpace: 'pre-line' }}>
          {parse(mainBody)}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          {helperDialogCloseButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default QuickApplicationHelperDialog
