import { baseApi } from './baseApi'
import { convertToSwedishTypesRecursive } from './helpers/autocompleteHelpers'
import { DeepLocation, JobTitle } from './types/api.types'

export interface JobTitleResponse {
  id: string
  jobTitle: string
  jobTitleWithHighLightedSearchTerm: string
}

const autocompleteApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getLocations: builder.query<DeepLocation[], string>({
      query: (location) => {
        return {
          url: `/referencedata/locations/suggest/${location}`,
        }
      },
      keepUnusedDataFor: 0,
      transformResponse: (response: DeepLocation[]) =>
        response.map((loc) => convertToSwedishTypesRecursive(loc)),
    }),
    getJobTitles: builder.query<JobTitle[], string>({
      query: (job) => ({
        url: `/referencedata/jobtitles/v2/suggestions/${job}`,
      }),
      keepUnusedDataFor: 0,
      transformResponse: (response: JobTitleResponse[]) =>
        response
          .map((job) => ({
            id: job.id,
            name: job.jobTitle,
            jobTitleWithHighLightedSearchTerm:
              job.jobTitleWithHighLightedSearchTerm,
          }))
          // Remove duplicates
          ?.reduce((a, c) => {
            if (!a.find((item) => item.name === c.name)) {
              a.push(c)
            }
            return a
          }, [] as JobTitle[]),
    }),
  }),
})

export const { useGetLocationsQuery, useGetJobTitlesQuery } = autocompleteApi
