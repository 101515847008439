import React from 'react'
import { Link, Text } from '@react-pdf/renderer'

import { pdfStyles as s } from '../../styles'
import { PdfSection } from '../../Components/PdfSection'
import { Reference } from '../../../GeneratedCv.types'

interface ReferencesSectionProps {
  references: Reference[]
  heading: string
}

export const ReferencesSection = ({
  references,
  heading,
}: ReferencesSectionProps) => (
  <PdfSection title={heading} font="h2" style={s.gutter5} wrap={false}>
    {references.map((reference, index) => (
      <PdfSection
        title={reference?.company ?? 'Ny referens'}
        font="body2"
        key={`references-${index}`}
        underline={index !== references.length - 1}
      >
        {reference.description && (
          <Text
            style={[s.body2, s.gutter3]}
          >{`"${reference.description}"`}</Text>
        )}
        {reference.name && (
          <Text style={[s.body2, s.gutter3]}>{reference.name}</Text>
        )}
        <Text style={[s.body2, s.gutter3]}>
          <Link src={`tel:${reference?.phone}`} style={s.link}>
            {reference?.phone ?? 'Ej angivet'}
          </Link>
        </Text>
        <Text style={s.body2}>
          <Link src={`mailto:${reference?.email}`} style={s.link}>
            {reference?.email ?? 'Ej angivet'}
          </Link>
        </Text>
      </PdfSection>
    ))}
  </PdfSection>
)
