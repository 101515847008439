import { object, string } from 'yup'

import { QuickApplicationFormFields } from '../../../../../Api/types/api.types'
import usePickEpiContent, {
  replaceEpiVariables,
} from '../../../../../Utils/Content/usePickEpiContent'

export const MAX_MOTIVATION_LENGTH = 600

export const useQuickApplicationValidation = () => {
  const { motivationRequired, motivationHelperText } =
    usePickEpiContent().selectedViewNy.quickApplication

  return object<QuickApplicationFormFields>().shape({
    motivation: string()
      .required(motivationRequired)
      .max(MAX_MOTIVATION_LENGTH, (params) =>
        replaceEpiVariables(motivationHelperText, {
          count: params.value.length.toString(),
          max: MAX_MOTIVATION_LENGTH.toString(),
        })
      ),
  })
}
